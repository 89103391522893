import { OperatingSystemEnum } from "../enum/OperatingSystemEnum";

export class DeviceChecker {
    static getMobileOS() {
        var userAgent = navigator.userAgent || navigator.vendor;
        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return OperatingSystemEnum.WINDOWS_PHONE;
        }
        if (/android/i.test(userAgent)) {
            return OperatingSystemEnum.ANDROID;
        }
        if (/iPad|iPhone|iPod/.test(userAgent)) {
            return OperatingSystemEnum.IOS;
        }
        return OperatingSystemEnum.OTHER;
    }
}