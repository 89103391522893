import moment from 'moment';

export function momentIso8601DateTimeReviver(value: any) {
  // Global geht die Definition nicht! Beim zweiten Aufruf von test() immer false.
  const iso8601DateTimePattern = /^\d{4}-[01]{1}\d{1}-[0-3]{1}\d{1}T[0-2]{1}\d{1}:[0-6]{1}\d{1}:[0-6]{1}\d{1}/gm;
  return iso8601DateTimePattern.test(value) ? moment(value) : value;
}

export function iso8601DateTimeReviver(value: any) {
  // Global geht die Definition nicht! Beim zweiten Aufruf von test() immer false.
  const iso8601DateTimePattern = /^\d{4}-[01]{1}\d{1}-[0-3]{1}\d{1}T[0-2]{1}\d{1}:[0-6]{1}\d{1}:[0-6]{1}\d{1}/gm;
  return iso8601DateTimePattern.test(value) ? moment(value).toDate() : value;
}

export const isIso8601DateTime = (value: any) => {
  const iso8601DateTimePattern = /^\d{4}-[01]{1}\d{1}-[0-3]{1}\d{1}T[0-2]{1}\d{1}:[0-6]{1}\d{1}:[0-6]{1}\d{1}/gm;
  return iso8601DateTimePattern.test(value);
}