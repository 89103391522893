import { AxiosResponse } from 'axios';
import { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { SessionDTO } from '../dto/SessionDTO';
import { SystemparameterDTO } from '../dto/SystemparameterDTO';
import { systemparameterService } from '../services/systemparameter.service';
import { useSession } from './session.context';

type SystemparameterContextType = {
  systemparameter?: SystemparameterDTO;
};

export const SystemparameterContext = createContext<SystemparameterContextType>({});

export const SystemparameterContextProvider = (props: { children: ReactNode; }) => {
  const [systemparameter, setSystemparameter] = useState<SystemparameterDTO>();
  const [loadTrigger, setLoadTrigger] = useState(new Date());
  const [isLoading, setLoading] = useState(false);
  const { session } = useSession();
  const sessionRef = useRef<SessionDTO>();

  const isLoadingRef = useRef(isLoading);

  const doSetLoading = (l: boolean) => {
    setLoading(l);
    isLoadingRef.current = l;
  }

  useEffect(() => {

    const load = async () => {
      doSetLoading(true);
      systemparameterService.load().then((response: AxiosResponse<SystemparameterDTO>) => {
        sessionRef.current = session;
        setSystemparameter(response.data);
      })
        .catch(e => {
          console.warn("Error loading system parameter: " + e);
          // Wenn noch keine bisher geladen wurden, Retry, damit alle Loginoptionen sichtbar werden können
          if (!systemparameter) {
            const timer = setTimeout(() => {
              setLoadTrigger(new Date());
            }, 30 * 1000);

            return () => clearTimeout(timer);
          }
        })
        .finally(() => doSetLoading(false));
    };

    // Nur Systemparameter laden, wenn die Session gelöscht wird oder neu erstellt wurde (oder initial)
    if (!isLoadingRef.current && ((!sessionRef.current && session) || (sessionRef.current && !session) || !systemparameter)) {
      load();
    }

  }, [session, systemparameter, loadTrigger]);

  return <SystemparameterContext.Provider value={{ systemparameter }}>{props.children}</SystemparameterContext.Provider>;
};

export const useSystemparameter = () => {
  const context = useContext(SystemparameterContext);
  if (context === undefined) {
    throw new Error('useSystemparameter must be used within a SystemparameterContextProvider');
  }
  return context;
};
