import { getTimezoneOffset } from "date-fns-tz";
import moment from "moment";

export const getLocalISODate = (date?: Date) => (date?.toLocaleString('sv', { timeZoneName: 'short' }).substring(0, 10));
export const getLocalISODateTime = (date?: Date) => date === null ? undefined : toOffsetIsoString(date!);

const toOffsetIsoString = (date: Date) => (
      date.getFullYear() +
      '-' + pad(date.getMonth() + 1) +
      '-' + pad(date.getDate()) +
      'T' + pad(date.getHours()) +
      ':' + pad(date.getMinutes()) +
      ':' + pad(date.getSeconds())
)

export const pad = (num: number) => {
  return (num < 10 ? '0' : '') + num;
};

export const SLOT_TIMEZONE = 'Europe/Berlin';

// Rückgabe des übergebenen Terminas umgewandelt als Slotzeit in der Zeitzone von Unikat -> Europe/Berlin
// 2023-04-18T00:00:00+0800 -> 2023-04-18T00:00:00+0200
// 2023-04-18T00:00:00-0700 -> 2023-04-18T00:00:00+0200
export const getSlotTime = (termin: Date) => {
  const tzOffset = getTimezoneOffset(SLOT_TIMEZONE, termin);
  const absTzOffset = Math.abs(tzOffset);
  const sep = tzOffset >= 0 ? '+' : '-';
  const isoTime = getLocalISODateTime(termin) + sep + pad(absTzOffset/60/1000/60) + pad((absTzOffset/60/1000)%60);
  const terminSlot = moment(isoTime).toDate();
  return terminSlot;
}
