import { faExclamationTriangle, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { classNames } from 'primereact/utils';
import { useErrorStatus } from "../contexts/errorstatus.context";
import { ErrorDTO } from '../dto/ErrorDTO';
import { MessageType } from '../dto/MessageType';


export const ErrorStatus = () => {

  const { errorStatus, setErrorStatus } = useErrorStatus();

  const clearErrorStatus = () => {
    setErrorStatus(undefined);
  };

  const formatDetailMessage = (err: ErrorDTO)  => {
      console.log("Err=" + JSON.stringify(err));
      let ret = [err.field, err.code && '(' + err.code + ')' ].filter(f => f).join(' ')
      return [ret, err.message || err.defaultMessage].filter(f => f).join(': ');
  } 
  
  if (errorStatus) {
    return <div className="errorstatus">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="errorstatus-main">
          <div className="errorstatus-main-symbol"><FontAwesomeIcon icon={faExclamationTriangle} /></div>
          <div className="errorstatus-main-time">{t('datumzeit', { date: errorStatus.timestamp })}</div>
          <div className="errorstatus-main-msg">{errorStatus.message?.toString()}</div>
        </div>
        <div className="errorstatus-close" onClick={clearErrorStatus}><i className="pi pi-times" /></div>
      </div>
      {errorStatus.errors && <div className="errorstatus-details">
        <ul>
          {errorStatus.errors.map((err, idx) => (<li key={idx}>
            <div className={classNames("errorstatus-details-entry", { "errorstatus-details-warn": err.type === MessageType.WARNING })}>
              <div className="errorstatus-details-entry-symbol"><FontAwesomeIcon icon={err.type === MessageType.WARNING ? faInfoCircle : faExclamationTriangle} /></div>
              <div>{formatDetailMessage(err)}</div>
            </div>
          </li>))}
        </ul>
      </div>}
    </div>;
  }
  return null;
};