import { AxiosResponse } from 'axios';
import { Button } from "primereact/button";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { useBooking } from "../contexts/booking.context";
import { ViewNameEnum } from '../dto/ViewNameEnum';
import { tourService } from '../services/tour.service';
import { SelectionHeaderView } from "./selectionheader.component";
import { NavigateBackNextView } from "./subComponents/navigatebacknext.component";

export const BuchungView = () => {
  const { tour, setTour, setContainer } = useBooking();
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const load = async () => {

      if( tour ) {
      const tFunc = () => ( 
        tour.containerList ? tourService.bucheTour({ ...tour, truckerCardId: undefined }) 
                           : tourService.bucheTourUm( { tpOid: tour.tourenplanOid, termin: tour.termin })
        );

      tour && tFunc()
        .then((response: AxiosResponse) => {
          setTour(undefined);
          setContainer(undefined);
          navigate(ViewNameEnum.ROOT);
        }).catch(() => setLoading(false));
      }
    };
    load();
  }, [navigate, setContainer, setTour, tour]);

  return <div>
    <SelectionHeaderView />
    <div className="content">
      <div className="booking">
        {loading ? <>
          <i className="pi pi-spin pi-spinner" />
          <div>{t("booking")}</div>
        </> :
          <div>
            <Button label={t('action.abort')} className="primary-btn" onClick={() => {
              setTour(undefined);
              setContainer(undefined);
              navigate(ViewNameEnum.ROOT);
            }
            } autoFocus />
          </div>
        }
      </div>
    </div>
    <NavigateBackNextView />

  </div>;
};