import { AxiosResponse } from "axios";
import { UserDTO } from "../dto/UserDTO";
import { apiPath } from "../utils/httpConfig";
import { httpService, makeURLCall } from "../utils/httpUtil";

export type UserService = {
  getUsers: (filter?: string) => Promise<AxiosResponse<UserDTO[], any>>;
};

class UserServiceLocal implements UserService {
  getUsers(filter?: string) {
    return httpService
      .getAxios()
      .then((ai) => ai.get<UserDTO[]>(makeURLCall(apiPath("/users"), { filter })));
  }
}

export const userService = new UserServiceLocal() as UserService;
