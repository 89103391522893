import { UserManager, UserManagerSettings } from 'oidc-client-ts';
import React, { ReactNode, useCallback, useContext, useEffect } from 'react';
import { OidcProviderDTO } from "../dto/OidcProviderDTO";
import { useSession } from "./session.context";
import { useSystemparameter } from "./systemparameter.context";
import {v4 as uuidv4} from 'uuid';

type IdpContextType = {
  providerName?: string;
  providerCode?: string;
  userManager?: UserManager;
  setProviderCode: (code?: string) => void;
  signOutRedirect: (uri?: string) => Promise<void>;
};

const IdpContext = React.createContext<IdpContextType>({
  setProviderCode: () => {},
  signOutRedirect: (uri?: string) => (Promise.resolve())
 });

export const IdpContextProvider = (props: { children: ReactNode }) => {
  const [providerName, setProviderName] = React.useState<string|undefined>();
  const [userManager, setUserManager] = React.useState<UserManager|undefined>();
  const [providerCode, setProviderCode] = React.useState<string|undefined>();
  const { systemparameter } = useSystemparameter();
  const { locale } = useSession();
  const basePath = process.env.PUBLIC_URL || "";
  const currentPath = window.location.protocol + '//' + window.location.hostname + (window.location.port && (':' + window.location.port)) + basePath;

  useEffect(() => {
    const oidcProvider = systemparameter?.oidcProviders.find((p) => p.code === providerCode);
    const settings = oidcProvider && getUserManagerSettings(oidcProvider, currentPath, locale);
   
    setProviderName(oidcProvider?.name);
    settings && setUserManager(new UserManager(settings) );
  }, [currentPath, locale, providerCode, systemparameter?.oidcProviders]);

  const signOutRedirect = useCallback((uri?: string) => {
    return Promise.resolve().then(() => userManager?.signoutRedirect({post_logout_redirect_uri: currentPath}) );
  }, [currentPath, userManager]);
  
  return <IdpContext.Provider value={{ providerName, userManager, setProviderCode, signOutRedirect }}>
      {props.children}
  </IdpContext.Provider>;
};

export const useIdp = () => {
  const context = useContext(IdpContext);
  if (context === undefined) {
    throw new Error('useSessionContext must be used within a SessionContextProvider');
  }
  return context;
};

const getUserManagerSettings = (oidcProvider: OidcProviderDTO, redirectUri: string, locale?: string): UserManagerSettings  => {

  const oidcConfig = {
    authority: oidcProvider.authority,
    client_id: oidcProvider.clientId,
    redirect_uri: redirectUri,
    extraQueryParams: {
      ui_locales: locale,
      nonce: uuidv4(),
    }
  } as UserManagerSettings;

  return { ...oidcConfig }
}
