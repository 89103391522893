import { useTranslation } from 'react-i18next';
import imgAusliferung from '../../assets/images/tourenarten/export_128x76.png';
import imgAnliferung from '../../assets/images/tourenarten/import_128x76.png';
import imgLeerAusgang from '../../assets/images/tourenarten/leerAusgang_128x76.png';
import imgLeerEingang from '../../assets/images/tourenarten/leerEingang_128x76.png';
import { TourenartEnum } from '../../dto/TourenartEnum';


type Props = { srcName: string, alt: string; };
export const TourenartImage: React.FC<Props> = ({ srcName, alt }) => {
  const { t } = useTranslation();
  return (
    <>
      <img src={getImageSrc(srcName)} alt={alt} />
      <span>{t(alt as any)}</span>
    </>
  );
};

const getImageSrc = (srcName: string) => {
  switch (srcName) {
    case TourenartEnum.ANLIEFERUNG:
      return imgAnliferung;
    case TourenartEnum.AUSLIEFERUNG:
      return imgAusliferung;
    case TourenartEnum.LEER_DEPOT_EINGANG:
      return imgLeerEingang;
    case TourenartEnum.LEER_DEPOT_AUSGANG:
      return imgLeerAusgang;
    default:
      return "";
  }
};