
import { AxiosResponse } from 'axios';
import { Buffer } from 'buffer';
import { Jwt } from "../dto/Jwt";
import { LoginDTO } from "../dto/LoginDTO";
import { LoginResponse } from "../dto/LoginResponse";
import { SessionDTO } from "../dto/SessionDTO";
import { apiPath } from "../utils/httpConfig";
import { httpService } from "../utils/httpUtil";
import { localStorageUtil } from "../utils/local.storage.util";

const JWT = "Truckgate-JWT";
const REFRESH_JWT = "Truckgate-Refresh-JWT";
const IDP_REFRESH_JWT = "Truckgate-IDP-Refresh-JWT";
const TIME_DIFF = "Truckgate-Time-Diff";

type UpdateTokenResponse = {
  updated: boolean;
  token: string | null;
  session?: SessionDTO;
}

export type SessionService = {
  login: (login: LoginDTO) => Promise<AxiosResponse<LoginResponse, any>>;
  tokenLogin: (token: string, locale?: string) => Promise<AxiosResponse<LoginResponse, any>>;
  logout: (refreshToken?: string) => Promise<AxiosResponse<void, any> | undefined>;
  refresh: (refreshToken: string, idpRefreshToken?: string) => Promise<AxiosResponse<LoginResponse, any>>;
  changeUser: (username: string) => Promise<AxiosResponse<LoginResponse, any>>;

  getToken: () => string | null;
  setToken: (token?: string) => void;
  getRefreshtoken: () => string | null;
  setRefreshtoken: (refreshtoken?: string) => void;
  getIdpRefreshtoken: () => string | null;
  setIdpRefreshtoken: (refreshtoken?: string) => void;
  updateToken: (minValidity: number) => Promise<UpdateTokenResponse>;
  getSession: () => SessionDTO | undefined;

  isRefreshRequest: (url?: string) => boolean;
};

class SessionServiceLocal implements SessionService {
  login(login: LoginDTO) {
    return httpService
      .getAxios()
      .then((ai) =>
        ai.post<LoginResponse>(apiPath("/sess/login"), login)
      )
      .then((resp) => {
        this.initToken(resp.data);
        return resp;
      });
  }

  tokenLogin(token: string, locale?: string) {
    return httpService
      .getAxios()
      .then((ai) =>
        ai.post<LoginResponse>(apiPath("/sess/tokenlogin"), { token, locale } )
      )
      .then((resp) => {
        this.initToken(resp.data);
        return resp;
      });
  }

  changeUser(username: string) {
    return httpService
      .getAxios()
      .then((ai) =>
        ai.post<LoginResponse>(apiPath("/sess/changeuser"), username )
      )
      .then((resp) => {
        this.initToken(resp.data);
        return resp;
      });
  }

  logout(refreshToken?: string) {
    return httpService
      .getAxios()
      .then((ai) =>
        this.getToken() || this.getRefreshtoken() ? ai.delete<void>(apiPath("/session"), {
          data: { token: refreshToken } as Jwt,
        }) : undefined
      )
      .then((resp) => {
        this.setRefreshtoken(undefined);
        this.setToken(undefined);
        return resp;
      });
  }

  refresh(refreshToken: string, idpRefreshToken?: string) {
    return httpService
      .getAxios()
      .then((ai) =>
        ai.post<LoginResponse>(apiPath("/sess/refresh"), {
          token: refreshToken,
          idpRefreshToken: idpRefreshToken
        })
      )
      .then((resp) => {
        this.initToken(resp.data);
        return resp;
      });
  }
  
  getToken() {
    return localStorageUtil.get(JWT);
  }

  setToken(token: string | undefined) {
    token ? localStorageUtil.add(JWT, token) : localStorageUtil.remove(JWT);
    
    const iat = this.getTokenIat();
    if(iat) {
      const now = (new Date().getTime() / 1000) | 0;
      const diff = (now - iat);
      localStorageUtil.add(TIME_DIFF, diff.toString());
    }
  }

  getRefreshtoken() {
    return localStorageUtil.get(REFRESH_JWT);
  }

  setRefreshtoken(token: string | undefined) {
    token
      ? localStorageUtil.add(REFRESH_JWT, token)
      : localStorageUtil.remove(REFRESH_JWT);
  }

  getIdpRefreshtoken() {
    return localStorageUtil.get(IDP_REFRESH_JWT);
  }

  setIdpRefreshtoken(token: string | undefined) {
    token
      ? localStorageUtil.add(IDP_REFRESH_JWT, token)
      : localStorageUtil.remove(IDP_REFRESH_JWT);
  }


  updateToken(minValidity: number) {
    return Promise.resolve().then(() => {
      const valid = this.isSessionValid(minValidity);

      if (!valid) {
        const refreshToken = this.getRefreshtoken();
        if (refreshToken) {
          return this.refresh(refreshToken, this.getIdpRefreshtoken() || undefined).then((resp) => ({ updated: true, token: resp.data.token, session: resp.data.session }) as UpdateTokenResponse);
        }
      }

      return { updated: false, token: this.getToken(), session: this.getSession() } as UpdateTokenResponse;
    });
  }

  initToken(loginResponse: LoginResponse) {
    loginResponse.refreshToken && this.setRefreshtoken(loginResponse.refreshToken);
    loginResponse.idpRefreshToken && this.setIdpRefreshtoken(loginResponse.idpRefreshToken);
    this.setToken(loginResponse.token);
  }

  parseJwt(token: string | null): any {
    if (!token) { return; }
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = Buffer.from(base64, 'base64').toString('utf-8');
    return JSON.parse(jsonPayload);
  }

  getTokenExpiration() {
    const token = this.getToken();
    return token && (this.parseJwt(token)?.exp as number);
  }

  getTokenIat() {
    const token = this.getToken();
    return token && (this.parseJwt(token)?.iat as number);
  }

  getSession() {
    const token = this.getToken();
    return token && JSON.parse(this.parseJwt(token)?.session);
   }

   isSessionValid(minValidity: number = 60) {
    const session = this.getSession();
    if(!session) {
      return false;
    }

    const exp = this.getTokenExpiration();
    const now = (new Date().getTime() / 1000) - (this.getTimeDiff() | 0);

    let remainingTimeSec = 0;
    if (exp) {
      remainingTimeSec = exp - now - minValidity;
    }

    if (remainingTimeSec <= 0) {
      return false;
   }

   return true;
  }

  /** Zeitdifferenz Serverzeit / Clientzeit in Sec */
  getTimeDiff() {
    return parseInt(localStorageUtil.get(TIME_DIFF) || '0');    
  }

  isRefreshRequest(url?: string) {
    return url?.endsWith("/sessionrefresh") || url?.endsWith("/sess/refresh" || url?.endsWith("/tokenlogin")) || false;
  }
}

export const sessionService = new SessionServiceLocal() as SessionService;
