import { useTranslation } from "react-i18next";
import { useBooking } from "../contexts/booking.context";
import { ViewNameEnum } from "../dto/ViewNameEnum";
import { SelectionHeaderView } from "./selectionheader.component";
import { InputNumberRight } from './subComponents/inputnumberright.component';
import { NavigateBackNextView } from "./subComponents/navigatebacknext.component";

export const CtrT1PckStkView = () => {
  const { container, setContainer } = useBooking();
  const { t } = useTranslation();

  const nextView = () => {
    return ViewNameEnum.CTRSHOW;
  };

  const setAnzahlPackstuecke = (anzahlpackstueckeNeu?: number) => {
    container && setContainer({ ...container, anzahlpackstuecke: anzahlpackstueckeNeu });
  };

  return <div>
    <SelectionHeaderView />
    <div className="title">{t('CtrT1PckstkView')}</div>

    <div className='formbox-wrapper content'>
      <div className='formbox'>
        <div className='formbox-field'>
          <label htmlFor="anzahlpackstuecke">{t('anzahlpackstuecke')}</label>
          <InputNumberRight
            id="anzahlpackstuecke"
            value={container?.anzahlpackstuecke}
            onChange={(e) => setAnzahlPackstuecke(e.value || undefined)}
            onValueChange={(e) => setAnzahlPackstuecke(e.value || undefined)}
            max={99999}
            min={1}
            autoFocus />
        </div>
      </div>
    </div>

    <NavigateBackNextView nextView={nextView} nextDisabled={!container?.anzahlpackstuecke} />

  </div>;
};