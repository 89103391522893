import { MessageType } from "../../dto/MessageType";
import { ValidationErrorDTO } from "../../dto/ValidationErrorDTO";

type Props = { property: string, validationErrors: ValidationErrorDTO[]; };

export const ValidationInfo: React.FC<Props> = ({ property, validationErrors }) => {
    return <>{validationErrors.filter(err => err.property === property)
        .map((err, idx) =>
            <span key={idx} className={err.type === MessageType.ERROR ? "formbox-field-error" : "formbox-field-warning"}>
                {err.message}
            </span>)}</>;
};