import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ViewNameEnum } from "../../dto/ViewNameEnum";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesLeft, faAnglesRight } from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useRef } from "react";

type Props = {
  disabled?: boolean;
  nextView?: () => ViewNameEnum | undefined;
  onNextView?: (nextView: ViewNameEnum) => void;
  nextButtonLabel?: string;
  nextDisabled?: boolean;
  nextAutoFocus?: boolean;
  prevViewVisible?: boolean;
};

export const NavigateBackNextView = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const nvButtonRef = useRef<any>(null);

  // Fokus auf next-Button setzen, wenn nextAutoFocus auf true wechselt
  useEffect(() => {
    props.nextAutoFocus && nvButtonRef?.current?.focus();
  }, [props.nextAutoFocus]);

  if (!props.disabled) {
    const nextView = props.nextView && props.nextView();

    return (
      <div className="nav-back-next-btns floating-btn">
        {props.prevViewVisible !== false && <Button className="primary-btn" onClick={() => navigate(-1)}><FontAwesomeIcon icon={faAnglesLeft} /><span>{t('back')}</span></Button>}
        {nextView && <Button ref={nvButtonRef} autoFocus={props.nextAutoFocus} className="primary-btn" label={props.nextButtonLabel || t('next')} onClick={() => {
          props.onNextView && props.onNextView(nextView);
          navigate(nextView);
        }} disabled={props.nextDisabled}> <FontAwesomeIcon icon={faAnglesRight} /></Button>}
      </div>
    );
  }

  return null;
};