import { AxiosResponse } from "axios";
import { ClientMessageDTO } from "../dto/ClientMessageDTO";
import { apiPath } from "../utils/httpConfig";
import { httpService, makeURLCall } from "../utils/httpUtil";

export type ClientMessageService = {
  load: (locale?: string) => Promise<AxiosResponse<ClientMessageDTO[], any>>;
};

class ClientMessageServiceLocal implements ClientMessageService {
  load(locale?: string) {
    return httpService
      .getAxios()
      .then((ai) => ai.get<ClientMessageDTO[]>(makeURLCall(apiPath("/clientmessages"), { locale })));
  }
}

export const clientMessageService = new ClientMessageServiceLocal() as ClientMessageService;
