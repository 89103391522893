import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

export const showCustomToast = (toastRef: React.RefObject<Toast>, title: string, message: string, firstBtnTitle: string, secondBtnTitle: string, confirm: () => void) => {

    const onCancelClick = () => {
        toastRef.current?.clear();
    };

    const onConfirmClick = () => {
        confirm();
        toastRef.current?.clear();
    };

    toastRef.current?.show({
        severity: 'warn',
        sticky: true,
        content: warnLayout(title, message, firstBtnTitle, secondBtnTitle, onConfirmClick, onCancelClick)
    });
};

const warnLayout = (title: string, message: string, firstBtnTitle: string, secondBtnTitle: string, confirm: () => void, cancel: () => void) => {
    return (
        <div className="flex flex-column" style={{ flex: '1' }}>
            <div className="text-center">
                <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }} />
                <div className="warn-title">{title}</div>
                <p>{message}</p>
            </div>
            <div className="warn-buttons">
                <div className="warn-button">
                    <Button type="button" label={firstBtnTitle} className="p-button-success" onClick={confirm} autoFocus />
                </div>
                <div className="warn-button">
                    <Button type="button" label={secondBtnTitle} className="p-button-secondary" onClick={cancel} />
                </div>
            </div>
        </div>
    );
};
