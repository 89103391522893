import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";
import { useBooking } from "../contexts/booking.context";
import { TourenartEnum } from "../dto/TourenartEnum";
import { ViewNameEnum } from "../dto/ViewNameEnum";
import { NavigateBackNextView } from "./subComponents/navigatebacknext.component";
import { SelectionHeaderView } from "./selectionheader.component";
import { FormView } from "./subComponents/formview.component";
import { containerValidationService, PROPERTY_QUAYACCOUNTNUMBER } from "../services/container.validation.service";
import { ContainerDTO } from "../dto/ContainerDTO";
import { ValidationErrorDTO } from "../dto/ValidationErrorDTO";
import { ValidationInfo } from "./subComponents/validationinfo.component";
import { useState } from "react";

export const CtrQuayAccountNumberView = () => {
  const validate = (ctr: ContainerDTO) => (containerValidationService.validate(ctr, (a) => a === PROPERTY_QUAYACCOUNTNUMBER));

  const { container, setContainer } = useBooking();
  const { t } = useTranslation();
  const [validationErrors, setValidationErrors] = useState<ValidationErrorDTO[]>(container ? validate(container) : []);

  const validateAndUpdate = (newContainer: ContainerDTO) => {
    setValidationErrors(validate(newContainer));
    setContainer(newContainer);
    return true;
  };

  const nextView = () => {
    if (container?.ueberdimensioniert) {
      return ViewNameEnum.CTROOG;
    }
    if (container?.t1) {
      return container?.tourenart === TourenartEnum.AUSLIEFERUNG ? ViewNameEnum.CTRT1PCKSTK : ViewNameEnum.CTRT1MRN;
    }
    return container?.tourenart === TourenartEnum.AUSLIEFERUNG ? ViewNameEnum.CTRSHOW : ViewNameEnum.CTRCARRIERCODE;
  };

  return <div>
    <SelectionHeaderView />
    <div className="title">{t('quayAccountNumber')}</div>
    <FormView nextView={nextView} nextEnabled={container && container.quayAccountNumber !== ''}>
      <div className='formbox-wrapper content'>
        <div className='formbox'>
          <div className='formbox-field'>
            <label htmlFor="quayAccountNumber">{t('quayAccountNumber')}</label>
            <InputText
              id="quayAccountNumber"
              data-testid="input"
              value={container?.quayAccountNumber || ''}
              onChange={(e) => container && validateAndUpdate({ ...container, quayAccountNumber: e.target.value })}
              maxLength={7}
              autoFocus />
          </div>
          <ValidationInfo property={PROPERTY_QUAYACCOUNTNUMBER} validationErrors={validationErrors} />
        </div>
      </div>
    </FormView>
    <NavigateBackNextView nextView={nextView} nextDisabled={!container?.quayAccountNumber} />

  </div>;
};