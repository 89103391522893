import { AxiosResponse } from "axios";
import { SystemparameterDTO } from "../dto/SystemparameterDTO";
import { apiPath } from "../utils/httpConfig";
import { httpService } from "../utils/httpUtil";

export type SystemparameterService = {
  load: () => Promise<AxiosResponse<SystemparameterDTO, any>>;
};

class SystemparameterServiceLocal implements SystemparameterService {
  load() {
    return httpService
      .getAxios()
      .then((ai) => ai.get<SystemparameterDTO>(apiPath("/systemparameter/truckgate")));
  }
}

export const systemparameterService = new SystemparameterServiceLocal() as SystemparameterService;
