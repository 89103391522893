import { AxiosResponse } from "axios";
import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { StammdatenContainerIsoCodeDTO } from "../dto/StammdatenContainerIsoCodeDTO";
import { StammdatenReederDTO } from "../dto/StammdatenReederDTO";
import { StammdatenUnLoCodeDTO } from "../dto/StammdatenUnLoCodeDTO";
import { TerminalDTO } from "../dto/TerminalDTO";
import { stammdatenService } from "../services/stammdaten.service";
import { terminalService } from "../services/terminal.service";
import { useSession } from "./session.context";

type StammdatenContextType = {
  initialized: boolean;
  terminals?: TerminalDTO[];
  containerIsoCodes?: StammdatenContainerIsoCodeDTO[];
  containeroperators?: StammdatenReederDTO[];
  fuhrunternehmen?: string[];
  loeschhaefen?: StammdatenUnLoCodeDTO[];
  zielbahnhoefe?: StammdatenUnLoCodeDTO[];

  setFuhrunternehmen: (fuhrunternehmenliste: string[]) => void;
};

const StammdatenContext = React.createContext<StammdatenContextType>({
  initialized: false,
  setFuhrunternehmen: () => {
    // Dummy init
  }
});

// The passed properties are used just for testing
type Props = {
  children: ReactNode;
  terminals?: TerminalDTO[];
  isoCodes?: StammdatenContainerIsoCodeDTO[];
  operator?: StammdatenReederDTO[];
  fuhrunternehmen?: string[];
  loeschhaefen?: StammdatenUnLoCodeDTO[];
  zielbahnhoefe?: StammdatenUnLoCodeDTO[];
};

enum InitState {
  NEW,
  LOADING,
  INITIALIZED,
};

export const StammdatenContextProvider = (props: Props) => {
  const [initState, setInitState] = useState(InitState.NEW);
  const [terminals, setTerminals] = useState<TerminalDTO[] | undefined>(props.terminals);
  const [containerIsoCodes, setContainerIsoCodes] = useState<StammdatenContainerIsoCodeDTO[] | undefined>(props.isoCodes);
  const [containeroperators, setContainerOperators] = useState<StammdatenReederDTO[] | undefined>(props.operator);
  const [fuhrunternehmen, setFuhrunternehmen] = useState<string[] | undefined>(props.fuhrunternehmen);
  const [loeschhaefen, setLoeschhaefen] = useState<StammdatenUnLoCodeDTO[] | undefined>(props.loeschhaefen);
  const [zielbahnhoefe, setZielbahnhoefe] = useState<StammdatenUnLoCodeDTO[] | undefined>(props.zielbahnhoefe);

  const { session } = useSession();
  const stateRef = useRef(initState);
  const user = session?.user;
  const truckerCardId = session?.supplements.truckerCardId;
  const truckerId = session?.supplements.truckerId;

  useEffect(() => {

    const loadAll = async () => {
      stateRef.current = InitState.LOADING;
      setInitState(InitState.LOADING);
      Promise.all(
        [
          terminalService.getAvailableTerminals().then((response: AxiosResponse<TerminalDTO[]>) => {
            const sortedTermials = response.data?.sort((a, b) => a.order?.localeCompare(b.order));
            setTerminals(sortedTermials);
          }),
          stammdatenService.findContainerOperators().then((response: AxiosResponse<StammdatenReederDTO[]>) => {
            setContainerOperators(response.data);
          }),
          stammdatenService.findContainerIsoCodes().then((response: AxiosResponse<StammdatenContainerIsoCodeDTO[]>) => {
            setContainerIsoCodes(response.data);
          }),
          (truckerCardId || truckerId) && stammdatenService.findFuhrunternehmen({ truckerCardId, truckerId }).then((response: AxiosResponse<string[]>) => {
            setFuhrunternehmen(response.data);
          }),
          stammdatenService.findLoeschhafen().then((response: AxiosResponse<StammdatenUnLoCodeDTO[]>) => {
            setLoeschhaefen(response.data);
          }),
          stammdatenService.findZielbahnhof().then((response: AxiosResponse<StammdatenUnLoCodeDTO[]>) => {
            setZielbahnhoefe(response.data);
          })
        ]).then(() => {
          stateRef.current = InitState.INITIALIZED;
          setInitState(InitState.INITIALIZED);
        }).catch(e => {
          console.log("Stammdaten init failed " + e);
          stateRef.current = InitState.NEW;
          setInitState(InitState.NEW);
        });
    };

    if (stateRef.current === InitState.NEW) {
      user && loadAll();
    } else {
      // Cleanup on logout
      if (!user) {
        setInitState(InitState.NEW);
        stateRef.current = InitState.NEW;
      }
    }
  }, [user, truckerCardId, truckerId]);

  return <StammdatenContext.Provider value={{ terminals, containerIsoCodes, containeroperators, fuhrunternehmen, loeschhaefen, zielbahnhoefe, initialized: initState === InitState.INITIALIZED, setFuhrunternehmen }}>{props.children}</StammdatenContext.Provider>;
};

export const useStammdaten = () => {
  const context = useContext(StammdatenContext);
  if (context === undefined) {
    throw new Error('useStammdatenContext must be used within a StammdatenContextProvider');
  }
  return context;
};

