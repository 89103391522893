import { AxiosInstance } from "axios";

export const httpService = new (class HttpService {
  private axiosInstance?: AxiosInstance;

  setAxiosInstance(a?: AxiosInstance) {
    this.axiosInstance = a;
  }

  getAxiosInstance() {
    return this.axiosInstance;
  }

  getAxios() {
    return Promise.resolve().then(() => {
      const ai = this.getAxiosInstance();
      if (!ai) {
        throw new Error("No Axios instance initialized");
      }
      return ai;
    });
  }
})();

type FilterType = string | boolean | number ;

export const buildURLQuery = (obj: any) => {
  if (!obj) {
    return "";
  }
 
  return Object.entries(obj)
    .filter(([k, v]) => v || v === false)
    .filter(([k, v]) => !Array.isArray(v))
    .map((pair) => pair?.map(v => encodeURIComponent(v as FilterType) ).join("="))
    .join("&");
};

export const makeURLCall = (path: string, param: any) => {
  const query = buildURLQuery(param);
  return [path, query].filter(x => x).join("?");
};
