import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useBooking } from "../contexts/booking.context";
import { ContainerDTO } from '../dto/ContainerDTO';
import { TourenartEnum } from "../dto/TourenartEnum";
import { ValidationErrorDTO } from '../dto/ValidationErrorDTO';
import { ViewNameEnum } from '../dto/ViewNameEnum';
import { containerValidationService, PROPERTY_CONTAINERNUMMER } from '../services/container.validation.service';
import { NavigateBackNextView } from "./subComponents/navigatebacknext.component";
import { SelectionHeaderView } from "./selectionheader.component";
import { ValidationInfo } from './subComponents/validationinfo.component';
import { FormView } from './subComponents/formview.component';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';

export const CtrNrView = () => {

  const navigate = useNavigate();

  const validate = (ctr: ContainerDTO) => (containerValidationService.validate(ctr, (a) => a === PROPERTY_CONTAINERNUMMER, tour));
  const { tour, container, setContainer } = useBooking();
  const { t } = useTranslation();
  const [validationErrors, setValidationErrors] = useState<ValidationErrorDTO[]>(container ? validate(container) : []);

  const nextView = () => (
    container?.tourenart && [TourenartEnum.LEER_DEPOT_EINGANG, TourenartEnum.LEER_DEPOT_AUSGANG].includes(container?.tourenart) ? ViewNameEnum.CTRISOCODE : ViewNameEnum.CTRFULLEMPTY
  );

  const validateAndUpdate = (newContainer: ContainerDTO) => {
    setValidationErrors(validate(newContainer));
    setContainer(newContainer);
    return true;
  };

  return (
    <>
      <SelectionHeaderView />
      <div className="title">{t('containernummer')}</div>
      <FormView nextView={nextView} nextEnabled={validationErrors.length === 0}>
        <div className='content formbox-wrapper'>
          <div className='formbox'>
            <div className='formbox-field'>
              <label htmlFor="ctrNr">{t('containernummer')}</label>
              <InputText
                id="ctrNr"
                keyfilter={/[A-Za-z\d]/}
                value={container?.containernummer || ''}
                className={classNames({ 'p-invalid': validationErrors.find(e => e.property === PROPERTY_CONTAINERNUMMER) })}
                onChange={(e) => container && validateAndUpdate({ ...container, containernummer: e.target.value?.toUpperCase() })}
                maxLength={12}
                autoFocus />
            </div>
            <ValidationInfo property='containernummer' validationErrors={validationErrors} />
            <div className='formbox-field'>
              <label htmlFor="shippersOwned">{t('shippersOwned')}</label>
              <Checkbox id="shippersOwned" checked={container?.shippersOwned || false} onChange={(e) => container && validateAndUpdate({ ...container, shippersOwned: e.checked })} />
            </div>

            {container?.tourenart === TourenartEnum.LEER_DEPOT_AUSGANG &&
              <div className='formbox-field'>
                <Button label={t('weiterOhneContainernummer')} onClick={() => {
                  navigate(nextView());
                }
                } disabled={!!container?.containernummer} />
              </div>
            }
          </div>
        </div>
      </FormView>
      <NavigateBackNextView nextView={nextView} nextDisabled={validationErrors.length !== 0} />
    </>
  );
};