import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useBooking } from "../contexts/booking.context";
import { useStammdaten } from "../contexts/stammdaten.context";
import { ContainerDTO } from "../dto/ContainerDTO";
import { StammdatenContainerIsoCodeDTO } from "../dto/StammdatenContainerIsoCodeDTO";
import { TourenartEnum } from "../dto/TourenartEnum";
import { ViewNameEnum } from "../dto/ViewNameEnum";
import { NavigateBackNextView } from "./subComponents/navigatebacknext.component";
import { SelectionHeaderView } from "./selectionheader.component";
import { TGDropdown } from './subComponents/tgdropdown.component';

const SHORTCUT_ISO_CODES = ["22G1", "42G1", "45G1"];

export const CtrIsoCodeView = () => {
  const { container, setContainer } = useBooking();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { containerIsoCodes } = useStammdaten();
  const [shortCodes, setShortCodes] = useState<StammdatenContainerIsoCodeDTO[]>();

  useEffect(() => {
    const sc = containerIsoCodes?.filter(c => SHORTCUT_ISO_CODES.includes(c.isoAlphaCode));
    if (sc?.length === SHORTCUT_ISO_CODES.length) {
      setShortCodes(sc);
    }
  }, [containerIsoCodes]);

  const nextView = (ctr: ContainerDTO | undefined = container) => {
    const nextView = container?.tourenart === TourenartEnum.ANLIEFERUNG || container?.tourenart === TourenartEnum.AUSLIEFERUNG
      ? ViewNameEnum.CTRADDITIONALDATA
      : ViewNameEnum.CTRCARRIERCODE;
    return !ctr?.containerIsoCode?.isoAlphaCode ? ViewNameEnum.CTRLENGTH : nextView;
  };

  const onNextView = (nv: ViewNameEnum) => {
    if (nv !== ViewNameEnum.CTRLENGTH) {
      container && setContainer({ ...container, containerLaenge: undefined, containerHoehe: undefined, containerType: undefined });
    }
  };

  const makeIsoCodeLabel = (code: StammdatenContainerIsoCodeDTO) => {
    return code?.isoAlphaCode ? code?.isoAlphaCode + " - " + [code?.containerlaenge, code?.containerhoehe, code?.containertyp].filter(e => e).join(" / ") : t('containerIsoCode');
  };

  const setIsoCode = (code?: StammdatenContainerIsoCodeDTO) => {
    if (container) {
      const ctr = { ...container, containerIsoCode: code };
      setContainer(ctr);
      navigate(nextView(ctr));
    }
  };

  const isoCodeViewList = containerIsoCodes?.map(c => ({
    label: makeIsoCodeLabel(c),
    value: c
  }));

  return <div>
    <SelectionHeaderView />
    <div className="title">{t('containerIsoCode')}</div>
    <div className='formbox-wrapper content'>
      <div className='formbox'>
        <div className='formbox-field'>
          <TGDropdown
            placeholder={t('containerIsoCode')}
            value={container?.containerIsoCode}
            options={isoCodeViewList}
            onChange={(e) => container && setContainer({ ...container, containerIsoCode: e.target.value })}
            autoFocus
          />
        </div>
        <div className='formbox-field'>
          {shortCodes?.map((c, idx) => <button key={idx} onClick={() => setIsoCode(c)} >{c.isoAlphaCode}</button>)}
        </div>
        <div className='formbox-field'>
          <button onClick={() => setIsoCode()} disabled={!!container?.containerIsoCode}>{t('weiterOhneIsoCode')}</button>
        </div>
      </div>
    </div>
    <NavigateBackNextView onNextView={onNextView} nextView={nextView} nextDisabled={!container?.containerIsoCode} />
  </div>;
};