import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useBooking } from "../contexts/booking.context";
import { ContainerzustandEnum } from "../dto/ContainerzustandEnum";
import { TourenartEnum } from "../dto/TourenartEnum";
import { ViewNameEnum } from "../dto/ViewNameEnum";
import { SelectionHeaderView } from "./selectionheader.component";
import { NavigateBackNextView } from "./subComponents/navigatebacknext.component";

export const CtrFullEmptyView = () => {
  const navigate = useNavigate();
  const { container, setContainer } = useBooking();
  const { t } = useTranslation();

  const nextView = () => (
    container?.tourenart === TourenartEnum.AUSLIEFERUNG ? ViewNameEnum.CTRADDITIONALDATA : ViewNameEnum.CTRISOCODE
  );

  const setZustand = (zustand: ContainerzustandEnum) => {
    container && setContainer({ ...container, containerzustand: zustand });
    navigate(nextView());
  };

  return (
    <>
      <SelectionHeaderView />
      <div className="title">{t('beladung')}</div>
      <div className='formbox-wrapper content'>
        <div className='formbox'>
          <div className="ctr-full-empty-content">
            <div className='formbox-field'>
              <button autoFocus onClick={() => setZustand(ContainerzustandEnum.VOLL)} >{t('VOLL')}</button>
            </div>
            <div className='formbox-field'>
              <button onClick={() => setZustand(ContainerzustandEnum.LEER)} >{t('LEER')}</button>
            </div>
          </div>
        </div>
      </div>
      <NavigateBackNextView />
    </>
  );
};