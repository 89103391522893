
import { faPlus, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useBooking } from "../contexts/booking.context";
import { useSystemparameter } from "../contexts/systemparameter.context";
import { ContainerDTO } from "../dto/ContainerDTO";
import { TourDTO } from "../dto/TourDTO";
import { ViewNameEnum } from "../dto/ViewNameEnum";
import { SelectionHeaderView } from "./selectionheader.component";
import { NavigateBackNextView } from "./subComponents/navigatebacknext.component";
import { showCustomToast } from "./subComponents/toast.component";
import { TourenartEnum } from '../dto/TourenartEnum';
import { classNames } from 'primereact/utils';


export const CtrShowView = () => {
  const { tour, setTour, container, setContainer } = useBooking();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showOverlay, setShowOverlay] = useState<boolean>();
  const { systemparameter } = useSystemparameter();

  useEffect(() => {
    if (container && tour) {
      const oldList = tour.containerList || [];
      setTour({ ...tour, containerList: [...oldList, { ...container, position: oldList.length + 1 }] });
      setContainer(undefined);
    }
  }, [container, setContainer, tour, setTour]);

  const nextView = () => {
    return tour?.terminal?.truckingCompanyAvailable ? ViewNameEnum.CTRTRUCKINGCOMPANY : ViewNameEnum.TIME;
  };

  const onNextView = (nv: ViewNameEnum) => {
    if (nv === ViewNameEnum.BOOK) {
      const newTour = { ...tour, termin: new Date(), slotklasse: 'standard' } as TourDTO;
      setTour(newTour);
    }
  };

  const removeContainerPosition = (pos: number) => {
    const ctrList = tour?.containerList.filter(c => c.position !== pos).map(c => ({ ...c, position: (c.position < pos ? c.position : (c.position - 1)) })) as ContainerDTO[];
    tour && setTour({ ...tour, containerList: ctrList });
  };

  const toast = useRef<Toast>(null);
  const showCancelWarning = (cancelContainer: ContainerDTO) => {
    setShowOverlay(true);
    showCustomToast(
      toast,
      t('storno'),
      t('bestaetigungsdialoStorno'),
      t('action.ok'),
      t('cancel'),
      () => { removeContainerPosition(cancelContainer.position); }
    );
  };

  const maxContainerCntReached = (tour?.containerList?.length || 0) >= (systemparameter?.maxContainerCntPossible || 12);

  const getFreiBuchNr = (ctr: ContainerDTO) => ([TourenartEnum.LEER_DEPOT_AUSGANG, TourenartEnum.AUSLIEFERUNG].includes(ctr.tourenart)
  ? [t('freistellreferenz'), ctr.freistellreferenz]
  : [TourenartEnum.LEER_DEPOT_EINGANG, TourenartEnum.ANLIEFERUNG].includes(ctr.tourenart) ? [ t('buchungsnummer'), ctr.buchungsnummer ]  : []);

  return <div>
    <Toast ref={toast} position="bottom-right" onHide={() => setShowOverlay(false)} />
    {showOverlay && <div className="overlay" />}
    <SelectionHeaderView />
    <div className="title">{t('CtrShowView')}</div>
    <div className="content">
      <div className="container-session">
        {tour?.containerList?.map(ctr => (
          <div className="container-row-v" key={ctr.position}>
            <button className="close-btn-fa" onClick={() => showCancelWarning(ctr)} title={t('storno')}><FontAwesomeIcon icon={faXmark} /></button>
            {column(t('position'), ctr.position)}
            {column(t('tourenart'), t(ctr.tourenart))}
            {column(t('containernummer'), ctr.containernummer)}
            {column(t('containerIsoCode'), ctr.containerIsoCode?.isoAlphaCode)}
            {column(getFreiBuchNr(ctr)[0], getFreiBuchNr(ctr)[1], 'code')}
            {column(t('carrier'), ctr.carrier?.kurzname)}
          </div>
        ))}
      </div>
      {!maxContainerCntReached && <button className="underline-btn" onClick={() => navigate(ViewNameEnum.TOURENART)} ><FontAwesomeIcon icon={faPlus} /> {t('newCtrButton')}</button>}
    </div>
    <NavigateBackNextView nextAutoFocus={true} prevViewVisible={false} onNextView={onNextView} nextButtonLabel={nextView() === ViewNameEnum.TIME ? t('terminauswahl') : undefined} nextView={nextView} nextDisabled={!tour?.containerList?.length} />
  </div>;
};

const column = (title?: string, value?: any, className?: string) => {
  return (
    title && <div className="column-v">
      <div className="column-title-v">{title}</div>
      <div className={classNames("column-value-v", className)}>{value}</div>
    </div>
  );
};
