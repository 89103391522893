import { classNames } from 'primereact/utils';
import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useBooking } from "../contexts/booking.context";
import { ContainerDTO } from '../dto/ContainerDTO';
import { ValidationErrorDTO } from '../dto/ValidationErrorDTO';
import { ViewNameEnum } from "../dto/ViewNameEnum";
import { containerValidationService, PROPERTY_BRUTTOGEWICHT } from '../services/container.validation.service';
import { SelectionHeaderView } from "./selectionheader.component";
import { InputNumberRight } from './subComponents/inputnumberright.component';
import { NavigateBackNextView } from "./subComponents/navigatebacknext.component";
import { ValidationInfo } from './subComponents/validationinfo.component';

export const CtrWeightView = () => {
  const validate = (ctr: ContainerDTO) => (containerValidationService.validate(ctr, (a) => a === PROPERTY_BRUTTOGEWICHT));

  const { container, setContainer } = useBooking();
  const [validationErrors, setValidationErrors] = useState<ValidationErrorDTO[]>(container ? validate(container) : []);
  const { t } = useTranslation();

  const nextView = () => {
    return ViewNameEnum.CTRBUCHUNGSNUMMER;
  };

  const validateAndUpdate = (newContainer: ContainerDTO) => {
    setValidationErrors(validate(newContainer));
    setContainer(newContainer);
    return true;
  };

  const setGewicht = (bruttogewichtNeu?: number) => {
    container && validateAndUpdate({ ...container, bruttogewicht: bruttogewichtNeu });
  };

  return <div>
    <SelectionHeaderView />
    <div className="title">{t('bruttogewicht')}</div>

    <div className='formbox-wrapper content'>
      <div className='formbox'>
        <div className='formbox-field'>
          <label htmlFor="bruttogewicht">{t('bruttogewichtkg')}</label>
          <InputNumberRight
            data-testid="input"
            id="bruttogewicht"
            value={container?.bruttogewicht}
            className={classNames({ 'p-invalid': validationErrors.find(e => e.property === PROPERTY_BRUTTOGEWICHT) })}
            onChange={(e) => setGewicht(e.value || undefined)}
            onValueChange={(e) => setGewicht(e.value || undefined)}
            min={1}
            max={99999}
            autoFocus />
        </div>
        <ValidationInfo property={PROPERTY_BRUTTOGEWICHT} validationErrors={validationErrors} />
      </div>
    </div>

    <NavigateBackNextView nextView={nextView} nextDisabled={validationErrors.length !== 0} />

  </div>;
};