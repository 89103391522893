import { useBooking } from "../contexts/booking.context";
import { terminalLogos } from "../dto/terminallogos";
import { ImageText } from "./subComponents/ImageText.component";
import { TourenartImage } from "./subComponents/TourenartImage.component";


type Props = {};

export const SelectionHeaderView = (props: Props) => {
  const { tour, container } = useBooking();
  const terminalLogo = terminalLogos[tour?.terminal?.codeForView as keyof typeof terminalLogos];
  return (
    <div className="selection-header-view">
      {container?.tourenart && <div className="selection"><TourenartImage srcName={container?.tourenart.toString() || ""} alt={container?.tourenart.toString() || ""} /></div>}
      {tour?.terminal?.codeForView && <div className="selection"><ImageText src={terminalLogo} alt={tour.terminal?.codeForView} /></div>}
    </div>
  );
};