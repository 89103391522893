import { AxiosResponse } from "axios";
import { SchiffsabfahrtDTO } from "../dto/SchiffsabfahrtDTO";
import { StammdatenContainerIsoCodeDTO } from "../dto/StammdatenContainerIsoCodeDTO";
import { StammdatenReederDTO } from "../dto/StammdatenReederDTO";
import { StammdatenUnLoCodeDTO } from "../dto/StammdatenUnLoCodeDTO";
import { apiPath } from "../utils/httpConfig";
import { httpService, makeURLCall } from "../utils/httpUtil";

export type Filter = {
  filter: string;
};

export type StammdatenService = {
  findContainerIsoCodes: (
    filter?: Filter
  ) => Promise<AxiosResponse<StammdatenContainerIsoCodeDTO[], any>>;

  findContainerLaengen: () => Promise<AxiosResponse<string[], any>>;

  findContainerHoehen: (filter: {
    l?: string;
  }) => Promise<AxiosResponse<string[], any>>;

  findContainerTypen: (filter: {
    l?: string;
    h?: string;
  }) => Promise<AxiosResponse<string[], any>>;

  findContainerOperators: (
    filter?: Filter
  ) => Promise<AxiosResponse<StammdatenReederDTO[], any>>;

  findFuhrunternehmen: (filter: {
    truckerCardId?: string;
    truckerId?: string;
  }) => Promise<AxiosResponse<string[], any>>;

  findCarriageOnInformation: (filter: { terminalCode: string; }) => Promise<AxiosResponse<SchiffsabfahrtDTO[], any>>;

  findZielbahnhof: () => Promise<AxiosResponse<StammdatenUnLoCodeDTO[], any>>;
  findLoeschhafen: () => Promise<AxiosResponse<StammdatenUnLoCodeDTO[], any>>;
};

class StammdatenServiceLocal implements StammdatenService {
  findContainerIsoCodes(filter?: Filter) {
    return httpService
      .getAxios()
      .then((ai) =>
        ai.get<StammdatenContainerIsoCodeDTO[]>(
          makeURLCall(apiPath("/containerisocodes"), filter)
        )
      );
  }

  findContainerLaengen() {
    return httpService
      .getAxios()
      .then((ai) => ai.get<string[]>(apiPath("/containerlaengen")));
  }

  findContainerHoehen(filter: { l?: string; }) {
    return httpService
      .getAxios()
      .then((ai) =>
        ai.get<string[]>(makeURLCall(apiPath("/containerhoehen"), filter))
      );
  }

  findContainerTypen(filter: { l?: string; h?: string; }) {
    return httpService
      .getAxios()
      .then((ai) =>
        ai.get<string[]>(makeURLCall(apiPath("/containertypen"), filter))
      );
  }

  findContainerOperators() {
    return httpService
      .getAxios()
      .then((ai) => ai.get<StammdatenReederDTO[]>(apiPath("/reeder")));
  }

  findFuhrunternehmen(filter: { truckerCardId?: string, truckerId?: string; }) {
    return httpService
      .getAxios()
      .then((ai) =>
        ai.get<string[]>(makeURLCall(apiPath("/fuhrunternehmen"), filter))
      );
  }

  findZielbahnhof() {
    return httpService
      .getAxios()
      .then((ai) => ai.get<StammdatenUnLoCodeDTO[]>(apiPath("/zielbahnhoefe")));
  }

  findLoeschhafen() {
    return httpService
      .getAxios()
      .then((ai) => ai.get<StammdatenUnLoCodeDTO[]>(apiPath("/loeschhaefen")));
  }

  findCarriageOnInformation(filter: { terminalCode: string; }) {
    return httpService
      .getAxios()
      .then((ai) => ai.get<SchiffsabfahrtDTO[]>(makeURLCall(apiPath("/carriageoninfo"), filter)));
  }
}

export const stammdatenService =
  new StammdatenServiceLocal() as StammdatenService;
