import { AxiosResponse } from "axios";
import { apiPath } from "../utils/httpConfig";
import { httpService } from "../utils/httpUtil";
import i18n from 'i18next';

export type HelpService = {
    getHelpHtml: (path: string) => Promise<AxiosResponse<string, any>>;
};

class HelpServiceLocal implements HelpService {
    getHelpHtml(path: string) {
        return httpService
            .getAxios()
            .then((ai) => ai.get<string>(apiPath("/help/" + pathViewNameMapper[path] + "?locale=" + getLocale())));
    }
}
export const helpService = new HelpServiceLocal() as HelpService;

const getLocale = () => {
    const locale = i18n.language;
    const supportLanguages = ["cz", "en", "de", "dk", "hu", "pl", "ro", "ru", "tr"];
    if (!supportLanguages.includes(locale)) { return "en"; }
    if (locale === 'cz') { return 'cs'; }
    return locale || (typeof window !== 'undefined' && window.localStorage.i18nextLng) || 'en';
};

const pathViewNameMapper: Record<string, string> = {
    "/": "TourenplanShowView",
    "/login": "LoginView",
    "/language": "LanguageView",
    "/terminals": "TerminalView",
    "/tourenart": "TourenartView",
    "/ctrnr": "CtrNrView",
    "/ctrfreistellnr": "CtrFreistellNrView",
    "/ctrisocode": "CtrIsoCodeView",
    "/ctrfullempty": "CtrFullEmptyView",
    "/additionaldata": "CtrAdditionalDataView",
    "/ctrlength": "CtrLengthView",
    "/ctrweight": "CtrWeightView",
    "/ctrheight": "CtrHeightView",
    "/ctrtype": "CtrTypeView",
    "/ctrcarriercode": "CtrCarrierCodeView",
    "/ctrturninreference": "CtrTurnInReferenceView",
    "/ctrshow": "CtrShowView",
    "/ctrbuchungsnummer": "CtrBuchungsNummerView",
    "/ctrloeschhafen": "CtrLoeschHafenView",
    "/ctrshipnr": "CtrShipNrView",
    "/ctrpckstk": "CtrT1PckstkView",
    "/ctrt1mrn": "CtrT1MrnView",
    "/ctroog": "",
    "/ctrquayaccountnumber": "",
    "/appointment": "TerminView",
    "/book": "",
};
