import { useSession } from "../contexts/session.context";
import { useAxios } from "../utils/hooks";

interface Props {
  children?: any;
}

export const Initialize = (props: Props) => {
  const { session } = useSession();
  const axios = useAxios(session?.locale);

  return axios ? props.children : null;
};