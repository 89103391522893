import { AxiosResponse } from "axios";
import { TerminalDTO } from "../dto/TerminalDTO";
import { apiPath } from "../utils/httpConfig";
import { httpService } from "../utils/httpUtil";

export type TerminalService = {
  getAvailableTerminals: () => Promise<AxiosResponse<TerminalDTO[], any>>;
};

class TerminalServiceLocal implements TerminalService {
  getAvailableTerminals() {
    return httpService
      .getAxios()
      .then((ai) => ai.get<TerminalDTO[]>(apiPath("/terminals")));
  }
}

export const terminalService = new TerminalServiceLocal() as TerminalService;
