import { ContainerDTO } from "../dto/ContainerDTO";
import { ContainerzustandEnum } from "../dto/ContainerzustandEnum";
import { TerminalDTO } from "../dto/TerminalDTO";
import { hasAdditionalProperties, TourenartEnum } from "../dto/TourenartEnum";

export const PROPERTY_UEBERDIMENSIONIERT = "ueberdimensioniert";
export const PROPERTY_NOR = "nor";
export const PROPERTY_GEFAHRGUT = "gefahrgut";
export const PROPERTY_KLV = "klv";
export const PROPERTY_KAIKONTONUMMER_SPECIFIED = "quayAccountNumberSpecified";
export const PROPERTY_T1 = "t1";

type AdditionalOptionsHash = {
  [key: string]: string;
};

const ADDITIONAL_PROPERTIES_FOR_CONTAINER_TYPE_FULL = {
  P: PROPERTY_UEBERDIMENSIONIERT,
  U: PROPERTY_UEBERDIMENSIONIERT,
  R: PROPERTY_NOR,
  T: PROPERTY_NOR,
} as AdditionalOptionsHash;

const ADDITIONAL_PROPERTIES_FOR_CONTAINER_TYPE_EMPTY = {
  T: PROPERTY_GEFAHRGUT,
} as AdditionalOptionsHash;

export class AdditionalOptionsService {

  // Rückabe einer Liste der zusätzlichen Optionsschritte in dem aktuellen Kontext
  getAdditionalOptions(terminal?: TerminalDTO, container?: ContainerDTO) {
    const additionalProps = new Set<string>();
    const thirdIsoChar = container?.containerIsoCode?.isoAlphaCode?.substring(2, 3) || "";

    if (
      (container?.tourenart === TourenartEnum.AUSLIEFERUNG &&
        terminal?.oogVerarbeitungAuslieferung) ||
      (container?.tourenart === TourenartEnum.ANLIEFERUNG &&
        terminal?.oogVerarbeitungAnlieferung)
    ) {
      additionalProps.add(PROPERTY_UEBERDIMENSIONIERT);
    }

    if (hasAdditionalProperties(container?.tourenart)) {
      if (terminal?.ctrRole) {
        additionalProps.add(PROPERTY_KLV);
      }

      if (terminal?.quayAccountNumberSpecifiable && container?.tourenart === TourenartEnum.AUSLIEFERUNG) {
        additionalProps.add(PROPERTY_KAIKONTONUMMER_SPECIFIED);
      }

      if (container?.containerzustand === ContainerzustandEnum.VOLL) {
        additionalProps.add(PROPERTY_T1);
        additionalProps.add(PROPERTY_GEFAHRGUT);
        const additional = ADDITIONAL_PROPERTIES_FOR_CONTAINER_TYPE_FULL[thirdIsoChar];
        additional && additionalProps.add(additional);
      } else if (container?.tourenart === TourenartEnum.AUSLIEFERUNG) {
        additionalProps.add(PROPERTY_GEFAHRGUT);
      } else {
        const additional = ADDITIONAL_PROPERTIES_FOR_CONTAINER_TYPE_EMPTY[thirdIsoChar];
        additional && additionalProps.add(additional);
      }
    }

    return Array.from(additionalProps);
  }
}

export const additionalOptionsService = new AdditionalOptionsService();
