import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBooking } from "../contexts/booking.context";
import { ContainerDTO } from "../dto/ContainerDTO";
import { ViewNameEnum } from "../dto/ViewNameEnum";
import { stammdatenService } from "../services/stammdaten.service";
import { NavigateBackNextView } from "./subComponents/navigatebacknext.component";
import { SelectionHeaderView } from "./selectionheader.component";
import { StringShortcutSelectorView } from "./subComponents/stringshortcutselectorview.component";

const SHORTCUT_CODES = ["20", "40"];

export const CtrLengthView = () => {
  const { container, setContainer } = useBooking();
  const { t } = useTranslation();
  const [containerLaengeList, setContainerLaengeList] = useState<string[]>();

  useEffect(() => {
    const load = async () => {
      stammdatenService.findContainerLaengen()
        .then((response: AxiosResponse) => {
          setContainerLaengeList(response.data);
        });
    };
    load();
  }, []);

  const nextView = (ctr: ContainerDTO | undefined = container) => (
    ViewNameEnum.CTRHEIGHT
  );

  const makeCtrLaengeLabel = (laenge: string) => {
    return laenge ? laenge + " (" + t('laenge') + " " + laenge + "')" : t('containerLaenge');
  };

  return <div>
    <SelectionHeaderView />

    <div className="title">{t('containerLaenge')}</div>

    <div className="content">
      <StringShortcutSelectorView label={t('containerLaenge')}
        itemTemplate={makeCtrLaengeLabel}
        selectedString={container?.containerLaenge}
        setSelectedString={(str) => container && setContainer({ ...container, containerLaenge: str })}
        nextView={nextView}
        stringList={containerLaengeList || []} shortcutStringList={SHORTCUT_CODES}
      />
    </div>

    <NavigateBackNextView nextView={nextView} nextDisabled={!container?.containerLaenge} />

  </div>;
};