import { t as ti, TFunction } from "i18next";
import { ContainerDTO } from "../dto/ContainerDTO";
import { MessageType } from "../dto/MessageType";
import { TourDTO } from "../dto/TourDTO";
import { ValidationErrorDTO } from "../dto/ValidationErrorDTO";
import { checkIsoString } from "../utils/IsoStringCheck";

const LADEEINHEIT_ID_REGEX = /^[A-Z]{4}\d{7,8}$/;
const WARENPOSITION_REGEX = /^\d{1,5}(?:,\d{1,5})*$/;
const MRN_T1_REGEX = /^MRN\d{2}[A-Z]{2}\w{12}[^RU]\d$/;

export const PROPERTY_CONTAINERNUMMER = "containernummer";
export const PROPERTY_UERBERHOEHE = "ueberhoehe";
export const PROPERTY_LOESCHHAFEN = "loeschhafen";
export const PROPERTY_WARENPOSITION = "warenposition";
export const PROPERTY_MRN = "mrn";
export const PROPERTY_CARRIAGEONINFORMATION = "carriageOnInformation";
export const PROPERTY_BRUTTOGEWICHT = "bruttogewicht";
export const PROPERTY_TURNINREFERENZ = "turninreferenz";
export const PROPERTY_FREISTELLREFERENZ = "freistellreferenz";
export const PROPERTY_BUCHUNGSNUMMER = "buchungsnummer";
export const PROPERTY_QUAYACCOUNTNUMBER = "quayAccountNumber";

export class ContainerValidationService {


  validate(
    container: ContainerDTO,
    propertyFilter: (property: string) => boolean = () => true,
    tour?: TourDTO
  ): ValidationErrorDTO[] {
    let errors = [] as ValidationErrorDTO[];

    propertyFilter(PROPERTY_CONTAINERNUMMER) && validateContainernummer(container, errors, tour);
    propertyFilter(PROPERTY_UERBERHOEHE) && validateUebermasse(container, errors);
    propertyFilter(PROPERTY_LOESCHHAFEN) && validateLoeschhafen(container, errors);
    propertyFilter(PROPERTY_WARENPOSITION) && validateWarenposition(container, errors);
    propertyFilter(PROPERTY_CARRIAGEONINFORMATION) && validateCarriageOnInformation(container, errors);
    propertyFilter(PROPERTY_BRUTTOGEWICHT) && validateBruttogewicht(container, errors);
    propertyFilter(PROPERTY_MRN) && validateMrn(container, errors);
    propertyFilter(PROPERTY_TURNINREFERENZ) && validateTurninReferenz(container, errors);
    propertyFilter(PROPERTY_FREISTELLREFERENZ) && validateFreistellReferenz(container, errors);
    propertyFilter(PROPERTY_BUCHUNGSNUMMER) && validateBuchungsnummer(container, errors);
    propertyFilter(PROPERTY_QUAYACCOUNTNUMBER) && validateQuayAccountNumber(container, errors);

    return errors;
  }

  setT(myt: TFunction) { t = myt; } //only for test

}

let t = ti; //only for test

const makeError = (proerty: string, message: string, type: MessageType = MessageType.ERROR) => {
  return {
    property: proerty,
    type: type,
    message: message,
  } as ValidationErrorDTO;
};

const validateCarriageOnInformation = (
  container: ContainerDTO,
  errors: ValidationErrorDTO[]
) => {
  if (!container.carriageOnInformation) {
    errors.push(makeError(PROPERTY_CARRIAGEONINFORMATION, t("error.mandatory", { property: t(PROPERTY_CARRIAGEONINFORMATION), }))
    );
  }
};

const validateBruttogewicht = (
  container: ContainerDTO,
  errors: ValidationErrorDTO[]
) => {
  if (!container?.bruttogewicht) {
    errors.push(makeError(PROPERTY_BRUTTOGEWICHT, t("error.mandatory", { property: t(PROPERTY_BRUTTOGEWICHT), })));
  } else {
    const tara = container.containerIsoCode?.tara;
    if (tara && tara.unit === "KGM") {
      if (container.bruttogewicht < tara.value) {
        errors.push(makeError(PROPERTY_BRUTTOGEWICHT, t("gewichtBruttoLowerThanGewichtTara", { tara: tara.value })));
      }
    }

    const maxBrutto = container.containerIsoCode?.maxBrutto;
    if (maxBrutto && maxBrutto.unit === "KGM") {
      if (container.bruttogewicht > maxBrutto.value) {
        errors.push(makeError(PROPERTY_BRUTTOGEWICHT, t("gewichtBruttoGreaterThanGewichtMax", { maxBrutto: maxBrutto.value, })));
      }
    }
  }
};

const validateWarenposition = (
  container: ContainerDTO,
  errors: ValidationErrorDTO[]
) => {
  if (!container?.warenposition?.match(WARENPOSITION_REGEX)) {
    errors.push(makeError(PROPERTY_WARENPOSITION, t("error.regexpExampleWp")));
  }

  if (container?.warenposition) {
    const values = container.warenposition.split(",");
    const unique = Array.from(new Set(values));
    if (values.length !== unique.length) {
      errors.push(makeError(PROPERTY_WARENPOSITION, t("error.singleEntry", { property: t(PROPERTY_WARENPOSITION), })));
    }
  }
};

const validateLoeschhafen = (
  container: ContainerDTO,
  errors: ValidationErrorDTO[]
) => {
  if (!container.loeschhafen) {
    errors.push(makeError(PROPERTY_LOESCHHAFEN, t("error.mandatory", { property: t(PROPERTY_LOESCHHAFEN), })));
  }
};

const validateContainernummer = (
  container: ContainerDTO,
  errors: ValidationErrorDTO[],
  tour?: TourDTO
) => {
  if (container.shippersOwned) {
    if (!container.containernummer || container.containernummer.length <= 0 || container.containernummer.length > 12) {
      errors.push(makeError(PROPERTY_CONTAINERNUMMER, t("error.length", { property: t(PROPERTY_CONTAINERNUMMER), min: 1, max: 12, })));
    }
  } else {
    if (!container?.containernummer?.match(LADEEINHEIT_ID_REGEX)) {
      errors.push(makeError(PROPERTY_CONTAINERNUMMER, t("regExErrorMessage.ladeeinheitenID")));
    } else {
      // Prüfziffer...
      if (!checkIsoString(container?.containernummer)) {
        errors.push(makeError(PROPERTY_CONTAINERNUMMER, t("error.invalidModulo11")));
      }
    }
  }

  if (
    container.containernummer && container.containernummer.length > 0 && tour?.containerList
      ?.map((co) => co.containernummer)
      .includes(container.containernummer)
  ) {
    errors.push(makeError(PROPERTY_CONTAINERNUMMER, t("error.notUnique")));
  }
};

const validateUebermasse = (
  container: ContainerDTO,
  errors: ValidationErrorDTO[]
) => {
  if (
    !container.ueberbreiteHinten &&
    !container.ueberbreiteLinks &&
    !container.ueberbreiteRechts &&
    !container.ueberbreiteVorn &&
    !container.ueberhoehe &&
    container.ueberdimensioniert
  ) {
    errors.push(
      makeError(PROPERTY_UERBERHOEHE, t("error.atleastOneFieldMandatory"))
    );
  }
};

const validateMrn = (container: ContainerDTO, errors: ValidationErrorDTO[]) => {
  if (!container.mrn) {
    errors.push(makeError(PROPERTY_MRN, t("error.mandatory", { property: t(PROPERTY_MRN), })));
    return;
  }

  if (container.mrn.length < 7 || !container.mrn?.startsWith("MRN")) {
    errors.push(makeError(PROPERTY_MRN, t("error.regexpExampleMRN")));
    return;
  }

  const mrn = container.mrn.substring(3);

  if (mrn.substring(2, 4) === "GB") {
    // GC-5622 GB gehorcht nicht dem CheckDigit verfahren
    return true;
  }

  if (mrn.length !== 18) {
    errors.push(makeError(PROPERTY_MRN, t("error.regexpExampleMRN")));
    return false;
  }

  if(!MRN_T1_REGEX.test(container.mrn)){
    errors.push(makeError(PROPERTY_MRN, t("error.regexpExampleMRN"), MessageType.WARNING));
  }

  if (!checkIsoString(mrn)) {
    errors.push(makeError(PROPERTY_MRN, t("error.invalidModulo11")));
  }
};

const validateTurninReferenz = (container: ContainerDTO, errors: ValidationErrorDTO[]) => {
  if (!container.turninreferenz || container.turninreferenz.length > 35) {
    errors.push(makeError(PROPERTY_TURNINREFERENZ, t("error.length", { property: t(PROPERTY_TURNINREFERENZ), 'min': 1, 'max': 35 })));
  }
};

const validateFreistellReferenz = (container: ContainerDTO, errors: ValidationErrorDTO[]) => {
  if (!container.freistellreferenz || container.freistellreferenz.length > 35) {
    errors.push(makeError(PROPERTY_FREISTELLREFERENZ, t("error.length", { property: t(PROPERTY_FREISTELLREFERENZ), 'min': 1, 'max': 35 })));
  }
};

const validateBuchungsnummer = (container: ContainerDTO, errors: ValidationErrorDTO[]) => {
  if (!container.buchungsnummer || container.buchungsnummer.length > 22) {
    errors.push(makeError(PROPERTY_BUCHUNGSNUMMER, t("error.length", { property: t(PROPERTY_BUCHUNGSNUMMER), 'min': 1, 'max': 22 })));
  }
};

const validateQuayAccountNumber = (container: ContainerDTO, errors: ValidationErrorDTO[]) => {
  if (!container.quayAccountNumber || container.quayAccountNumber.length > 7) {
    errors.push(makeError(PROPERTY_QUAYACCOUNTNUMBER, t("error.length", { property: t(PROPERTY_QUAYACCOUNTNUMBER), 'min': 1, 'max': 7 })));
  }
};

export const containerValidationService = new ContainerValidationService();
