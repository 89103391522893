import { useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import czFlag from '../assets/images/flags/cz.png';
import deFlag from '../assets/images/flags/de.png';
import dkFlag from '../assets/images/flags/dk.png';
import gbFlag from '../assets/images/flags/gb.png';
import huFlag from '../assets/images/flags/hu.png';
import plFlag from '../assets/images/flags/pl.png';
import roFlag from '../assets/images/flags/ro.png';
import ruFlag from '../assets/images/flags/ru.png';
import trFlag from '../assets/images/flags/tr.png';
import { useClientMessages } from '../contexts/clientmessages.context';
import { useErrorStatus } from '../contexts/errorstatus.context';
import { useSession } from '../contexts/session.context';

class Flag {
    code: string;
    name: string;
    src: string;
    constructor(code: string, name: string, src: string) {
        this.code = code;
        this.name = name;
        this.src = src;
    }
}

const flags = [
    new Flag("de", "Deutsch", deFlag),
    new Flag("en", "English", gbFlag),
    new Flag("dk", "Dansk", dkFlag),
    new Flag("pl", "Polski", plFlag),
    new Flag("cz", "Čeština", czFlag),
    new Flag("ru", "Pусский язык", ruFlag),
    new Flag("hu", "Magyar nyelv", huFlag),
    new Flag("ro", "Română", roFlag),
    new Flag("tr", "Türkçe", trFlag),
];

export const Language = () => {
    const { setLocale } = useSession();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { setErrorStatus } = useErrorStatus();
    const { resetHiddenStatus } = useClientMessages();

    const resetHiddenStatusRef = useRef(resetHiddenStatus);

    useEffect(() => { 
        setErrorStatus(undefined); 
        resetHiddenStatusRef.current(); 
    }, [setErrorStatus]);

    return (
        <div className='content'>
            <div className='title'>
                {t('LanguageView')}
            </div>
            <div className="language-flags">
                {flags.map(flag => (
                    <div key={flag.code}
                        data-testid="flag"
                        className="language-flag"
                        onClick={() => {
                            setLocale(flag.code);
                            navigate("/login");
                        }}>
                        <img src={flag.src} alt={flag.code} />
                        <div>{flag.name}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};