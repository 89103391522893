import { faClock, faAnglesRight, faCircleCheck, faCirclePlay, faCircleXmark, faHourglass, faTag, faXmark, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AxiosResponse } from "axios";
import { formatInTimeZone } from 'date-fns-tz';
import { t } from 'i18next';
import moment from "moment";
import { Button } from "primereact/button";
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';
import { RefObject, useEffect, useRef, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useBooking } from '../contexts/booking.context';
import { useSession } from "../contexts/session.context";
import { useStammdaten } from '../contexts/stammdaten.context';
import { useSystemparameter } from '../contexts/systemparameter.context';
import { KommunikationStatusInfo } from '../dto/KommunikationStatusInfo';
import { OverviewContainerDTO } from '../dto/OverviewContainer.DTO';
import { OverviewTruckDTO } from "../dto/OverviewTruckDTO";
import { TourDTO } from '../dto/TourDTO';
import { TourenartEnum } from '../dto/TourenartEnum';
import { ViewNameEnum } from "../dto/ViewNameEnum";
import { tourService } from "../services/tour.service";
import { SLOT_TIMEZONE } from '../utils/dateutil';
import { StringUtils } from '../utils/stringUtils';
import { showCustomToast } from "./subComponents/toast.component";
import { isSameDay, isSameMinute } from 'date-fns';
import { faRight } from '@fortawesome/pro-solid-svg-icons';

const STATUS_ABFERTIGUNGSMERKMAL_PREFIX = "StatusAbfertigungsmerkmal.";
const TOOLTIP_SUFFIX = ".tooltip";
const RELOAD_INTERVAL_SEC = 30;

export const TourenplanShowView = () => {
  const navigate = useNavigate();
  const [touren, setTouren] = useState<OverviewTruckDTO[]>();
  const [loadTrigger, setLoadTrigger] = useState<Date>();
  const [isLoading, setLoading] = useState(false);
  const { setTour } = useBooking();
  const { terminals } = useStammdaten();

  const { session } = useSession();
  const tourRef = useRef(touren);
  const { systemparameter } = useSystemparameter();
  const timer = useRef<NodeJS.Timeout | null>(null); // we can save timer in useRef and pass it to child

  const truckerCardId = session?.supplements?.truckerCardId;
  const truckerId = session?.supplements?.truckerId;

  useEffect(() => {
    if (truckerCardId || truckerId) {
      setLoading(true);
      tourService.findActiveTouren({ truckerCardId, truckerId })
        .then((response: AxiosResponse<OverviewTruckDTO[]>) => {
          if (response?.data) {
            checkTourenaenderungen(toast, tourRef.current, response.data);
            tourRef.current = response.data;
            setTouren(response.data);
          }
        })
        .catch(e => console.log("Error loading tour list: " + e))
        .finally(() => setLoading(false));
    }
  }, [truckerCardId, truckerId, loadTrigger, setLoading]);

  const resetTimeout = (tim: any) => {
    tim && clearTimeout(tim);
  };

  const hasKommunkation = () => (
    touren && touren?.filter(t => t.stornoAllowed === false).length > 0 // Implizite Abfrage, stornoAllowed = true, wenn RATKommunikationsstatus = sendend
  );

  const isRebookAllowed = (tour: OverviewTruckDTO) => {
    const term = terminals?.find(t => t.codeForView === tour.terminalCode);
    return tour.ratSammelstatusSeverity !== 1 && term?.umbucher && tour.terminBestaetigt && tour.stornoAllowed;
  }

  useEffect(() => {
    resetTimeout(timer.current);

    timer.current = setTimeout(() => {
      setLoadTrigger(new Date());
    }, (systemparameter?.updateInterval || RELOAD_INTERVAL_SEC) * 1000);

    return () => resetTimeout(timer.current);
  }, [systemparameter, loadTrigger]);

  const toast = useRef<Toast>(null);
  const [showOverlay, setShowOverlay] = useState<boolean>();
  const showCancelTourWarning = (tour: OverviewTruckDTO) => {
    setShowOverlay(true);
    showCustomToast(
      toast,
      t('storno'),
      t('bestaetigungsdialoStorno'),
      t('action.ok'),
      t('cancel'),
      () => { deleteTour(tour.transportvormeldungOid, tour.tourenplanOid).then(() => { setLoadTrigger(new Date()); }); }
    );
  };

  const getContainerStatus = (ctr: OverviewContainerDTO) => (ctr.komStatus.code === KommunikationStatusInfo.NegativQuittiert ? ctr.komStatus : ctr.ctrStatus);

  const getFreiBuchNr = (ctr: OverviewContainerDTO) => (
    [TourenartEnum.LEER_DEPOT_AUSGANG, TourenartEnum.AUSLIEFERUNG].includes(ctr.tourenart)
      ? [t('freistellreferenzKurz'), getNoIfAllowed(ctr.abfertigungsmerkmale, ["6601", "6602", "6603", "6604", "6605"], ctr.freistellungsnummer)]
      : [TourenartEnum.LEER_DEPOT_EINGANG, TourenartEnum.ANLIEFERUNG].includes(ctr.tourenart)
        ? [  ctr.buchungsnummer ? t('buchungsnummerKurz') : t('freistellreferenzKurz'), getNoIfAllowed(ctr.abfertigungsmerkmale, ["6616", "6617", "6618", "6619"], ctr.buchungsnummer || ctr.freistellungsnummer)]
        : []);

  const getNoIfAllowed = (abfMerkmale?: string[], whitelist?: string[], value?: string) => {
    if (abfMerkmale && abfMerkmale?.filter(m => whitelist?.find(w => w === m)).length > 0) {
      return value;
    }
    return value ? "*****" : undefined;
  }

  const rebook = (tour: OverviewTruckDTO) => {
    const newTour = new TourDTO();
    newTour.tourenplanOid = tour.tourenplanOid;

    const term = terminals?.find(t => t.codeForView === tour.terminalCode);
    if (term) {
      newTour.terminal = term;
      setTour(newTour);
      navigate(ViewNameEnum.TIME);
    } else {
      console.log("Missing terminal", tour.terminalCode);
    }
  }

  const terminString = (termin?: Date, terminBestaetigt?: Date) => {
    const timeSuffix = "h";

    if (!termin) {
      return '';
    }

    termin = new Date(termin);

    if (!terminBestaetigt) {
      terminBestaetigt = termin;
    }

    terminBestaetigt = new Date(terminBestaetigt);
   
    if (isSameMinute(termin.getTime(), terminBestaetigt.getTime())) {
      return <>{formatInTimeZone(terminBestaetigt, SLOT_TIMEZONE, 'dd.MM.yyyy HH') + timeSuffix}</>;
    }

    if (isSameDay(termin.getTime(), terminBestaetigt.getTime())) {
      return <>{formatInTimeZone(terminBestaetigt, SLOT_TIMEZONE, 'dd.MM.yyyy HH') + timeSuffix} <FontAwesomeIcon icon={faRight} /> {formatInTimeZone(termin, SLOT_TIMEZONE, 'HH') + timeSuffix} </>;
    }

    return <>{formatInTimeZone(terminBestaetigt, SLOT_TIMEZONE, 'dd.MM.yyyy HH') + timeSuffix} <FontAwesomeIcon icon={faRight} /> {formatInTimeZone(termin, SLOT_TIMEZONE, 'dd.MM.yyyy HH') + timeSuffix} </>;
  }

  const leereTourenliste = touren && touren.length === 0;
  return leereTourenliste
    ? <Navigate to={ViewNameEnum.TERMINALS} replace={true} />
    : <>
      <Toast ref={toast} position="bottom-right" onHide={() => setShowOverlay(false)} />
      {showOverlay && <div className="overlay" />}
      <div className="title">{t(hasKommunkation() ? 'pleaseWait' : 'TourenplanShowView')}</div>
      <div className="content">
        {touren?.map((tour, index) => (
          < div className="terminal-session" key={index} >
            <div className="terminal-row" data-testid="terminal-row">
              {column(t('terminal') + ":", tour.terminalCode)}
              {column(t('tpStatus') + ":", getRatIcon(tour))}
              {column(t('tpReferenz') + ":", StringUtils.defaultIfBlank(tour.tourenplanReferenz, "---"), true)}
              {column(t('slotzeit') + ":", terminString(tour.termin, tour.terminBestaetigt), true)}
              {isRebookAllowed(tour)
                && column(<button className="rebook-btn-fa" onClick={() => rebook(tour)}> {t('umbuchen')}  <FontAwesomeIcon icon={faClock} /> </button>, undefined)
              }
              {tour.stornoAllowed
                && <button data-testid="close-btn" className="close-btn-fa" onClick={() => showCancelTourWarning(tour)} title={t('storno')} ><FontAwesomeIcon icon={faXmark} /></button>
              }
            </div>
            <div className="container-session">
              {tour.containerExtracts?.map((container, index2) => {
                const freiBuchNr = getFreiBuchNr(container);
                return <div className="container-row" key={index2} data-testid="container-row">
                  {column(t('container') + ":", getContainerInfo(container))}
                  {column(t('ctrStatus') + ":", getIcon(getContainerStatus(container)?.severity))}
                  {column(t('tourenart') + ":", t(container.tourenart))}
                  {column(t('containerIsoCode') + ":", container.isocode)}
                  {column(freiBuchNr[1] && freiBuchNr[0] + ":", freiBuchNr[1], false, 'code')}
                  {remarks(container)}
                </div>
              })}
            </div>
          </div>
        ))}
        <div className='nav-back-next-btns floating-btn'>
          <Button loading={isLoading} label={t('action.refresh')} className="secondary-btn" onClick={() => setLoadTrigger(new Date())} />
          <Button label={t('new')} className="primary-btn" onClick={() => navigate(ViewNameEnum.TERMINALS)} autoFocus><FontAwesomeIcon icon={faAnglesRight} /></Button>
        </div>
      </div>
    </>;
};

const column = (title?: any, value?: any, isValueBold: boolean = false, valueClassName?: string) => {
  return (
    title && <div className="column">
      <div className="column-title">{title}</div>
      <div className={classNames("column-value", valueClassName)}>{isValueBold ? <b>{value}</b> : value}</div>
    </div>
  );
};

const deleteTour = (transportvormeldungOid: string, tourenplanOid: string) => {
  return tourService.storniereTour({ transportvormeldungOid, tourenplanOid });
};

// UNIKAT-6162
const getRatIcon = (tour: OverviewTruckDTO) => {

  if( tour.terminBestaetigt && tour.ratSammelstatusSeverity === 3 && !isSameMinute(tour.termin.getTime(), tour.terminBestaetigt.getTime())) {
      return <div className="status-icon"><FontAwesomeIcon icon={faCircleCheck} style={{ color: '#fff', backgroundColor: '#bdbc30' }} /></div>;
  }

  return getIcon(tour.ratSammelstatusSeverity);
}

const getIcon = (severity: number) => {
  let iconDefinition: IconDefinition;
  let bgColor: string;
  switch (severity) {
    case 0: { iconDefinition = faCircleCheck; bgColor = "#3d994f"; break; }
    case 1: { iconDefinition = faHourglass; bgColor = "#003476"; break; }
    case 2: { iconDefinition = faCirclePlay; bgColor = "#bdbc30"; break; }
    case 3: { iconDefinition = faCircleXmark; bgColor = "#af2a2a"; break; }
    default: return;
  }
  return <div className="status-icon"><FontAwesomeIcon icon={iconDefinition} style={{ color: '#fff', backgroundColor: bgColor }} /></div>;
};

const remarks = (container: OverviewContainerDTO) => {
  let abfertigungsmerkmaleTooltip = container.abfertigungsmerkmale.map(merkmale => {
    return t(STATUS_ABFERTIGUNGSMERKMAL_PREFIX + merkmale + TOOLTIP_SUFFIX);
  });

  let statuses = abfertigungsmerkmaleTooltip.concat(container.quittungsdatenTextTV, container.quittungsdatenTextTP);
  return (statuses.length !== 0 &&
    <div className='marks'>
      <div className='mark-title'>{t('marks') + ':'}</div>
      <div className='mark-values'>
        {statuses.map((status, index) => {
          return <div className='mark-value' key={index}><FontAwesomeIcon icon={faTag} /><p>{status}</p></div>;
        })}
      </div>
    </div>
  );
};

const getContainerInfo = (container: OverviewContainerDTO) => {
  let info: string = StringUtils.defaultIfBlank(container.containernummer, "---");
  if (!StringUtils.isEmpty(container.containerLaenge) || !StringUtils.isEmpty(container.isocode)) {
    info += " (" + (!StringUtils.isEmpty(container.containerLaenge) ? (container.containerLaenge + "'") : container.isocode) + ")";
  }
  return info;
};

const PositivePop = (props: { tour: OverviewTruckDTO; }) => {
  return <>
    <span style={{ fontSize: "1.5em", paddingRight: '0.5rem' }}>
      {t("tpReferenz")}
    </span>
    <span style={{ fontSize: "1.5em", fontWeight: 'bold' }}>{props.tour.tourenplanReferenz}</span>
    <br />
    <span>{t('popPositiveText')}</span></>;
};

// Vergleich der vorherigen Tourenliste mit der aktuellen. Wenn gleichlang und sich ratSammelstatusSeverity geändert hat,
// Ausgabe von Toast bzgl. positiver oder negativer Rückmeldung.
const checkTourenaenderungen = (toast: RefObject<Toast>, tourenOld?: OverviewTruckDTO[], tourenNew?: OverviewTruckDTO[]) => {
  if (!tourenOld || tourenOld?.length !== tourenNew?.length) {
    return;
  }

  const diffs = tourenOld?.filter(oldTp => {
    const newTp = tourenNew?.find(t => t.tourenplanOid === oldTp.tourenplanOid);
    return (oldTp.containerExtracts?.length !== newTp?.containerExtracts?.length) || !moment(oldTp.termin).isSame(newTp.termin);
  });

  if (diffs.length > 0) {
    return;
  }

  const changedList = tourenNew?.filter((newTp) => {
    const oldTp = tourenOld.find(t => t.tourenplanOid === newTp.tourenplanOid);
    return oldTp?.ratSammelstatusSeverity !== newTp?.ratSammelstatusSeverity;
  })
    .filter(newTp => newTp.ratSammelstatusSeverity === 0 || newTp.ratSammelstatusSeverity === 3)
    .map((newTp) => {
      let type = undefined;
      switch (newTp?.ratSammelstatusSeverity) {
        case 0: type = "POS"; break;
        case 3: type = "NEG"; break;
        default: type = undefined;
      }
      return { type: type, tour: newTp };
    });

  changedList.forEach(entry =>
    entry.type === 'POS' ? toast.current?.show({ severity: 'info', summary: entry.tour.terminalCode, detail: <PositivePop tour={entry.tour} />, sticky: true })
      : toast.current?.show({ severity: 'warn', summary: entry.tour.terminalCode, detail: t('popNegativeText'), sticky: true })
  );
};
