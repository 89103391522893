import { Dropdown, DropdownProps } from "primereact/dropdown";

export const TGDropdown = (props: DropdownProps) => {

    const resetOffset = () => {
        var element = document.getElementsByClassName('p-dropdown-items p-virtualscroller-content').item(0) as HTMLElement;
        if (element) { element.style.transform = "translate3d(0px, 0px, 0px)"; }
    };

    return <>
        <Dropdown
            {...props}
            data-testid="dropdown"
            showClear
            filter
            virtualScrollerOptions={{ itemSize: 40 }}
            resetFilterOnHide
            onFilter={(e) => { resetOffset(); props.onFilter && props.onFilter(e); }}
        />
    </>;

};