import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import imgAusliferung from '../assets/images/tourenarten/export_128x76.png';
import imgAnliferung from '../assets/images/tourenarten/import_128x76.png';
import imgLeerAusgang from '../assets/images/tourenarten/leerAusgang_128x76.png';
import imgLeerDeopt from '../assets/images/tourenarten/leercontainerDepot_128x128.png';
import imgLeerEingang from '../assets/images/tourenarten/leerEingang_128x76.png';
import imgTerminal from '../assets/images/tourenarten/terminalKaibetrieb_128x128.png';
import { useBooking } from "../contexts/booking.context";
import { ContainerDTO } from "../dto/ContainerDTO";
import { ContainerzustandEnum } from "../dto/ContainerzustandEnum";
import { TourenartEnum } from "../dto/TourenartEnum";
import { ViewNameEnum } from "../dto/ViewNameEnum";
import { NavigateBackNextView } from "./subComponents/navigatebacknext.component";
import { SelectionHeaderView } from "./selectionheader.component";
import { TourenartImage } from "./subComponents/TourenartImage.component";
import { KennzeichenVewendungEnum } from "../dto/KennzeichenVewendungEnum";

type Props = {};

type MenuListEntry = {
  art: TourenartEnum;
  nextView: string;
  imgSrc: string;
  verwendung: KennzeichenVewendungEnum
};

export const TourenartView = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { tour, container, setContainer } = useBooking();

  useEffect(() => {
    // Nach Rückwechsel wieder zurücksetzen
    container && setContainer(undefined);
  }, [container, setContainer]);

  const nextView = useCallback(() => {
    return tour?.containerList?.length ? ViewNameEnum.CTRSHOW : undefined;
  }, [tour]);

  const setTourenart = (menuEntry: MenuListEntry) => {
    const newContainer = new ContainerDTO();
    newContainer.tourenart = menuEntry.art;
    if (menuEntry.art === TourenartEnum.LEER_DEPOT_AUSGANG || menuEntry.art === TourenartEnum.LEER_DEPOT_EINGANG) {
      newContainer.containerzustand = ContainerzustandEnum.LEER;
    }
    setContainer(newContainer);
    navigate(menuEntry.nextView);
  };

  const menuListVoll = [
    { art: TourenartEnum.ANLIEFERUNG, nextView: ViewNameEnum.CTRNR, imgSrc: imgAnliferung, verwendung: KennzeichenVewendungEnum.EX },
    { art: TourenartEnum.AUSLIEFERUNG, nextView: ViewNameEnum.CTRFREISTELLNR, imgSrc: imgAusliferung, verwendung: KennzeichenVewendungEnum.IM }
  ] as MenuListEntry[];

  const menuListLeer = [
    { art: TourenartEnum.LEER_DEPOT_EINGANG, nextView: ViewNameEnum.CTRNR, imgSrc: imgLeerEingang, verwendung: KennzeichenVewendungEnum.DE },
    { art: TourenartEnum.LEER_DEPOT_AUSGANG, nextView: ViewNameEnum.CTRFREISTELLNR, imgSrc: imgLeerAusgang, verwendung: KennzeichenVewendungEnum.DA }
  ] as MenuListEntry[];

  const TourenartButton = (m: MenuListEntry, autoFocus: boolean) => {
    return (
      <button key={m.art} onClick={() => setTourenart(m)} autoFocus={autoFocus} >
        <span>
          <TourenartImage srcName={m.art} alt={t(m.art.toString() as any)} />
        </span>
      </button>
    );
  };

  const isVisible = (verwendungAktuell: KennzeichenVewendungEnum, verwendungen?: KennzeichenVewendungEnum[]) => (!verwendungen || verwendungen.length === 0 || verwendungen.includes(verwendungAktuell));

  const VerwendungButtonList = (props: { entries: MenuListEntry[], imageName: string, alt: string }) => {
    const entriesFiltered = props.entries.filter(m => isVisible(m.verwendung, tour?.terminal.verwendungen));
    return entriesFiltered.length > 0 ? <div className="ButtonGroup">
      <span className="ButtonGroup-img">
        <img src={props.imageName} alt={props.alt} />
      </span>
      {entriesFiltered.map((m, idx) => TourenartButton(m, idx === 0))}
    </div> : <></>
  }

  const Tourenarten = () => {
    return (
      <div className="ButtonGroupWrapper content">
        <VerwendungButtonList entries={menuListVoll} imageName={imgTerminal} alt="Terminal" />
        <VerwendungButtonList entries={menuListLeer} imageName={imgLeerDeopt} alt="Leerdepot" />
      </div>
    );
  };

  return <div>
    <SelectionHeaderView />
    <div className="title">{t("TourenartView" as any)}</div>
    <div className="ButtonGroup">
      <Tourenarten />
    </div>
    <NavigateBackNextView nextView={nextView} />
  </div>;
};