import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useBooking } from "../contexts/booking.context";
import { ContainerDTO } from "../dto/ContainerDTO";
import { ValidationErrorDTO } from "../dto/ValidationErrorDTO";
import { ViewNameEnum } from "../dto/ViewNameEnum";
import { containerValidationService, PROPERTY_TURNINREFERENZ } from "../services/container.validation.service";
import { SelectionHeaderView } from "./selectionheader.component";
import { FormView } from "./subComponents/formview.component";
import { NavigateBackNextView } from "./subComponents/navigatebacknext.component";
import { ValidationInfo } from "./subComponents/validationinfo.component";

export const CtrTurninReferenceView = () => {
  const validate = (ctr: ContainerDTO) => (containerValidationService.validate(ctr, (a) => a === PROPERTY_TURNINREFERENZ));
  const { container, setContainer } = useBooking();
  const [validationErrors, setValidationErrors] = useState<ValidationErrorDTO[]>(container ? validate(container) : []);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const nextView = () => (
    ViewNameEnum.CTRSHOW
  );

  const validateAndUpdate = (newContainer: ContainerDTO) => {
    setValidationErrors(validate(newContainer));
    setContainer(newContainer);
    return true;
  };

  const setTurninRef = (ref?: string) => {
    if (container) {
      setContainer({ ...container, turninreferenz: ref });
      navigate(nextView());
    }
  };

  return <div>
    <SelectionHeaderView />
    <div className="title">{t('turninreferenz')}</div>

    <div className="content">
      <FormView nextView={nextView} nextEnabled={validationErrors.length === 0}>
        <div className='formbox-wrapper'>
          <div className='formbox'>
            <div className='formbox-field'>
              <label htmlFor="turninref">{t('turninreferenz')}</label>
              <InputText
                data-testid="input"
                id="turninref"
                value={container?.turninreferenz || ''}
                className={classNames({ 'p-invalid': validationErrors.find(e => e.property === PROPERTY_TURNINREFERENZ) })}
                onChange={(e) => container && validateAndUpdate({ ...container, turninreferenz: e.target.value })}
                maxLength={35}
                autoFocus />
            </div>
            <ValidationInfo property={PROPERTY_TURNINREFERENZ} validationErrors={validationErrors} />
            <div className='formbox-field'>
              <Button onClick={() => setTurninRef()} disabled={!!container?.turninreferenz}>{t('weiterOhneTurnInReference')}</Button>
            </div>
          </div>
        </div>
      </FormView>
    </div>
    <NavigateBackNextView nextView={nextView} nextDisabled={validationErrors.length !== 0} />
  </div>;
};