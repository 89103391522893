import { AxiosResponse } from "axios";
import { SlotAuslastungsgradEnum } from "../dto/SlotAuslastungsgradEnum";
import { apiPath } from "../utils/httpConfig";
import { httpService, makeURLCall } from "../utils/httpUtil";

export type SlotauslastungFilter = {
  termin: string;
  terminalCode: string;
  kategorie: string;
};

export type SlotauslastungService = {
  getSlotauslastungPerDay: (filter: SlotauslastungFilter) => Promise<AxiosResponse<SlotAuslastungsgradEnum[], any>>;
};

class SlotauslastungLocal implements SlotauslastungService {
  getSlotauslastungPerDay(filter: SlotauslastungFilter) {
    return httpService
      .getAxios()
      .then((ai) =>
        ai.get<SlotAuslastungsgradEnum[]>(makeURLCall(apiPath("/slotauslastungen"), filter))
      );
  }
}

export const slotauslastungService = new SlotauslastungLocal() as SlotauslastungService;
