import { classNames } from "primereact/utils";
import { SlotAuslastungsgradEnum } from "../../dto/SlotAuslastungsgradEnum";

type Props = { state: SlotAuslastungsgradEnum, disabled: boolean; };

export const Ampel: React.FC<Props> = ({ state, disabled }) => {

    let ampelTop = "ampel-disabeld";
    let ampelMid = "ampel-disabeld";
    let ampelBot = "ampel-disabeld";

    let bg = "ampel-background";

    switch (state) {
        case SlotAuslastungsgradEnum.OK:
            ampelBot = "ampel-ok";
            ampelTop = "ampel-gesperrt";
            ampelMid = "ampel-gesperrt";
            break;
        case SlotAuslastungsgradEnum.KRITISCH:
            ampelMid = "ampel-kritisch";
            ampelTop = "ampel-gesperrt";
            ampelBot = "ampel-gesperrt";
            break;
        case SlotAuslastungsgradEnum.VOLL:
            ampelTop = "ampel-voll";
            ampelMid = "ampel-gesperrt";
            ampelBot = "ampel-gesperrt";
            break;
        case SlotAuslastungsgradEnum.GESPERRT:
            ampelTop = "ampel-gesperrt";
            ampelMid = "ampel-gesperrt";
            ampelBot = "ampel-gesperrt";
            break;
    }

    if (disabled) {
        bg = "ampel-background-disabeld";
    }

    return (<>
        <svg className="ampel" id="Ampel" data-name="Ampel" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128.56 355.33">
            <rect className={bg} x="0.5" y="0.5" width="127.56" height="354.33" rx="15" />
            <circle className={classNames(ampelTop, { 'ampel-dimmed': disabled })} cx="64.28" cy="64.28" r="40" />
            <circle className={classNames(ampelMid, { 'ampel-dimmed': disabled })} cx="64.28" cy="177.67" r="40" />
            <circle className={classNames(ampelBot, { 'ampel-dimmed': disabled })} cx="64.28" cy="291.05" r="40" />
        </svg>
    </>);
};