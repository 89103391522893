import { AxiosError } from 'axios';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../assets/images/truckgate_logo_374x51.png';
import { useBooking } from '../contexts/booking.context';
import { useSession } from '../contexts/session.context';
import { ViewNameEnum } from '../dto/ViewNameEnum';
import { helpService } from '../services/help.service';
import { Loader } from './loader.component';
import { ChangeUserButton } from './subComponents/changeuser.component';
import { CustomDialog } from './subComponents/dialog.component';
import { showCustomToast } from './subComponents/toast.component';
import { UserInfo } from './subComponents/userinfo.component';
import { VersionInfo } from './subComponents/versioninfo.component';

type Props = {};

export const Header = (props: Props) => {
    const navigate = useNavigate();
    const { session } = useSession();
    const { t } = useTranslation();
    const { tour, setTour, setContainer } = useBooking();
    const [showDialog, setShowDialog] = useState(false);

    const navigateLogout = () => (navigate(ViewNameEnum.IDPLOGOUT));

    // cancel tour
    const [showOverlay, setShowOverlay] = useState<boolean>();
    const toast = useRef<Toast>(null);
    const showCancelTourWarning = (isLogout?: boolean) => {
        setShowOverlay(true);
        showCustomToast(
            toast,
            t('cancel'),
            t('confirmToDiscardChanges'),
            t('action.ok'),
            t('cancel'),
            () => { setTour(undefined); setContainer(undefined); navigate(ViewNameEnum.ROOT); isLogout && navigateLogout(); }
        );
    };

    // logout
    const handleLogout = () => {
        tour ? showCancelTourWarning(true) : navigateLogout();
    };

    // help dialog
    const [helpHtml, setHelpHtml] = useState('');
    const location = useLocation();
    const loadHelpContent = () => {
        helpService
            .getHelpHtml(location.pathname)
            .then(response => {
                setHelpHtml(response.data);
                setShowDialog(true);
            }).catch((e: AxiosError) => {
                toast.current?.show({ severity: 'error', summary: t('fehler'), detail: t(`server.http.${e.response?.status}` as any, t('server.error', { status: e.response?.status })), life: 3000 });
            });
    };

    return (
        <>
            <Toast ref={toast} position="bottom-right" onHide={() => setShowOverlay(false)} />
            {showOverlay && <div className="overlay" />}
            <div className='header'>
                <img className={classNames('logo', { 'logo-clickable': !tour})} src={logo} alt="Logo" onClick={() => !tour && navigate(ViewNameEnum.ROOT, { replace: true })} />
                <Loader />
                <CustomDialog header={t('help')} content={helpHtml} visible={showDialog} onHide={() => setShowDialog(false)} />
                <div>
                    <div className='version-info-container'>
                        <UserInfo />
                        <VersionInfo />
                    </div>
                    <div className='header-buttons'>
                        <ChangeUserButton />
                        {session && tour && <Button icon="pi pi-times" onClick={() => { showCancelTourWarning(); }} label={t('action.abort')} />}
                        {session && <Button icon="pi pi-sign-out" onClick={handleLogout} label={t('action.logout')} />}
                        <Button icon="pi pi-question-circle" onClick={() => { loadHelpContent(); }} label={t('help')} />
                    </div>
                </div>
            </div>
        </>
    );
};
