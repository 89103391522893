import { ReactNode } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useBooking } from "../contexts/booking.context";
import { useSession } from "../contexts/session.context";
import { ViewNameEnum } from "../dto/ViewNameEnum";

export const InSession = (props: { children: ReactNode; }) => {
    const { session } = useSession();
    return <>{session ? props.children : null}</>;
};

export const PrivateOutlet = () => {
    const { session } = useSession();
    return session ? <Outlet /> : <Navigate to={ViewNameEnum.LOGIN} />;
};

export const LocaleOutlet = () => {
    const { locale } = useSession();
    return locale ? <Outlet /> : <Navigate to={ViewNameEnum.LANGUAGE} />;
};

export const EditTourOutlet = () => {
    const { tour } = useBooking();
    return tour?.terminal ? <Outlet /> : <Navigate to={ViewNameEnum.ROOT} replace={true} />;
};

export const EditContainerOutlet = () => {
    const { container } = useBooking();
    return container ? <Outlet /> : <Navigate to={ViewNameEnum.TOURENART} replace={true} />;
};

export const BookReadyOutlet = () => {
    const { tour } = useBooking();    
    return tour?.termin ? <Outlet /> : <Navigate to={ViewNameEnum.TOURENART} replace={true} />;
};

export const ContainerSizeOutlet = () => {
    const { container } = useBooking();
    return !container?.containerIsoCode ? <Outlet /> : <Navigate to={ViewNameEnum.CTRISOCODE} replace={true} />;
};