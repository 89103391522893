import { UserInfo } from "./subComponents/userinfo.component";
import { VersionInfo } from "./subComponents/versioninfo.component";

export const Footer = () => {
    return (
        <div className='version-info-container-mobile'>
            <div style={{ display: 'inline-flex' }} >
            <VersionInfo />
            <div style={{paddingRight: '0.2em', paddingLeft: '0.2em' }} className='version-info'> - </div>
            <UserInfo/>
            </div>
        </div>
    );
};