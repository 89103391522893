import { faXmarkLarge } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateCheck } from 'react-update-notification';
import { UpdateStatus } from 'react-update-notification/lib/types';

interface Props {
  children: JSX.Element;
  autoUpdateSec?: number;
}

/**
 * Periodisches Prüfen von version.json gegen lokale Version. Wenn eine andere Version auf dem Server gefunden wurde, Rendering einer Statuszeile
 * und automatisches Refresh nach einem Timeout.
 */
export const AutoUpdater = (props: Props) => {
  const timer = useRef<NodeJS.Timeout | null>(null); // we can save timer in useRef and pass it to child
  const [leftSeconds, setLeftSeconds] = useState(props.autoUpdateSec || 30);
  const [aborted, setAborted] = useState(false);

  const { t } = useTranslation();

  const autoTimerEnabled = props.autoUpdateSec && props.autoUpdateSec > 0;

  const { status, reloadPage } = useUpdateCheck({
    type: 'interval',
    interval: 600000,
    ignoreServerCache: true
  });

  const resetTimeout = (tim: any) => {
    tim && clearTimeout(tim);
  };

  const newVersionAvailable = status === UpdateStatus.available;

  useEffect(() => {
    resetTimeout(timer.current);
    if (newVersionAvailable && autoTimerEnabled) {

      timer.current = timer.current = setTimeout(() => {
        setLeftSeconds(leftSeconds - 1);
        if (leftSeconds === 1) {
          reloadPage();
        }
      }, 1000);
    }

    return () => resetTimeout(timer.current);
  }, [newVersionAvailable, reloadPage, leftSeconds, autoTimerEnabled]);

  if (!newVersionAvailable || aborted) {
    return props.children;
  }

  return (
    <>
      <div className="autoupdater">
        <div className="autoupdater-main">
          <div className="autoupdater-main-message">{t(autoTimerEnabled ? 'newversion.timeout' : 'newversion', { sec: leftSeconds })}</div>
          <div style={{ display: 'flex' }}>
            <div className="autoupdater-button" onClick={reloadPage}>{t('action.refresh')}</div>
            <div onClick={() => {
              setAborted(true);
            }} style={{ marginLeft: '1rem', alignSelf: 'center' }} ><FontAwesomeIcon icon={faXmarkLarge} /></div>
          </div>
        </div>
      </div>
      {props.children}
    </>
  );
};