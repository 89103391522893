import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useBooking } from "../contexts/booking.context";
import { ContainerDTO } from "../dto/ContainerDTO";
import { ValidationErrorDTO } from "../dto/ValidationErrorDTO";
import { ViewNameEnum } from "../dto/ViewNameEnum";
import { containerValidationService, PROPERTY_BUCHUNGSNUMMER } from "../services/container.validation.service";
import { SelectionHeaderView } from "./selectionheader.component";
import { FormView } from "./subComponents/formview.component";
import { NavigateBackNextView } from "./subComponents/navigatebacknext.component";
import { ValidationInfo } from "./subComponents/validationinfo.component";

export const CtrBuchungsNrView = () => {
  const validate = (ctr: ContainerDTO) => (containerValidationService.validate(ctr, (a) => a === PROPERTY_BUCHUNGSNUMMER));

  const { container, setContainer } = useBooking();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [validationErrors, setValidationErrors] = useState<ValidationErrorDTO[]>(container ? validate(container) : []);

  const nextView = (ctr = container) => {
    if( ctr?.klv) { return ViewNameEnum.CTRLOESCHHAFEN; }
    return ctr?.buchungsnummer ? ViewNameEnum.CTRSHOW : ViewNameEnum.CTRSHIPNR;
  };

  const validateAndUpdate = (newContainer: ContainerDTO) => {
    setValidationErrors(validate(newContainer));
    setContainer(newContainer);
    return true;
  };

  useEffect(() => {
    if(container?.buchungsnummer && (container?.carriageOnInformation || container?.loeschhafen)) {
      setContainer({...container, carriageOnInformation: undefined, loeschhafen: undefined})
    }
  }, [container, setContainer]);

  return <>
    <SelectionHeaderView />
    <div className="title">{t('buchungsnummer')}</div>
    <FormView nextView={nextView} nextEnabled={validationErrors.length === 0}>
      <div className='formbox-wrapper content'>
        <div className='formbox'>
          <div className='formbox-field'>
            <label htmlFor="buchungsnummer">{t('buchungsnummer')}</label>
            <InputText
              data-testid="input"
              id="buchungsnummer"
              value={container?.buchungsnummer || ''}
              onChange={(e) => container && validateAndUpdate({ ...container, buchungsnummer: e.target.value })}
              maxLength={22}
              autoFocus />
          </div>
          <ValidationInfo property={PROPERTY_BUCHUNGSNUMMER} validationErrors={validationErrors} />
          <div className='formbox-field'>
            <Button label={t('weiterOhneBuchungsnummer')} onClick={() => {
              if (container) {
                const ctr = { ...container, buchungsnummer: undefined };
                setContainer(ctr);
                navigate(nextView(ctr));
              }
            }
            } disabled={!!container?.buchungsnummer} />
          </div>
        </div>
      </div>
    </FormView>
    <NavigateBackNextView nextView={nextView} nextDisabled={validationErrors.length !== 0} />
  </>;
};