import { classNames } from 'primereact/utils';
import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useBooking } from "../contexts/booking.context";
import { useStammdaten } from '../contexts/stammdaten.context';
import { ContainerDTO } from '../dto/ContainerDTO';
import { StammdatenUnLoCodeDTO } from '../dto/StammdatenUnLoCodeDTO';
import { ValidationErrorDTO } from '../dto/ValidationErrorDTO';
import { ViewNameEnum } from '../dto/ViewNameEnum';
import { containerValidationService, PROPERTY_LOESCHHAFEN } from '../services/container.validation.service';
import { NavigateBackNextView } from "./subComponents/navigatebacknext.component";
import { SelectionHeaderView } from "./selectionheader.component";
import { ValidationInfo } from './subComponents/validationinfo.component';
import { TGDropdown } from './subComponents/tgdropdown.component';

export const CtrLoeschHafenView = () => {

  const validate = (ctr: ContainerDTO) => (containerValidationService.validate(ctr, (a) => a === PROPERTY_LOESCHHAFEN));

  const { container, setContainer } = useBooking();
  const { t } = useTranslation();
  const [validationErrors, setValidationErrors] = useState<ValidationErrorDTO[]>(container ? validate(container) : []);

  const { loeschhaefen, zielbahnhoefe } = useStammdaten();

  const unLoCodes = container?.klv ? zielbahnhoefe : loeschhaefen;

  const validateAndUpdate = (newContainer: ContainerDTO) => {
    setValidationErrors(validate(newContainer));
    setContainer(newContainer);
    return true;
  };

  const nextView = () => {
    return ViewNameEnum.CTRSHOW;
  };

  const makeLabel = (unLoCode?: StammdatenUnLoCodeDTO) => {
    if (unLoCode) {
      const unLoCodeNameOrNull = unLoCode.name ? " - " + unLoCode.name : '';
      return unLoCode.unlocode + unLoCodeNameOrNull;
    }
    return t('loeschhafen');
  };

  return <div>
    <SelectionHeaderView />
    <div className="title">{t('loeschhafen')}</div>

    <div className='formbox-wrapper content'>
      <div className='formbox'>
        <div className='formbox-field'>
          <TGDropdown
            optionLabel="code"
            placeholder={t('carrier')}
            value={container?.loeschhafen}
            filterBy="unlocode,name"
            options={unLoCodes}
            valueTemplate={makeLabel}
            itemTemplate={makeLabel}
            onChange={(e) => container && validateAndUpdate({ ...container, loeschhafen: e.target.value })}
            className={classNames({ 'p-invalid': validationErrors.find(e => e.property === PROPERTY_LOESCHHAFEN) })}
            autoFocus
          />
        </div>
        <ValidationInfo property={PROPERTY_LOESCHHAFEN} validationErrors={validationErrors} />
      </div>
    </div>
    <NavigateBackNextView nextView={nextView} nextDisabled={!container?.loeschhafen} />
  </div>;
};