import { AxiosError } from "axios";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import truckerCard from "../assets/images/Truckercard-kreis.png";
import { ReactComponent as ReactLogo } from '../assets/images/impala-logo.svg';
import { useErrorStatus } from "../contexts/errorstatus.context";
import { useSession } from "../contexts/session.context";
import { ErrorResponseDTO } from "../dto/ErrorResponseDTO";
import { LoginTypeEnum } from "../dto/LoginTypeEnum";
import { sessionService } from "../services/session.service";
import { HttpStatusCode } from "../utils/httpStatusCode";
import { useSystemparameter } from "../contexts/systemparameter.context";
import { ViewNameEnum } from "../dto/ViewNameEnum";
import { classNames } from "primereact/utils";

const IMPALA_CODES = ["IMPT", "IMPI", "IMPP", "IPID", "IPII"];

export const Login = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const currentLocationState: any = location.state || { from: { pathname: '/' } };
  const { systemparameter } = useSystemparameter();

  const { t } = useTranslation();
  const { session, setSession, locale, setLocale } = useSession();
  const { setErrorStatus } = useErrorStatus();

  const [username, setUsername] = useState('');
  const inputUsernameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value.trim());
  };

  const [password, setPassword] = useState('');
  const inputPasswordHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value.trim());
  };

  useEffect(() => {
    if (session) {
      navigate(currentLocationState?.from);
    }
  }, [currentLocationState?.from, session, navigate]);

  const [isErrorMandatory, setErrorMandatory] = useState(false);
  const [isErrorLength, setErrorLength] = useState(false);
  const checkValidation = () => {
    setErrorMandatory(false);
    setErrorLength(false);
    let validation = true;
    if (typeof username == 'undefined' || !username) {
      setErrorMandatory(true);
      validation = false;
    }
    if (typeof password == 'undefined' || !password || password.length > 4) {
      setErrorLength(true);
      validation = false;
    }
    return validation;
  };

  const toast = useRef<Toast>(null);
  const [isLoading, setLoading] = useState(false);
  const login = (event: React.FormEvent<HTMLFormElement>) => {

    event.preventDefault();

    if (!checkValidation()) { return; }
    setLoading(true);
    toast.current?.clear();
    sessionService
      .login({ username: username, password: password, locale })
      .then((loginResponse) => {
        setLoading(false);
        setSession(loginResponse.data?.session);
      })
      .catch((error: AxiosError) => {
        setLoading(false);
        switch (error?.response?.status) {
          case HttpStatusCode.UNAUTHORIZED:
            setErrorStatus(undefined);
            const serverError = error?.response?.data as ErrorResponseDTO;
            const maintenance = serverError.cause === LoginTypeEnum.ACCESS_DENIED_FOR_EXTERNAL_IP;

            toast.current?.show({ severity: 'error', summary: t('error.unauthorized'), detail: maintenance ? t('loginFailedMaintenance') : t('loginPlease'), sticky: true });
            break;

          case HttpStatusCode.FORBIDDEN:
            setErrorStatus(undefined);
            toast.current?.show({ severity: 'error', summary: t('fehler'), detail: t('loginFailed'), sticky: true });
            break;

          default:
            toast.current?.show({ severity: 'error', summary: t('fehler'), detail: t(`server.http.${error?.response?.status}` as any, t('server.error', { status: error?.response?.status })), sticky: true });
            break;
        }
      });
  };

  return (
    <div>
      <Toast ref={toast} position="bottom-right" />
      <div className="login-background">
        <div className="login-card-view">
          <div className="trucker-card">
            <img src={truckerCard} alt="TruckerCard" />
          </div>
          <form onSubmit={login}>
            <div className="input-row">
              <div className="field">
                <label id="card-id-label" className="block">{t('truckerCardId')}</label>
                <InputText autoComplete='username' autoCapitalize='none' aria-labelledby="card-id-label" className={isErrorMandatory ? "p-invalid block" : "block"} onChange={inputUsernameHandler} autoFocus />
                {isErrorMandatory && <small id="username2-help" className="p-error block" aria-labelledby="card-id-label">{t('error.mandatory', { property: t('truckerCardId') })}</small>}
              </div>
            </div>
            <div className="input-row">
              <div className="field">
                <label id="pin-label" className="block">{t('password')}</label>
                <Password aria-labelledby="pin-label" autoComplete='current-password' className={isErrorLength ? "p-invalid block" : "block"} onChange={inputPasswordHandler} feedback={false} />
                {isErrorLength && <small className="p-error block" aria-labelledby="pin-label">{t('error.length', { property: t('password'), min: '1', max: '4' })}</small>}
              </div>
            </div>
            <div className="input-button-row">
              <Button label={t('action.login')} type="submit" className="login-button" aria-labelledby="login-form" loading={isLoading} />
            </div>
          </form>
          {systemparameter?.oidcProviders?.filter(p => p.authority).map((p, idx) =>
          <div className="input-button-row" key={idx}>
            <Button onClick={() => { navigate(ViewNameEnum.IDPLOGIN + "/" + p.code); }} className={classNames({ "impala-login-button": IMPALA_CODES.includes(p.code), "additional-login-button": !IMPALA_CODES.includes(p.code)})} >
                {IMPALA_CODES.includes(p.code) && <ReactLogo height={'24px'} width={'24px'} />}
                <span style={{width: "100%"}}>{t('loginWith', { name: p.name })}</span>
            </Button>
          </div>)}
          <div className="input-button-row">
            <div onClick={() => { setLocale(undefined); }} className="language-button">{t('language')}</div>
          </div>
        </div>
      </div>
    </div>
  );
};