import { useNavigate } from "react-router-dom";
import { ViewNameEnum } from "../../dto/ViewNameEnum";

type Props = {
    nextView?: () => ViewNameEnum | undefined;
    nextEnabled?: boolean;
    children?: React.ReactNode;
};

export const FormView = (props: Props) => {
    const navigate = useNavigate();
    const nextView = props.nextView && props.nextView();
    const onSubmit = () => { (props.nextEnabled && nextView) && navigate(nextView); };

    return (
        <form onSubmit={onSubmit}>
            {props.children}
        </form>
    );
};