import { Dialog } from 'primereact/dialog';
import parse from 'html-react-parser';

type Props = {
    header: string;
    content: string;
    visible: boolean;
    onHide: () => void;
};

export const CustomDialog = (props: Props) => {
    return (
        <Dialog className='custom-dialog' header={props.header} visible={props.visible} onHide={props.onHide}>
            {parse(props.content)}
        </Dialog >
    );
};