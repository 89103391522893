import i18n from "i18next";
import translationEn from "./en/translation.json";
import translationDe from "./de/translation.json";
import translationCz from "./cz/translation.json";
import translationDk from "./dk/translation.json";
import translationFr from "./fr/translation.json";
import translationHu from "./hu/translation.json";
import translationPl from "./pl/translation.json";
import translationRo from "./ro/translation.json";
import translationRu from "./ru/translation.json";
import translationTr from "./tr/translation.json";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { format as formatDate, isDate } from "date-fns";
import {
  enGB as en,
  de,
  enGB as cz,
  enGB as dk,
  fr,
  hu,
  pl,
  ro,
  ru,
  tr,
} from "date-fns/locale"; // import all locales we need

const dateFnslocales = { en, de, cz, dk, fr, hu, pl, ro, ru, tr };

export const resources = {
  en: {
    translation: translationEn,
  },
  de: {
    translation: translationDe,
  },
  cz: {
    translation: translationCz,
  },
  dk: {
    translation: translationDk,
  },
  fr: {
    translation: translationFr,
  },
  hu: {
    translation: translationHu,
  },
  pl: {
    translation: translationPl,
  },
  ro: {
    translation: translationRo,
  },
  ru: {
    translation: translationRu,
  },
  tr: {
    translation: translationTr,
  },
} as const;

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default

      format: (value, format, lng) => {
        if (isDate(value) && lng && format) {
          const locale = dateFnslocales[lng as keyof typeof dateFnslocales];
          return formatDate(value, format, { locale });
        }
        return value;
      },
    },
    resources,
  });
