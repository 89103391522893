import { classNames } from "primereact/utils";
import { useSession } from "../../contexts/session.context";
import { useSystemparameter } from "../../contexts/systemparameter.context";
import { TerminalDTO } from "../../dto/TerminalDTO";
import { ImageText } from "./ImageText.component";

type Props = { alt: string, src: string, disabled: boolean, onClick: () => void, terminal: TerminalDTO, autofocus?: boolean; };
export const TerminalViewSingleTerminal: React.FC<Props> = ({ alt, src, disabled, onClick, terminal, autofocus }) => {

    const { session } = useSession();
    const { systemparameter } = useSystemparameter();

    const provCode = session?.supplements?.idpCode;
    const oidcProv = systemparameter?.oidcProviders?.find(p => p.code === provCode);
    const isTerminalValid = !oidcProv || (oidcProv?.terminalsIncludedRegex && !!terminal?.code?.match(oidcProv.terminalsIncludedRegex));

    const TerminalButton = () => {
        return (
            <button onClick={() => isTerminalValid && onClick()} autoFocus={autofocus}>
                <ImageText alt={alt} src={src} />
            </button>
        );
    };

    const divClass = classNames('TerminalViewSingleTerminal', { 'TerminalViewSingleTerminal-disabled': disabled || !isTerminalValid });

    return (
        <div key={terminal.code} className={divClass}>
            <TerminalButton />
        </div>
    );
};