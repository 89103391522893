import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useErrorStatus } from "../contexts/errorstatus.context";
import { useIdp } from "../contexts/idp.context";
import { useLoadingStatusContext } from "../contexts/loadingstatus.context";
import { useSession } from "../contexts/session.context";
import { ErrorDTO } from "../dto/ErrorDTO";
import { ErrorResponseDTO } from "../dto/ErrorResponseDTO";
import { ValidationErrorDTO } from "../dto/ValidationErrorDTO";
import { sessionService } from "../services/session.service";
import { httpService } from "./httpUtil";
import {
  isIso8601DateTime,
  iso8601DateTimeReviver
} from "./reviver.util";

const MIN_TOKEN_LEFT_SEC = 60;

type Content = {
  instance?: AxiosInstance;
};
export const useAxios = (
  locale?: string,
  baseURL: string = window.location.origin
) => {
  const [axiosInstance, setAxiosInstance] = useState<Content>({});
  const { setLoadingStatus } = useLoadingStatusContext();
  const { setErrorStatus } = useErrorStatus();
  const { setSession, session, setToken } = useSession();
  const { signOutRedirect } = useIdp();
  const { t } = useTranslation();

  const handleBadRequest = useCallback(
    (status: number, responseData: any) => {
      if (responseData && Array.isArray(responseData) && responseData.length > 0) {
        const serverError = new ErrorResponseDTO();
        serverError.status = status;
        serverError.message = t('fehler');
        serverError.timestamp = new Date();
        serverError.errors = responseData.map((rd: ValidationErrorDTO) => ({ message: rd.message, field: rd.property, type: rd.type } as ErrorDTO));
        setErrorStatus(serverError);
      } else if (responseData && responseData["error"]) {
        // Spring style JSON error
        const serverError = responseData as ErrorResponseDTO;
        setErrorStatus( {...serverError, timestamp: serverError.timestamp || new Date(), message: serverError.error || serverError.message || t(`server.http.${status}` as any, t('server.error', { status })) });
      } else {
        // generic HTTP error
        const genericError = {
          message: t(`server.http.${status}` as any, t('server.error', { status })),
          status: status,
          timestamp: new Date(),
        } as ErrorResponseDTO;
        setErrorStatus(genericError);
      }
    },
    [setErrorStatus, t]
  );

  const currentSessionRef = useRef(session);

  const updateTokenaAndProceed = useCallback((config: AxiosRequestConfig<any>) => {
    return sessionService.updateToken(MIN_TOKEN_LEFT_SEC).then(({ token, session }) => {
      setToken(token || undefined);
      if (session && currentSessionRef.current?.id !== session.id) {
        setSession(session);
        currentSessionRef.current = session;
      }

      if (config?.headers) {
        if (locale) {
          config.headers["Accept-Language"] = locale;
        }
        if (token && !config.url?.endsWith("/login") && !config.url?.endsWith("/tokenlogin")) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      }
      return Promise.resolve().then(() => config);
    }).catch((err) => {
      console.warn(err);
      setSession(undefined);
      signOutRedirect();
      return config;
    });
  }, [locale, setSession, setToken, signOutRedirect]);

  useEffect(() => {
    const instance = axios.create({
      baseURL,
    });

    // Add a request interceptor
    instance.interceptors.request.use(
      function (config) {
        setLoadingStatus({ isLoading: true });
        setErrorStatus(undefined);

        if (sessionService.isRefreshRequest(config.url)) {
          return config;
        }

        return updateTokenaAndProceed(config);
      },
      function (error) {
        setLoadingStatus({ isLoading: false });
        return Promise.reject(error);
      }
    );

    instance.interceptors.response.use(
      (originalResponse) => {
        setLoadingStatus({ isLoading: false });
        handleDates(originalResponse.data);
        return originalResponse;
      },
      (error) => {
        const originalConfig = error.config;

        switch (error?.response?.status) {
          case 401:
          case 403:
            if (!originalConfig._retry && !sessionService.isRefreshRequest(originalConfig.url)) {
              originalConfig._retry = true;
              return updateTokenaAndProceed(originalConfig).then(() => instance(originalConfig));
            } else {
              setSession(undefined);
            }
            break;
          default:
            handleBadRequest(error?.response?.status, error?.response?.data);
            break;
        }
        setLoadingStatus({ isLoading: false });
        return Promise.reject(error);
      }
    );

    setAxiosInstance({ instance });
    httpService.setAxiosInstance(instance);

    return () => {
      setAxiosInstance({});
    };
  }, [baseURL, setLoadingStatus, locale, handleBadRequest, setErrorStatus, setSession, setToken, updateTokenaAndProceed]);

  return axiosInstance.instance;
};

const handleDates = (body: any) => {
  if (body === null || body === undefined || typeof body !== "object")
    return body;

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIso8601DateTime(value))
      body[key] = iso8601DateTimeReviver(value);
    else if (typeof value === "object") handleDates(value);
  }
};
