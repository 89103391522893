import React, { ReactNode, useContext, useEffect } from 'react';
import { ContainerDTO } from "../dto/ContainerDTO";
import { SessionDTO } from '../dto/SessionDTO';
import { TourDTO } from "../dto/TourDTO";
import { localStorageUtil } from '../utils/local.storage.util';
import { useSession } from './session.context';

const USERDATA_TOUR = "Truckgate-Tour";
const USERDATA_CONTAINER = "Truckgate-Container";

const getUserdataTourName = (session?: SessionDTO) => (USERDATA_TOUR + "." + session?.supplements?.truckerCardId);
const getUserdataContainerName = (session?: SessionDTO) => (USERDATA_CONTAINER + "." + session?.supplements?.truckerCardId);


type BookingContextType = {
  tour?: TourDTO;
  setTour: (data?: React.SetStateAction<TourDTO | undefined>) => void;
  container?: ContainerDTO;
  setContainer: (data?: React.SetStateAction<ContainerDTO | undefined>) => void;
};

const BookingContext = React.createContext<BookingContextType>({
  setTour: () => {
    // initially
  },
  setContainer: () => {
    // initially
  },
});

const getContainerFromStorage = (session?: SessionDTO) => {
  const ctr = localStorageUtil.get(getUserdataContainerName(session));
  return ctr ? JSON.parse(ctr) as ContainerDTO : undefined;
};

const getTourFromStorage = (session?: SessionDTO) => {
  const ctr = localStorageUtil.get(getUserdataTourName(session));
  return ctr ? JSON.parse(ctr) as TourDTO : undefined;
};

export const BookingContextProvider = (props: { children: ReactNode; }) => {
  const { session } = useSession();
  const userDataTourName = getUserdataTourName(session);
  const userDataContainerName = getUserdataContainerName(session);
  const [tour, setTour] = React.useState(getTourFromStorage(session));
  const [container, setContainer] = React.useState(getContainerFromStorage(session));

  useEffect(() => {
    tour ? localStorageUtil.add(userDataTourName, JSON.stringify(tour)) : localStorageUtil.remove(userDataTourName);
  }, [tour, userDataTourName]);

  useEffect(() => {
    container ? localStorageUtil.add(userDataContainerName, JSON.stringify(container)) : localStorageUtil.remove(userDataContainerName);
  }, [container, userDataContainerName]);

  //  Temp-Daten löschen beim Logout
  useEffect(() => {
    if (!session) {
      tour && setTour(undefined);
      container && setContainer(undefined);
    }
  }, [session, userDataTourName, userDataContainerName, tour, container]);

  return <BookingContext.Provider value={{ tour, setTour, container, setContainer }}>{props.children}</BookingContext.Provider>;
};

export const useBooking = () => {
  const context = useContext(BookingContext);
  if (context === undefined) {
    throw new Error('useBooking must be used within a BookingContextProvider');
  }
  return context;
};

