
export class StorageItem {
    key: string;
    value: any;

    constructor(key: string, value: any) {
        this.key = key;
        this.value = value;
    }
}

// class for working with local storage in browser (common that can use other classes for store some data)
class LocalStorageUtil {
    localStorageSupported: boolean;

    constructor() {
        this.localStorageSupported = window['localStorage'] != null;
    }

    // add value to storage
    add(key: string, item: string) {
        if (this.localStorageSupported) {
            localStorage.setItem(key, item);
        }
    }

    // get all values from storage (all items)
    getAllItems(): Array<StorageItem> {
        const list = new Array<StorageItem>();

        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            const value = key && localStorage.getItem(key);

            key && list.push({
                key: key,
                value: value
            });
        }
        return list;
    }

    // get only all values from localStorage
    getAllValues(): Array<any> {
        const list = new Array<any>();

        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            const value = key && localStorage.getItem(key);

            list.push(value);
        }
        return list;
    }

    // get one item by key from storage
    get(key: string) {
        if (this.localStorageSupported) {
            return localStorage.getItem(key);
        } else {
            return null;
        }
    }

    // remove value from storage
    remove(key: string) {
        if (this.localStorageSupported) {
            localStorage.removeItem(key);
        }
    }

    // clear storage (remove all items from it)
    clear() {
        if (this.localStorageSupported) {
            localStorage.clear();
        }
    }
}

export const localStorageUtil = new LocalStorageUtil();
