import { Dropdown } from 'primereact/dropdown';
import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { useBooking } from "../contexts/booking.context";
import { useStammdaten } from '../contexts/stammdaten.context';
import { TourDTO } from '../dto/TourDTO';
import { ValidationErrorDTO } from '../dto/ValidationErrorDTO';
import { ViewNameEnum } from '../dto/ViewNameEnum';
import { PROPERTY_FUHRUNTERNEHMEN, tourValidationService } from '../services/tour.validation.service';
import { NavigateBackNextView } from "./subComponents/navigatebacknext.component";
import { SelectionHeaderView } from "./selectionheader.component";
import { ValidationInfo } from './subComponents/validationinfo.component';

export const CtrTruckingCompanyView = () => {
  const validate = (validateTour: TourDTO) => (tourValidationService.validate(validateTour, (a) => a === PROPERTY_FUHRUNTERNEHMEN));

  const { tour, setTour } = useBooking();
  const [validationErrors, setValidationErrors] = useState<ValidationErrorDTO[]>(tour ? validate(tour) : []);
  const { fuhrunternehmen, setFuhrunternehmen } = useStammdaten();
  const { t } = useTranslation();

  const nextView = () => {
    return ViewNameEnum.TIME;
  };

  const onNextView = (nv: ViewNameEnum) => {
    if (nv === ViewNameEnum.BOOK) {
      const newTour = { ...tour, termin: new Date(), slotklasse: 'standard' } as TourDTO;
      setTour(newTour);
    }

    // Neues Fuhrunternehmen ggf. in Cache ergänzen
    if (tour?.fuhrunternehmen && !fuhrunternehmen?.includes(tour?.fuhrunternehmen)) {
      setFuhrunternehmen([...fuhrunternehmen || [], tour?.fuhrunternehmen]);
    }
  };

  const validateAndUpdate = (selectedTour: TourDTO) => {
    setValidationErrors(validate(selectedTour));
    setTour(selectedTour);
    return true;
  };

  const makeCarrierLabel = (fu: string) => {
    return fu + " (" + t('fuhrunternehmen') + " " + fu.trim().replace(/^(\d)(\d)$/g, "$1'$2\"") + ")";
  };

  const optionList = fuhrunternehmen?.map(f => ({ label: makeCarrierLabel(f), value: f }));
  const nextButtonTitle = nextView() === ViewNameEnum.TIME ? t('terminauswahl') : undefined;

  return tour?.terminal ? <div>
    <SelectionHeaderView />
    <div className="title">{t('fuhrunternehmen')}</div>

    <div className='formbox-wrapper content'>
      <div className='formbox'>
        <div className='formbox-field' data-testid="formbox-field">
          <Dropdown
            data-testid="dropdown"
            showClear
            filter
            placeholder={t('fuhrunternehmen')}
            value={tour?.fuhrunternehmen}
            options={optionList}
            resetFilterOnHide
            editable
            maxLength={20}
            onChange={(e) => validateAndUpdate({ ...tour, fuhrunternehmen: e.value })}
            autoFocus
          />
        </div>
        <ValidationInfo property={PROPERTY_FUHRUNTERNEHMEN} validationErrors={validationErrors} />
      </div>
    </div>

    <NavigateBackNextView nextView={nextView} nextButtonLabel={nextButtonTitle} onNextView={onNextView} nextDisabled={validationErrors.length !== 0} />

  </div> : <Navigate to={ViewNameEnum.ROOT} />;
};