export enum ViewNameEnum {
  ROOT = "/",
  LOGIN = "/login",
  LANGUAGE = "/language",
  TERMINALS = "/terminals",
  TOURENART = "/tourenart",
  CTRNR = "/ctrnr",
  CTRFREISTELLNR = "/ctrfreistellnr",
  CTRISOCODE = "/ctrisocode",
  CTRFULLEMPTY = "/ctrfullempty",
  CTRADDITIONALDATA = "/additionaldata",
  CTRLENGTH = "/ctrlength",
  CTRWEIGHT = "/ctrweight",
  CTRHEIGHT = "/ctrheight",
  CTRTYPE = "/ctrtype",
  CTRCARRIERCODE = "/ctrcarriercode",
  CTRTURNINREFERENCE = "/ctrturninreference",
  CTRSHOW = "/ctrshow",
  CTRTRUCKINGCOMPANY = "/ctrtruckingcompany",
  CTRBUCHUNGSNUMMER = "/ctrbuchungsnummer",
  CTRLOESCHHAFEN = "/ctrloeschhafen",
  CTRSHIPNR = "/ctrshipnr",
  CTRT1PCKSTK = "/ctrpckstk",
  CTRT1MRN = "/ctrt1mrn",
  CTROOG = "/ctroog",
  CTRQUAYACCOUNTNUMBER = "/ctrquayaccountnumber",
  TIME = "/appointment",
  BOOK = "/book",
  IDPLOGIN = "/login-idp",
  IDPLOGINCALLBACK = "/login-idp-callback",
  IDPLOGOUT = "/logout-idp",
}