import { ContainerzustandEnum } from "./ContainerzustandEnum";
import { SchiffsabfahrtDTO } from "./SchiffsabfahrtDTO";
import { StammdatenContainerIsoCodeDTO } from "./StammdatenContainerIsoCodeDTO";
import { StammdatenReederDTO } from "./StammdatenReederDTO";
import { StammdatenUnLoCodeDTO } from "./StammdatenUnLoCodeDTO";
import { TourenartEnum } from "./TourenartEnum";

export class ContainerDTO {
  position: number;

  tourenart: TourenartEnum;

  containernummer: string;
  shippersOwned: boolean;
  containerzustand: ContainerzustandEnum;

  bruttogewicht?: number;

  containerLaenge?: string;
  containerHoehe?: string;
  containerType?: string;

  buchungsnummer?: string;

  carrier: StammdatenReederDTO;
  carriageOnInformation?: SchiffsabfahrtDTO;
  loeschhafen?: StammdatenUnLoCodeDTO;
  containerIsoCode?: StammdatenContainerIsoCodeDTO;

  freistellreferenz: string;
  turninreferenz?: string;

  ueberhoehe?: number;
  ueberbreiteRechts?: number;
  ueberbreiteLinks?: number;
  ueberbreiteVorn?: number;
  ueberbreiteHinten?: number;

  ueberdimensioniert: boolean;
  gefahrgut: boolean;
  klv: boolean;
  t1: boolean;
  quayAccountNumberSpecified: boolean;
  nor: boolean;

  anzahlpackstuecke?: number;

  mrn?: string;
  warenposition?: string;
  quayAccountNumber?: string;
}
