import { AxiosResponse } from "axios";
import { OverviewTruckDTO } from "../dto/OverviewTruckDTO";
import { TourDTO } from "../dto/TourDTO";
import { apiPath } from "../utils/httpConfig";
import { httpService, makeURLCall } from "../utils/httpUtil";

export type TourFilter = {
  truckerCardId?: string;
  truckerId?: string;
};

export type StornoParameter = {
  transportvormeldungOid: string;
  tourenplanOid: string;
};

export type UmbuchungParameter = {
  tpOid: string;
  termin: Date;
};

export type TourService = {
  findActiveTouren: (filter: TourFilter) => Promise<AxiosResponse<OverviewTruckDTO[], any>>;
  bucheTour: (tour: TourDTO) => Promise<AxiosResponse<void, any>>;
  bucheTourUm: (umbuchungParam: UmbuchungParameter) => Promise<AxiosResponse<void, any>>;
  storniereTour: (stornoParam: StornoParameter) => Promise<AxiosResponse<void, any>>;
};

class TourServiceLocal implements TourService {
  findActiveTouren(filter: TourFilter) {
    return httpService
      .getAxios()
      .then((ai) =>
        ai.get<OverviewTruckDTO[]>(makeURLCall(apiPath("/touren"), filter))
      );
  }

  bucheTour(tour: TourDTO) {
    return httpService
      .getAxios()
      .then((ai) => ai.post<void>(apiPath("/touren"), tour));
  }   

  bucheTourUm(umbuchungParam: UmbuchungParameter) {
    return httpService
      .getAxios()
      .then((ai) => ai.put<void>(apiPath("/touren"), umbuchungParam));
  }

  storniereTour(stornoParam: StornoParameter) {
    return httpService
      .getAxios()
      .then((ai) => ai.delete<void>(apiPath("/touren"), { data: stornoParam }));
  }
}

export const tourService = new TourServiceLocal() as TourService;