export enum LoginTypeEnum {

    /** user was not found */
    USER_NOT_FOUND = "USER_NOT_FOUND",

    /** user is not active */
    USER_NOT_ACTIVE = "USER_NOT_ACTIVE",

    /** max active user login count of a company exceeded */
    USER_MAX_COUNT_EXCEEDED = "USER_MAX_COUNT_EXCEEDED",

    /** max active user login count of a company exceeded */
    USER_ALREADY_LOGGED_IN = "USER_ALREADY_LOGGED_IN",

    /** company is not active */
    COMPANY_NOT_ACTIVE = "COMPANY_NOT_ACTIVE",

    /** login is ok, user and password are correct */
    LOGIN_OK = "LOGIN_OK",

    /** login failed, user and password are not correct */
    LOGIN_FAILED = "LOGIN_FAILED",

    /** access denied for external client network address */
    ACCESS_DENIED_FOR_EXTERNAL_IP = "ACCESS_DENIED_FOR_EXTERNAL_IP",

    /** access denied for external client network address for support/master */
    ACCESS_DENIED_FOR_EXTERNAL_SUPPORT_MASTER = "ACCESS_DENIED_FOR_EXTERNAL_SUPPORT_MASTER",

    /** validation of newPassword is ok*/
    VALIDATION_OK = "VALIDATION_OK",

    /** changing password failed because new password is one of the lastpasswords */
    VALIDATION_LAST_PASSWORD = "VALIDATION_LAST_PASSWORD",

    /** changing password failed because new password is to similar to old password */
    VALIDATION_PART_OF_OLD_PASSWORD = "VALIDATION_PART_OF_OLD_PASSWORD",

    /** no SSO configured for the company */
    SSO_NOT_SUPPORTED = "SSO_NOT_SUPPORTED",

    /** unknown login type **/
    UNKNOWN = "UNKNOWN"
}