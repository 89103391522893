import { Checkbox } from "primereact/checkbox";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { useBooking } from "../contexts/booking.context";
import { ContainerDTO } from "../dto/ContainerDTO";
import { TourenartEnum } from "../dto/TourenartEnum";
import { ViewNameEnum } from "../dto/ViewNameEnum";
import { additionalOptionsService } from "../services/additionaloptions.service";
import { SelectionHeaderView } from "./selectionheader.component";
import { NavigateBackNextView } from "./subComponents/navigatebacknext.component";

export const CtrAdditionalDataView = () => {
  const { tour, container, setContainer } = useBooking();
  const { t } = useTranslation();

  const additionalOptions = additionalOptionsService.getAdditionalOptions(tour?.terminal, container);

  const nextView = () => {
    if (container?.quayAccountNumberSpecified) {
      return ViewNameEnum.CTRQUAYACCOUNTNUMBER;
    }
    if (container?.ueberdimensioniert) {
      return ViewNameEnum.CTROOG;
    }
    if (container?.t1) {
      return container?.tourenart === TourenartEnum.AUSLIEFERUNG ? ViewNameEnum.CTRT1PCKSTK : ViewNameEnum.CTRT1MRN;
    }
    return container?.tourenart === TourenartEnum.AUSLIEFERUNG ? ViewNameEnum.CTRSHOW : ViewNameEnum.CTRCARRIERCODE;
  };

  const setContainerNormalized = (ctr?: ContainerDTO) => {
    const oog = ctr?.ueberdimensioniert;

    ctr && setContainer({ ...ctr,
       mrn: ctr.t1 ? ctr.mrn : undefined,
       warenposition: ctr.t1 ? ctr.warenposition : undefined,
       anzahlpackstuecke: ctr.t1 ? ctr.anzahlpackstuecke : undefined,
       ueberhoehe: oog ? ctr.ueberhoehe : undefined,
       ueberbreiteRechts: oog ? ctr.ueberbreiteRechts : undefined,
       ueberbreiteLinks: oog ? ctr.ueberbreiteLinks : undefined,
       ueberbreiteVorn: oog ? ctr.ueberbreiteVorn : undefined,
       ueberbreiteHinten: oog ? ctr.ueberbreiteHinten : undefined,
       quayAccountNumber: ctr.quayAccountNumberSpecified ? ctr.quayAccountNumber : undefined,
       loeschhafen: undefined,
       carriageOnInformation: ctr.klv ? undefined : ctr.carriageOnInformation,
     })
  }

  if (additionalOptions?.length === 0) {
    return <Navigate to={nextView()} replace={true} />;
  }

  return <div>
    <SelectionHeaderView />
    <div className="title">{t('CtrAdditionalDataView')}</div>
    <div className='formbox-wrapper content'>
      <div className='formbox'>
        <div className='formbox-field'>
          <ul>
            {additionalOptions && additionalOptions.map((opt, idx) =>
              <div key={opt}>
                <Checkbox
                  data-testid="checkbox"
                  id={opt}
                  checked={container && container[opt as keyof typeof container]}
                  onChange={(e) => container && setContainerNormalized({ ...container, [opt]: e.checked })}
                />
                {t(opt as any)}
              </div>
            )}
          </ul>
        </div>
      </div>
    </div>
    <NavigateBackNextView nextView={nextView} nextAutoFocus={true} />
  </div>;
};