import { faExclamationTriangle, faInfoCircle, faXmarkLarge } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from 'moment';
import { classNames } from 'primereact/utils';
import { useEffect, useRef, useState } from "react";
import ReactMarkdown from 'react-markdown';
import { useClientMessages } from '../../contexts/clientmessages.context';
import { useErrorStatus } from '../../contexts/errorstatus.context';
import { useLoadingStatusContext } from '../../contexts/loadingstatus.context';
import { useSession } from '../../contexts/session.context';
import { useSystemparameter } from '../../contexts/systemparameter.context';
import { ClientMessageDTO } from "../../dto/ClientMessageDTO";
import { clientMessageService } from "../../services/clientmessage.service";

const DEFAULT_RELOAD_INTERVAL_SEC = 10 * 60;
const DEFAULT_MINIMUM_DELAY_SEC = 10;

class LastClientMessages {
  lastUpdate: Date;
  messages: ClientMessageDTO[];
}

export const ClientMessages = () => {

  const { clientMessages, setClientMessages } = useClientMessages();

  const [loadTrigger, setLoadTrigger] = useState<Date>();
  const { loadingStatus } = useLoadingStatusContext();
  const { setErrorStatus } = useErrorStatus();
  const { locale } = useSession();
  const { systemparameter } = useSystemparameter();

  const lastMsgRef = useRef<LastClientMessages>();

  const reloadIntervalSec = systemparameter?.clientMessagesCheckIntervalSec || DEFAULT_RELOAD_INTERVAL_SEC;
  const minimumDeleySec = systemparameter?.clientMessagesCheckMinimumDelaySec || DEFAULT_MINIMUM_DELAY_SEC;

  // Aktualisierung der Clientnachrichten gem. RELOAD_INTERVAL
  // Weiterhin Aktualisierung, wenn ein REST-Call ausgeführt wurde und die letzte Aktualisierung der Clientnachrichten vor länger als MINIMUM_DELAY_SEC stattfand
  useEffect(() => {
    clientMessageService.load(locale).then(response => {
      // Verhinderung mehrfachen Triggerns
      lastMsgRef.current = { lastUpdate: new Date(), messages: lastMsgRef.current?.messages || [] };

      const compareMessages = lastMsgRef.current?.messages || [];
      const list = response?.data?.map((m) => {
        // Hidden-Status übernehmen, falls weggeklickt
        const oldMessage = compareMessages.find(oldmsg => oldmsg.translation === m.translation);
        return { ...m, hidden: oldMessage?.hidden }
      }
      );

      setClientMessages({ lastUpdate: new Date(), messages: list });
    })
      .catch(e => console.warn("Error loading client messages: " + e));
    ;

  }, [locale, loadTrigger, setErrorStatus, setClientMessages]);

  // Änderungen in lastMsgRef nachziehen
  useEffect(() => {
    lastMsgRef.current = clientMessages;
  }, [clientMessages]);

  // Trigger durch anderen aktiven Request, nur wenn genug Zeit vergangen
  useEffect(() => {
    const now = new Date();
    if (!loadingStatus.isLoading && now.getTime() - minimumDeleySec * 1000 > (lastMsgRef.current?.lastUpdate?.getTime() || 0)) {
      // Verhinderung mehrfachen Triggerns
      lastMsgRef.current = { lastUpdate: new Date(), messages: lastMsgRef.current?.messages || [] };
      setLoadTrigger(new Date());
    }
  }, [loadingStatus, minimumDeleySec]);

  useEffect(() => {
    const timer = setInterval(() => {
      setLoadTrigger(new Date());
    }, reloadIntervalSec * 1000);

    return () => clearTimeout(timer);
  }, [reloadIntervalSec]);

  return <div>
    {clientMessages?.messages.filter(m => !m.hidden).map((m, idx) =>
      <div className={classNames({ 'clientmessage-warning': m.type === 'WARNING' }, "clientmessage")} key={idx}>
        <div className={'clientmessage-icon'} >
          <FontAwesomeIcon icon={m.type === 'WARNING' ? faExclamationTriangle : faInfoCircle} />
        </div>
        <div>{m.startTime && moment(m.startTime).format('DD.MM.YYYY HH:mm')}</div>
        <div><ReactMarkdown>{m.translation}</ReactMarkdown></div>
        <div onClick={() => {
          const newMsg = { ...clientMessages, messages: [...clientMessages.messages.filter(cm => cm.translation !== m.translation), { ...m, hidden: true }] };
          setClientMessages(newMsg);
        }} style={{ marginLeft: 'auto' }} ><FontAwesomeIcon icon={faXmarkLarge} /></div>
      </div>)
    }
  </div>;
};