import { faAnglesLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "primereact/button";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useBooking } from "../contexts/booking.context";
import { useSession } from "../contexts/session.context";
import { useStammdaten } from "../contexts/stammdaten.context";
import { useSystemparameter } from "../contexts/systemparameter.context";
import { OverviewTruckDTO } from "../dto/OverviewTruckDTO";
import { TerminalDTO } from "../dto/TerminalDTO";
import { terminalLogos } from "../dto/terminallogos";
import { TourDTO } from "../dto/TourDTO";
import { ViewNameEnum } from "../dto/ViewNameEnum";
import { tourService } from "../services/tour.service";
import { TerminalViewSingleTerminal } from "./subComponents/terminalviewSingleTerminal.component";


type Props = {};

export const TerminalView = (props: Props) => {
  const navigate = useNavigate();
  const { session } = useSession();
  const { terminals } = useStammdaten();
  const [touren, setTouren] = useState<OverviewTruckDTO[]>();
  const { tour, setTour } = useBooking();
  const { t } = useTranslation();
  const { systemparameter } = useSystemparameter();
  const timer = useRef<NodeJS.Timeout | null>(null); // we can save timer in useRef and pass it to child
  const [loadTrigger, setLoadTrigger] = useState<Date>();
  const truckerCardId =  session?.supplements?.truckerCardId;
  const truckerId =  session?.supplements?.truckerId;

  const RELOAD_INTERVAL_SEC = 30;

  useEffect(() => {
    !tour && touren && touren?.length > 0 && setTour(new TourDTO());
  });

  useEffect(() => {
    const loadActiveTouren = async () => {
      (truckerCardId || truckerId) && tourService.findActiveTouren({ truckerCardId, truckerId })
        .then(response => { response?.data && setTouren(response.data); })
        .catch(e => console.log(e));
    };
    loadActiveTouren();
  }, [truckerCardId, truckerId, loadTrigger]);

  useEffect(() => {
    resetTimeout(timer.current);

    timer.current = timer.current = setTimeout(() => {
      setLoadTrigger(new Date());
    }, (systemparameter?.updateInterval || RELOAD_INTERVAL_SEC) * 1000);

    return () => resetTimeout(timer.current);
  }, [systemparameter, loadTrigger]);

  const resetTimeout = (tim: any) => {
    tim && clearTimeout(tim);
  };

  const hasActiveTour = (terminalCode: string) => {
    return !!(touren?.find(terminal => terminal.terminalCode === terminalCode));
  };

  const selectTerminal = (terminal: TerminalDTO) => {
    const newTour = new TourDTO();
    newTour.terminal = terminal;
    if (session?.supplements?.truckerCardId) {
      newTour.truckerCardId = session?.supplements?.truckerCardId;
    }
    setTour(newTour);
    navigate(ViewNameEnum.TOURENART);
  };

  // Spaltenliste mit den Terminals gemäß order (<spalte>.<zeile>)
  const terminalListColumns = [1, 2, 3, 4].map(rownum => (terminals?.filter(terminal => terminal.order.match("^" + rownum + "\\."))));
  // Terminals, die nicht gefunden wurden
  const restTerminals = terminals?.filter(terminal => !terminal.order || !terminal.order.match(/^[1-4]\./));

  return (
    <div className="TerminalView content">
      <div className="title">{t("TerminalView" as any)}</div>
      <div className="TerminalViewTerminals">

        {terminalListColumns.filter(row => row && row.length > 0).map((col, colIdx) =>
          <div className="TerminalViewTerminalRow" key={colIdx}>
            {col?.map((terminal, rowIdx) =>
              <TerminalViewSingleTerminal autofocus={colIdx === 0 && rowIdx === 0} key={terminal.code} terminal={terminal} onClick={() => !hasActiveTour(terminal.codeForView) && selectTerminal(terminal)} src={terminalLogos[terminal.codeForView as keyof typeof terminalLogos]} alt={terminal.codeForView} disabled={hasActiveTour(terminal.codeForView)} />
            )}
          </div>)}
        <div className="TerminalViewTerminalRow">
          {restTerminals?.map(terminal =>
            <TerminalViewSingleTerminal autofocus={false} key={terminal.code} terminal={terminal} onClick={() => !hasActiveTour(terminal.codeForView) && selectTerminal(terminal)} src={terminalLogos[terminal.codeForView as keyof typeof terminalLogos]} alt={terminal.codeForView} disabled={hasActiveTour(terminal.codeForView)} />
          )}
        </div>
      </div>
      <div className="nav-back-next-btns floating-btn">
        {touren && touren?.length > 0 && <Button className="primary-btn" onClick={() => navigate(ViewNameEnum.ROOT, { replace: true })}><FontAwesomeIcon icon={faAnglesLeft} /><span>{t('back')}</span></Button>}
      </div>
    </div >
  );
};
