import { faAnglesLeft, faAnglesRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError, AxiosResponse } from 'axios';
import moment, { Moment } from 'moment';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useBooking } from "../contexts/booking.context";
import { SlotAuslastungsgradEnum } from '../dto/SlotAuslastungsgradEnum';
import { ViewNameEnum } from '../dto/ViewNameEnum';
import { slotauslastungService } from '../services/slotauslastung.service';
import { getLocalISODate } from '../utils/iso8601.util';
import { SelectionHeaderView } from "./selectionheader.component";
import { NavigateBackNextView } from './subComponents/navigatebacknext.component';
import { TerminviewTermin } from './subComponents/terminviewTermin.component';
import { getSlotTime } from '../utils/dateutil';

export const TerminView = () => {
  const { tour, setTour } = useBooking();
  const [filter, setFilter] = useState({ kategorie: 'standard', terminalCode: tour?.terminal?.code || 'XXX', termin: moment().startOf('day') });
  const [tagesauslastung, setTagesauslastung] = useState<SlotAuslastungsgradEnum[]>();
  const [selectedSlot, setSelectedSlot] = useState<Moment>();
  const { t } = useTranslation();
  const toast = useRef<Toast>(null);
  const nextView = () => {
    return ViewNameEnum.BOOK;
  };

  useEffect(() => {
    const load = async () => {
      if (tour?.terminal?.slotBuchungRole) {
        slotauslastungService.getSlotauslastungPerDay({ ...filter, termin: getLocalISODate(filter.termin.toDate()) })
          .then((response: AxiosResponse<SlotAuslastungsgradEnum[]>) => {
            // response.data[16] = SlotAuslastungsgradEnum.KRITISCH;
            // response.data[17] = SlotAuslastungsgradEnum.VOLL;
            // response.data[18] = SlotAuslastungsgradEnum.GESPERRT;
            setTagesauslastung(response.data);
          }).catch((e: AxiosError) => {
            toast.current?.show({ severity: 'error', summary: t('fehler'), detail: t(`server.http.${e.response?.status}` as any, t('server.error', { status: e.response?.status })), life: 3000 });
          });
      } else {
        setTagesauslastung(Array(24).fill(null));
      }
    };
    load();
  }, [filter, t, tour?.terminal?.slotBuchungRole]);

  const setTerminAndFilter = (termin: Moment) => {
    setFilter({ ...filter, termin: termin });
    setSelectedSlot(undefined);
    tour && setTour({ ...tour, termin: termin.toDate(), slotklasse: filter.kategorie });
  };

  const selectTermin = (slot: Moment) => {
    if (tour) {
      const tourNeu = { ...tour, termin: slot.toDate(), slotklasse: filter.kategorie };
      tour && setTour(tourNeu);
      setSelectedSlot(slot);
    }
  };

  const getTermin = () => {
    if (selectedSlot) {
      return t("datumMitTagUndUhrzeit", { 'date': selectedSlot.toDate() }) + "-" + t("datumUhrzeit", { 'date': moment(selectedSlot).add(1, 'hours').toDate() });
    } else {
      return t("datumMitTag", { 'date': filter.termin.toDate() });
    }
  };

  const terminList = tagesauslastung?.map((status, stunde) => ({
    slot: moment(filter.termin).startOf('day').hour(stunde).startOf('hour'),
    status: status
  }));

  return <div>
    <Toast ref={toast} position="bottom-right" />
    <SelectionHeaderView />
    <div className='terminview-wrapper content'>
      <div className='terminview'>
        <div className='terminview-title'>
          <div className='title'>{t('TerminView')}</div>
        </div>
        <div className='terminview-options'>
          <div className='terminview-options-row'>
            <span>{t("slotklasse")}:</span>
            <span><InputText id="kategorie" value={filter.kategorie} onChange={(e) => setFilter({ ...filter, kategorie: e.target.value })} /></span>
          </div>
          <div className='terminview-options-row'>
            <span>{t("ausgewaehlterTermin")}:</span>
            <span><span>{getTermin()}</span></span>
          </div>
          <div className='terminview-options-row terminview-options-row-buttons' data-testid="terminview-options-row-buttons">
            <span><Button className="primary-btn" onClick={() => setTerminAndFilter(filter.termin.add(-1, 'days'))} disabled={!filter.termin.isAfter(moment().startOf('day'))} ><FontAwesomeIcon icon={faAnglesLeft} /><span>{t('frueher')}</span></Button></span>
            <span><Button className="primary-btn" onClick={() => setTerminAndFilter(moment().startOf('day'))} autoFocus ><span>{t('heute')}</span></Button></span>
            <span><Button className="primary-btn" onClick={() => setTerminAndFilter(filter.termin.add(1, 'days'))} ><span>{t('spaeter')}</span> <FontAwesomeIcon icon={faAnglesRight} /></Button></span>
          </div>
        </div>

        <div className="terminview-termine" data-testid="terminview-termine">
          {terminList?.map((a, idx) => {
            const slotTerminVon = moment(getSlotTime(a.slot.toDate()));
            const slotExpired = moment().startOf('hour').isAfter(slotTerminVon);

            return <TerminviewTermin key={idx} time={a.slot.get('hour')} 
              ampelState={slotExpired ? SlotAuslastungsgradEnum.GESPERRT : a.status} activeTime={selectedSlot?.hour() || -1} onClick={() => selectTermin(slotTerminVon)}
              disabled={[SlotAuslastungsgradEnum.GESPERRT, SlotAuslastungsgradEnum.VOLL].includes(a.status) || slotExpired} />
          }
          )}
        </div>

      </div>
    </div>
    <NavigateBackNextView nextView={nextView} nextDisabled={!selectedSlot} nextButtonLabel={t('book')} nextAutoFocus={!!selectedSlot} />

  </div>;
};