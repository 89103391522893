import { AxiosResponse } from 'axios';
import moment from 'moment';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useBooking } from "../contexts/booking.context";
import { ContainerDTO } from '../dto/ContainerDTO';
import { SchiffsabfahrtDTO } from '../dto/SchiffsabfahrtDTO';
import { ValidationErrorDTO } from '../dto/ValidationErrorDTO';
import { ViewNameEnum } from '../dto/ViewNameEnum';
import { containerValidationService, PROPERTY_CARRIAGEONINFORMATION } from '../services/container.validation.service';
import { stammdatenService } from '../services/stammdaten.service';
import { NavigateBackNextView } from "./subComponents/navigatebacknext.component";
import { SelectionHeaderView } from "./selectionheader.component";
import { ValidationInfo } from './subComponents/validationinfo.component';

export const CtrShipNrView = () => {

  const validate = (ctr: ContainerDTO) => (containerValidationService.validate(ctr, (a) => a === PROPERTY_CARRIAGEONINFORMATION));

  const { tour, container, setContainer } = useBooking();
  const [schiffsabfahrten, setSchiffsabfahrten] = useState<SchiffsabfahrtDTO[]>();
  const { t } = useTranslation();
  const [validationErrors, setValidationErrors] = useState<ValidationErrorDTO[]>(container ? validate(container) : []);

  useEffect(() => {
    const load = async () => {
      tour?.terminal?.code && stammdatenService.findCarriageOnInformation({ terminalCode: tour?.terminal?.code })
        .then((response: AxiosResponse) => {
          setSchiffsabfahrten(response.data);
        });
    };
    load();
  }, [tour]);

  const validateAndUpdate = (newContainer: ContainerDTO) => {
    setValidationErrors(validate(newContainer));
    setContainer(newContainer);
    return true;
  };

  const nextView = () => {
    return ViewNameEnum.CTRLOESCHHAFEN;
  };

  const makeLabel = (abfahrt: SchiffsabfahrtDTO) => {
    return (abfahrt.schiffCallSign + (abfahrt.schiffname ? " (" + abfahrt.schiffname + " " + abfahrt.schiffNummer + ")" : '') + (abfahrt.eta ? " (" + moment(abfahrt.eta).format("DD.MM.YYYY HH:mm") + ")" : ''));
  };

  const shipViewList = schiffsabfahrten?.map(abfahrt => ({ label: makeLabel(abfahrt), value: abfahrt }));

  return <div>
    <SelectionHeaderView />
    <div className="title">{t('carriageOnInformation')}</div>

    <div className='formbox-wrapper content'>
      <div className='formbox'>
        <div className='formbox-field'>
          <Dropdown showClear filter
            data-testid="dropdown"
            placeholder={t('carriageOnInformation')}
            value={container?.carriageOnInformation}
            filterBy="label"
            options={shipViewList}
            resetFilterOnHide
            onChange={(e) => container && validateAndUpdate({ ...container, carriageOnInformation: e.target.value })}
            className={classNames({ 'p-invalid': validationErrors.find(e => e.property === PROPERTY_CARRIAGEONINFORMATION) })}
            autoFocus
          />
        </div>
        <ValidationInfo property={PROPERTY_CARRIAGEONINFORMATION} validationErrors={validationErrors} />
      </div>
    </div>

    <NavigateBackNextView nextView={nextView} nextDisabled={!container?.carriageOnInformation} />

  </div>;
};