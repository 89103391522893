import React, { useContext, ReactNode } from 'react';
import { ErrorResponseDTO } from '../dto/ErrorResponseDTO';

type ErrorStatusContextType = {
  errorStatus?: ErrorResponseDTO;
  setErrorStatus: (data?: React.SetStateAction<ErrorResponseDTO | undefined>) => void;
  setErrorStatusText: (text: string) => void;
  clearFieldErrors: (fieldName: string) => void;
};

const ErrorStatusContext = React.createContext<ErrorStatusContextType>({
  setErrorStatus: () => {
    // Dummy bis zur Initialiisierung
  },

  setErrorStatusText: () => {

  },

  clearFieldErrors: () => {
    // Dummy bis zur Initialiisierung
  },
  
});

export const ErrorStatusContextProvider = (props: { children: ReactNode; }) => {
  const [errorStatus, setErrorStatus] = React.useState<ErrorResponseDTO>();

  const clearFieldErrors = (fieldName: string) => {
    if (errorStatus?.errors) {
      errorStatus.errors = errorStatus?.errors?.filter(e => e.field !== fieldName);
      if (errorStatus.errors.length === 0) {
        setErrorStatus(undefined); // Clear global after removing last field error
      }
    }
  };

  const setErrorStatusText = (text: string) => {
    const genericError = {
      message: text,
      status: 999,
      timestamp: new Date(),
    } as ErrorResponseDTO;
    setErrorStatus(genericError);
  };

  return <ErrorStatusContext.Provider value={{ errorStatus, setErrorStatus, clearFieldErrors, setErrorStatusText }}>{props.children}</ErrorStatusContext.Provider>;
};

export const useErrorStatus = () => {
  const context = useContext(ErrorStatusContext);
  if (context === undefined) {
    throw new Error('useErrorStatusContext must be used within a ErrorStatusContextProvider');
  }
  return context;
};

