import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBooking } from "../contexts/booking.context";
import { ContainerDTO } from "../dto/ContainerDTO";
import { ViewNameEnum } from "../dto/ViewNameEnum";
import { stammdatenService } from "../services/stammdaten.service";
import { NavigateBackNextView } from "./subComponents/navigatebacknext.component";
import { SelectionHeaderView } from "./selectionheader.component";
import { StringShortcutSelectorView } from "./subComponents/stringshortcutselectorview.component";

const SHORTCUT_CODES = ["86", "96"];

export const CtrHeightView = () => {
  const { container, setContainer } = useBooking();
  const { t } = useTranslation();
  const [containerHoeheList, setContainerHoeheList] = useState<string[]>();

  useEffect(() => {
    const load = async () => {
      stammdatenService.findContainerHoehen({ l: container?.containerLaenge })
        .then((response: AxiosResponse) => {
          setContainerHoeheList(response.data);
        });
    };
    load();
  }, [container]);

  const nextView = (ctr: ContainerDTO | undefined = container) => (
    ViewNameEnum.CTRTYPE
  );

  const makeCtrHoeheLabel = (hoehe: string) => {
    return hoehe ? hoehe + " (" + t('hoehe') + " " + hoehe?.trim().replace(/^(\d)(\d)$/g, "$1'$2\"") + "')" : t('containerHoehe');
  };

  return <div>
    <SelectionHeaderView />
    <div className="title">{t('containerHoehe')}</div>
    <div className="content">
      <StringShortcutSelectorView label={t('containerHoehe')}
        itemTemplate={makeCtrHoeheLabel}
        selectedString={container?.containerHoehe || ""}
        setSelectedString={(str) => container && setContainer({ ...container, containerHoehe: str })}
        nextView={nextView}
        stringList={containerHoeheList || []} shortcutStringList={SHORTCUT_CODES}
      />
    </div>
    <NavigateBackNextView nextView={nextView} nextDisabled={!container?.containerHoehe} />

  </div>;
};