import { useTranslation } from "react-i18next";
import logo from '../assets/images/truckgate_logo_374x51.png';

const ErrorView = (props: { error: string; }) => {
    const { t } = useTranslation();

    return (
        <div className="errorview">
            <div className="errorview-Header">
                <img className='logo' src={logo} alt="Logo" />
            </div>
            <div className="errorview-content">
                <div>
                    <p>{t("errorview.text")}</p>
                    <span className="errorview-Link"><button onClick={() => {
                        window.location.replace(window.location.origin);

                    }
                    }>{t("errorview.button")}</button></span>
                </div>
            </div>
            <div className="errorview-content">
                {props.error}
            </div>
        </div>
    );
};

export default ErrorView;
