import * as Sentry from '@sentry/browser';
import { TransportOptions } from '@sentry/types';
import { useEffect } from 'react';
import { useSession } from '../contexts/session.context';
import { apiBasePath } from './httpConfig';
import { getStageName } from './versionUtils';
  
export const SentryInitializer = () => {

  const { session, token } = useSession();

  useEffect(() => {
      const dsn = window.location.protocol + '//1234@' + window.location.hostname + (window.location.port && (':' + window.location.port)) + '/1';
      const env = getStageName() || 'dev';
      const component = 'frontend';
      const release = 'truckgate@' + process.env.REACT_APP_VERSION;
      
      token && Sentry.init({
        dsn: dsn, release: release, environment: env,
        tunnel: apiBasePath + "/1/envelope",
        autoSessionTracking: false,
        transportOptions: { dsn: dsn, headers: { 'Authorization': 'Bearer ' + token } } as TransportOptions,
      });
      Sentry.setTag('component', component);
      session?.user && Sentry.setUser(session.user);
  }, [session, token]);

  return <></>;
}

