import * as Sentry from "@sentry/react";
import { t } from "i18next";
import {
  Route,
  BrowserRouter as Router,
  Routes
} from "react-router-dom";
import { BuchungView } from "./components/buchungview.component";
import { CtrAdditionalDataView } from "./components/ctradditionaldataview.component";
import { CtrBuchungsNrView } from "./components/ctrbuchungsnrview.component";
import { CtrCarrierCodeView } from "./components/ctrcarriercodeview.component";
import { CtrFreistellNrView } from "./components/ctrfreistellnrview.component";
import { CtrFullEmptyView } from "./components/ctrfullempty.component";
import { CtrHeightView } from "./components/ctrheighthview.component";
import { CtrIsoCodeView } from "./components/ctrisocodeview.component";
import { CtrLengthView } from "./components/ctrlengthview.component";
import { CtrLoeschHafenView } from "./components/ctrloeschhafenview.component";
import { CtrNrView } from "./components/ctrnrview.component";
import { CtrOogView } from "./components/ctroogview.component";
import { CtrQuayAccountNumberView } from "./components/ctrquayaccountnumberview.component";
import { CtrShipNrView } from "./components/ctrshipnrview.component";
import { CtrShowView } from "./components/ctrshowview.component";
import { CtrT1MrnView } from "./components/ctrt1mrnview.component";
import { CtrT1PckStkView } from "./components/ctrt1pckstkview.component";
import { CtrTruckingCompanyView } from "./components/ctrtruckingcompanyview.component";
import { CtrTurninReferenceView } from "./components/ctrturninreferenceview.component";
import { CtrTypeView } from "./components/ctrtypeview.component";
import { CtrWeightView } from "./components/ctrweightview.component";
import { ErrorStatus } from "./components/errorstatus.component";
import ErrorView from "./components/errorview";
import { Footer } from "./components/footer.component";
import { Header } from "./components/header.component";
import { Initialize } from "./components/initialize.component";
import { Language } from "./components/language.component";
import { Login } from "./components/login.component";
import { BookReadyOutlet, ContainerSizeOutlet, EditContainerOutlet, EditTourOutlet, LocaleOutlet, PrivateOutlet } from "./components/privateoutlet.cmponent";
import { AutoUpdater } from "./components/subComponents/autoupdater.component";
import { ClientMessages } from "./components/subComponents/clientmessages.component";
import { IdpLoginInternal, IdpLogin, IdpLogout } from "./components/subComponents/idplogin.component";
import { TerminalView } from "./components/terminalview.component";
import { TerminView } from "./components/terminview.component";
import { TourenartView } from "./components/tourenartview.component";
import { TourenplanShowView } from "./components/tourenplanshowview.component";
import { BookingContextProvider } from "./contexts/booking.context";
import { ClientMessagesContextProvider } from "./contexts/clientmessages.context";
import { ErrorStatusContextProvider } from "./contexts/errorstatus.context";
import { LoadingStatusContextProvider } from "./contexts/loadingstatus.context";
import { SessionContextProvider } from "./contexts/session.context";
import { StammdatenContextProvider } from "./contexts/stammdaten.context";
import { SystemparameterContextProvider } from "./contexts/systemparameter.context";
import { ViewNameEnum } from "./dto/ViewNameEnum";
import { OperatingSystemEnum } from "./enum/OperatingSystemEnum";
import "./scss/styles.scss";
import { DeviceChecker } from "./utils/DeviceChecker";
import { SentryInitializer } from "./utils/sentryInitializer";
import useDocumentTitle from './utils/useDocumentTitle';
import { getStageName } from "./utils/versionUtils";
import { IdpContextProvider } from "./contexts/idp.context";

export const App = () => {

  const OS = DeviceChecker.getMobileOS();
  if (OS === OperatingSystemEnum.ANDROID || OS === OperatingSystemEnum.IOS || OS === OperatingSystemEnum.WINDOWS_PHONE) {
    window.addEventListener("resize", function () {
      if (document.activeElement && (document.activeElement.tagName === "INPUT" || document.activeElement.tagName === "TEXTAREA")) {
        window.setTimeout(function () {
          document.activeElement?.scrollIntoView();
        }, 0);
      }
    });
  }

  useDocumentTitle(t('titlename.' + (getStageName() || 'dev') as any));

  return <SessionContextProvider>
    <LoadingStatusContextProvider>
      <ErrorStatusContextProvider>
        <ClientMessagesContextProvider>
          <Initialize>
            <SentryInitializer />
            <Sentry.ErrorBoundary fallback={({ error, componentStack, resetError }) => <ErrorView error={error?.toString()} />}>
              <SystemparameterContextProvider>
                <IdpContextProvider>
                <StammdatenContextProvider>
                  <BookingContextProvider>
                    <Router basename={process.env.PUBLIC_URL}>
                      <div className="App">
                        <Header />
                        <ClientMessages />
                        <ErrorStatus />
                        <Routes>
                          <Route path={ViewNameEnum.IDPLOGIN + "/:oidcProviderCode"} element={<IdpLogin />} />
                          <Route path={ViewNameEnum.IDPLOGINCALLBACK  + "/:oidcProviderCode"} element={<IdpLoginInternal />} />
                          <Route path={ViewNameEnum.IDPLOGOUT} element={<IdpLogout />} />
                          <Route element={<LocaleOutlet />}>
                            <Route element={<PrivateOutlet />}>
                              <Route path={ViewNameEnum.ROOT} element={<AutoUpdater><TourenplanShowView /></AutoUpdater>} />
                              <Route path={ViewNameEnum.TERMINALS} element={<AutoUpdater><TerminalView /></AutoUpdater>} />
                              <Route element={<EditTourOutlet />} >
                                <Route path={ViewNameEnum.TOURENART} element={<TourenartView />} />
                                <Route path={ViewNameEnum.CTRSHOW} element={<CtrShowView />} />
                                <Route path={ViewNameEnum.CTRTRUCKINGCOMPANY} element={<CtrTruckingCompanyView />} />
                                <Route path={ViewNameEnum.TIME} element={<TerminView />} />
                                <Route element={<BookReadyOutlet />} >
                                  <Route path={ViewNameEnum.BOOK} element={<BuchungView />} />
                                </Route>
                                <Route element={<EditContainerOutlet />} >
                                  <Route path={ViewNameEnum.CTRNR} element={<CtrNrView />} />
                                  <Route path={ViewNameEnum.CTRFREISTELLNR} element={<CtrFreistellNrView />} />
                                  <Route path={ViewNameEnum.CTRFULLEMPTY} element={<CtrFullEmptyView />} />
                                  <Route path={ViewNameEnum.CTRISOCODE} element={<CtrIsoCodeView />} />
                                  <Route element={<ContainerSizeOutlet />}>
                                    <Route path={ViewNameEnum.CTRLENGTH} element={<CtrLengthView />} />
                                    <Route path={ViewNameEnum.CTRHEIGHT} element={<CtrHeightView />} />
                                    <Route path={ViewNameEnum.CTRTYPE} element={<CtrTypeView />} />
                                  </Route>
                                  <Route path={ViewNameEnum.CTRADDITIONALDATA} element={<CtrAdditionalDataView />} />
                                  <Route path={ViewNameEnum.CTRCARRIERCODE} element={<CtrCarrierCodeView />} />
                                  <Route path={ViewNameEnum.CTRWEIGHT} element={<CtrWeightView />} />
                                  <Route path={ViewNameEnum.CTRBUCHUNGSNUMMER} element={<CtrBuchungsNrView />} />
                                  <Route path={ViewNameEnum.CTRTURNINREFERENCE} element={<CtrTurninReferenceView />} />
                                  <Route path={ViewNameEnum.CTRT1MRN} element={<CtrT1MrnView />} />
                                  <Route path={ViewNameEnum.CTRT1PCKSTK} element={<CtrT1PckStkView />} />
                                  <Route path={ViewNameEnum.CTROOG} element={<CtrOogView />} />
                                  <Route path={ViewNameEnum.CTRSHIPNR} element={<CtrShipNrView />} />
                                  <Route path={ViewNameEnum.CTRLOESCHHAFEN} element={<CtrLoeschHafenView />} />
                                  <Route path={ViewNameEnum.CTRQUAYACCOUNTNUMBER} element={<CtrQuayAccountNumberView />} />
                                </Route>
                              </Route>
                            </Route>
                            <Route path={ViewNameEnum.LOGIN} element={<AutoUpdater><Login /></AutoUpdater>} />
                          </Route>
                          <Route path={ViewNameEnum.LANGUAGE} element={<AutoUpdater><Language /></AutoUpdater>} />
                          <Route path="*" element={<div>404</div>} />
                        </Routes>
                      </div>
                    </Router>
                    <Footer />
                  </BookingContextProvider>
                </StammdatenContextProvider>
                </IdpContextProvider>
              </SystemparameterContextProvider>
            </Sentry.ErrorBoundary>
          </Initialize>
        </ClientMessagesContextProvider>
      </ErrorStatusContextProvider>
    </LoadingStatusContextProvider>
  </SessionContextProvider>;
};

export default App;
