import { useLoadingStatusContext } from "../contexts/loadingstatus.context";

export const Loader = () => {

  const { loadingStatus } = useLoadingStatusContext();

  if (loadingStatus.isLoading) {
    return <div className='loader'>
      <div className='loader-progress'>
        <div className='indeterminate' />
      </div>
    </div>;
  }

  return null;
};