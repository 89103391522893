import { AxiosResponse } from 'axios';
import { AutoComplete } from 'primereact/autocomplete';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { ReactNode, useEffect, useState } from 'react';
import { ListSelectorDialog } from './listselector.component';

interface Props<T> {
  searchMethod: (event: { query: string, code?: string }) => Promise<T[] | undefined>;
  itemTemplate: (item: T) => ReactNode | string;
  selectedItemTemplate: (item?: T) => string | undefined;
  onSelect: (item?: T) => void;
  value?: T;
  disabled?: boolean;
  showButton?: boolean;
  filteredInitial: T[];
  field: string;
  title?: string;
  className?: string;
  dropdown?: boolean;
}

export const DetailSelector = <T,>(props: Props<T>) => {

  const [current, setCurrent] = useState<T>();
  const [listSelectorVisible, setListSelectorVisible] = useState(false);
  const [filteredCodes, setFilteredCodes] = useState<T[]>([]);

  useEffect(() => setFilteredCodes(props.filteredInitial), [props.filteredInitial]);
  useEffect(() => setCurrent(props.value), [props.value]);

  return (<>
    <div className='detail-selector'>
      <AutoComplete
        value={current}
        suggestions={filteredCodes}
        field={props.field}
        itemTemplate={props.itemTemplate}
        completeMethod={(event) => {
          props.searchMethod(event).then(reason => reason && setFilteredCodes(reason));
        }}
        forceSelection
        autoHighlight
        onChange={(event) => event.value != null && setCurrent(event.value)}
        onSelect={(event) => props.onSelect(event.value)}
        onBlur={() => {
          if (typeof current === 'string') {
            if (current.length === 0) {
              props.onSelect(undefined);
            } else {
              setCurrent(props.value);
            }
          }
        }
        }
        disabled={props.disabled}
        className={props.className}
        dropdown={props.dropdown}
      />

      <div className={classNames("p-input-icon-right")}>
        <InputText id="filter" value={props.selectedItemTemplate(props.value) || ''} 
        readOnly={true}
        className={classNames('p-inputtext p-component p-filled detail-selector-info', { "detail-selector-info-selectable": props.showButton && !props.disabled })} 
        onClick={() => props.showButton && !props.disabled && setListSelectorVisible(true)} />
        {props.value && !props.disabled && <i className="clear-icon pi pi-times" onClick={() => props.onSelect(undefined)} />}
      </div>

    </div>
    <ListSelectorDialog
      title={props?.title || ''}
      visible={listSelectorVisible}
      onHide={() => setListSelectorVisible(false)}
      itemTtemplate={props.itemTemplate}
      searchMethod={props.searchMethod}
      onSelect={props.onSelect}
      suggestions={filteredCodes}
    />
  </>);
}

export const searchFiltered = <T,>(searchFunc: (filter: { filter?: string }) => Promise<AxiosResponse<T[]>>, minInputLength: number = 1) => (event: { query: T | string }) => {
  return Promise.resolve().then(() => {
    if (typeof event.query === 'string' && event.query && event.query.length >= minInputLength) {
      const query = event?.query?.toLowerCase();
      return searchFunc({ filter: query }).then(result => result.data);
    }
    return [];
  })
    .catch(e => {
      console.log("Search failed for " + event.query);
      return [];
    });
}
