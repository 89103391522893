import { faPeopleArrows } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useErrorStatus } from "../../contexts/errorstatus.context";
import { useSession } from "../../contexts/session.context";
import { UserDTO } from "../../dto/UserDTO";
import { ViewNameEnum } from "../../dto/ViewNameEnum";
import { sessionService } from "../../services/session.service";
import { userService } from "../../services/user.service";
import { DetailSelector } from "./detailselector.component";

type ObjType = UserDTO;

type Props = {
  visible?: boolean;
  onClose: () => void;
}

export const ChangeUserDialog = (props: Props) => {
  const [users, setUsers] = useState<UserDTO[]>();
  const [filteredUsers, setFilteredUsers] = useState<ObjType[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserDTO>();
  const { setSession } = useSession();
  const { setErrorStatusText } = useErrorStatus();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    !initialized && props.visible && userService.getUsers().then(res => { setUsers(res.data); setSelectedUser(undefined); }).finally(() => setInitialized(true));
  }, [initialized, props.visible])

  const onClose = () => {
    setSelectedUser(undefined);
    setInitialized(false);
    props.onClose();
  }

  const changeUser = () => {
    selectedUser?.username && sessionService.changeUser(selectedUser.username)
      .then((loginResponse) => setSession(loginResponse.data?.session))
      .then(() => navigate(ViewNameEnum.ROOT))
      .catch(e => { console.error("Error: " + e); setErrorStatusText("Error: " + e); })
      .finally(onClose)
      ;
  }

  const footer = <>
    <Button label={t('action.login')} onClick={changeUser}></Button>
  </>

  const itemTemplate = (item: ObjType | undefined) => (
    item && (<>{item?.username} ({item?.fullName})</>)
  );

  const selectedItemTemplate = (item: ObjType | undefined) => {
    return (
      item?.fullName
    );
  }

  const searchCode = (event: { query: ObjType | string }) => {
    return new Promise<ObjType[]>(() => {
      if (typeof event.query === 'string' && event.query) {
        const query = event?.query?.toLocaleLowerCase().trim();
        return userService.getUsers(query).then(res => {
          setFilteredUsers(res.data);
          return res.data;
        })
      } else {
        users && setFilteredUsers(users);
      }
      return users;
    });
  }

  return (<Dialog header={t('changeUser.dialogtitle')} visible={props.visible} onHide={onClose} footer={footer}>
    <div>
      <label><Trans i18nKey={'username'}/></label>
      <DetailSelector
        field='username'
        filteredInitial={filteredUsers}
        itemTemplate={itemTemplate}
        onSelect={s => { setSelectedUser(s); }}
        searchMethod={searchCode}
        selectedItemTemplate={selectedItemTemplate}
        value={selectedUser}

      />

    </div>
  </Dialog>)

}

export const ChangeUserButton = () => {
  const [visible, setVisible] = useState(false);
  const { session } = useSession();

  const isSupport = session?.supplements.support;

  return (<>
    {isSupport && <Button onClick={() => setVisible(true)}>
      <FontAwesomeIcon icon={faPeopleArrows} className="p-button-icon-left" />
      <span className="p-button-label"><Trans i18nKey={'changeUser.button'} /></span>
    </Button>}

    <ChangeUserDialog visible={visible} onClose={() => setVisible(false)} />
  </>);
}