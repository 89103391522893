import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useBooking } from "../contexts/booking.context";
import { ContainerDTO } from "../dto/ContainerDTO";
import { ValidationErrorDTO } from "../dto/ValidationErrorDTO";
import { ViewNameEnum } from "../dto/ViewNameEnum";
import { containerValidationService, PROPERTY_MRN, PROPERTY_WARENPOSITION } from "../services/container.validation.service";
import { NavigateBackNextView } from "./subComponents/navigatebacknext.component";
import { SelectionHeaderView } from "./selectionheader.component";
import { ValidationInfo } from "./subComponents/validationinfo.component";
import { FormView } from "./subComponents/formview.component";
import { MessageType } from "../dto/MessageType";

export const CtrT1MrnView = () => {

  const validate = (ctr: ContainerDTO) => (containerValidationService.validate(ctr, (a) => [PROPERTY_WARENPOSITION, PROPERTY_MRN].includes(a)));
  const { container, setContainer } = useBooking();
  const { t } = useTranslation();
  const [validationErrors, setValidationErrors] = useState<ValidationErrorDTO[]>(container ? validate(container) : []);

  const nextView = () => (
    ViewNameEnum.CTRCARRIERCODE
  );

  const validateAndUpdate = (newContainer: ContainerDTO) => {
    setValidationErrors(validate(newContainer));
    setContainer(newContainer);
    return true;
  };

  const getValidationErrorsWithoutWarnings = () => (validationErrors.filter(e => e.type === MessageType.ERROR));
  validationErrors.sort((a,b) => a.type.localeCompare(b.type));

  return <div>
    <SelectionHeaderView />
    <div className="title">{t('CtrT1MrnView')}</div>

    <FormView nextView={nextView} nextEnabled={validationErrors.length === 0}>
      <div className='formbox-wrapper content'>
        <div className='formbox'>
          <div className='formbox-field-label'>
            <label htmlFor="mrn">{t('mrn')}</label>
            <InputText id="mrn" keyfilter={/[A-Za-z\d]/} value={container?.mrn || ''} className={classNames({ 'p-invalid': validationErrors.find(e => e.property === PROPERTY_MRN) })} onChange={(e) => container && validateAndUpdate({ ...container, mrn: e.target.value?.toUpperCase() })} maxLength={21} autoFocus />
          </div>
          <ValidationInfo property='mrn' validationErrors={validationErrors} />
          <div className='formbox-field-label'>
            <label htmlFor="warenposition">{t('warenposition')}</label>
            <InputText id="warenposition" value={container?.warenposition || ''} className={classNames({ 'p-invalid': validationErrors.find(e => e.property === PROPERTY_WARENPOSITION) })} onChange={(e) => container && validateAndUpdate({ ...container, warenposition: e.target.value })} />
          </div>
          <ValidationInfo property='warenposition' validationErrors={validationErrors} />
        </div>
      </div>
    </FormView>
    <NavigateBackNextView nextView={nextView} nextDisabled={getValidationErrorsWithoutWarnings().length !== 0} />
  </div>;
};