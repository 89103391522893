import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useBooking } from "../contexts/booking.context";
import { ContainerDTO } from '../dto/ContainerDTO';
import { TourenartEnum } from '../dto/TourenartEnum';
import { ValidationErrorDTO } from '../dto/ValidationErrorDTO';
import { ViewNameEnum } from "../dto/ViewNameEnum";
import { containerValidationService, PROPERTY_UERBERHOEHE } from '../services/container.validation.service';
import { SelectionHeaderView } from "./selectionheader.component";
import { InputNumberRight } from './subComponents/inputnumberright.component';
import { NavigateBackNextView } from "./subComponents/navigatebacknext.component";
import { ValidationInfo } from './subComponents/validationinfo.component';

const PROPS = ["ueberhoehe", "ueberbreiteLinks", "ueberbreiteRechts", "ueberbreiteVorn", "ueberbreiteHinten"];

export const CtrOogView = () => {
  const validate = (ctr: ContainerDTO) => (containerValidationService.validate(ctr, (a) => a === PROPERTY_UERBERHOEHE));

  const { container, setContainer } = useBooking();
  const { t } = useTranslation();
  const [validationErrors, setValidationErrors] = useState<ValidationErrorDTO[]>(container ? validate(container) : []);

  const nextView = () => {
    if (container?.t1) {
      return container?.tourenart === TourenartEnum.AUSLIEFERUNG ? ViewNameEnum.CTRT1PCKSTK : ViewNameEnum.CTRT1MRN;
    }
    return container?.tourenart === TourenartEnum.AUSLIEFERUNG ? ViewNameEnum.CTRSHOW : ViewNameEnum.CTRCARRIERCODE;
  };

  const validateAndUpdate = (newContainer: ContainerDTO) => {
    setValidationErrors(validate(newContainer));
    setContainer(newContainer);
    return true;
  };

  return <>
    <SelectionHeaderView />
    <div className="title">{t('ueberdimensioniert')}</div>
    <div className='formbox-wrapper content'>
      <div className='formbox'>
        <div className='formbox-field'>
          {PROPS.map(prop =>
            <React.Fragment key={prop}>
              <div className='input-row'>
                <label htmlFor={prop}>{t(prop as any) + " " + t('incm')}</label>
                <div className='input-field-wrapper'>
                  <InputNumberRight
                    id={prop}
                    value={container && (container as any)[prop]}
                    onChange={(e) => container && validateAndUpdate({ ...container, [prop]: e.value })}
                    onValueChange={(e) => container && validateAndUpdate({ ...container, [prop]: e.value })} min={1} max={999} size={3} />
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
        <ValidationInfo property={PROPS[0]} validationErrors={validationErrors} />
      </div>
    </div>
    <NavigateBackNextView nextView={nextView} nextDisabled={validationErrors.length !== 0} />
  </>;
};