import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBooking } from "../contexts/booking.context";
import { useStammdaten } from "../contexts/stammdaten.context";
import { ContainerDTO } from "../dto/ContainerDTO";
import { TourenartEnum } from "../dto/TourenartEnum";
import { ViewNameEnum } from "../dto/ViewNameEnum";
import { stammdatenService } from "../services/stammdaten.service";
import { NavigateBackNextView } from "./subComponents/navigatebacknext.component";
import { SelectionHeaderView } from "./selectionheader.component";
import { StringShortcutSelectorView } from "./subComponents/stringshortcutselectorview.component";

const SHORTCUT_CODES = ["DC", "OT", "RF"];

export const CtrTypeView = () => {
  const { container, setContainer } = useBooking();
  const { t } = useTranslation();
  const [containerTypList, setContainerTypList] = useState<string[]>();
  const { containerIsoCodes } = useStammdaten();

  useEffect(() => {
    const load = async () => {
      stammdatenService.findContainerTypen({ l: container?.containerLaenge, h: container?.containerHoehe })
        .then((response: AxiosResponse) => {
          setContainerTypList(response.data);
        });
    };
    load();
  }, [container]);

  const nextView = (ctr: ContainerDTO | undefined = container) => (
    container?.tourenart === TourenartEnum.ANLIEFERUNG || container?.tourenart === TourenartEnum.AUSLIEFERUNG ? ViewNameEnum.CTRADDITIONALDATA : ViewNameEnum.CTRCARRIERCODE
  );

  const onNextView = (view: ViewNameEnum, selectedType?: string) => {
    const type = selectedType || container?.containerType;
    const iso = containerIsoCodes?.find(c => c.containerhoehe === container?.containerHoehe && c.containerlaenge === container.containerLaenge && c.containertyp === type);
    container && setContainer({ ...container, containerIsoCode: iso, containerHoehe: undefined, containerLaenge: undefined, containerType: undefined });
  };

  const makeCtrTypLabel = (typ: string) => {
    return typ || t('containerType');
  };

  return <div>
    <SelectionHeaderView />
    <div className="title">{t('containerType')}</div>
    <div className="content">
      <StringShortcutSelectorView label={t('containerType')}
        itemTemplate={makeCtrTypLabel}
        selectedString={container?.containerType || ""}
        setSelectedString={(str) => container && setContainer({ ...container, containerType: str })}
        nextView={nextView}
        onNextView={onNextView}
        stringList={containerTypList || []} shortcutStringList={SHORTCUT_CODES}
      />
    </div>
    <NavigateBackNextView onNextView={onNextView} nextView={nextView} nextDisabled={!container?.containerType} />

  </div>;
};