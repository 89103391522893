import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from "react-i18next";
import { useBooking } from "../contexts/booking.context";
import { useStammdaten } from '../contexts/stammdaten.context';
import { StammdatenReederDTO } from '../dto/StammdatenReederDTO';
import { TourenartEnum } from "../dto/TourenartEnum";
import { ViewNameEnum } from '../dto/ViewNameEnum';
import { NavigateBackNextView } from "./subComponents/navigatebacknext.component";
import { SelectionHeaderView } from "./selectionheader.component";

export const CtrCarrierCodeView = () => {
  const { container, setContainer } = useBooking();
  const { t } = useTranslation();

  const { containeroperators } = useStammdaten();

  const nextView = () => {
    switch (container?.tourenart) {
      case TourenartEnum.ANLIEFERUNG:
        return ViewNameEnum.CTRWEIGHT;
      case TourenartEnum.LEER_DEPOT_EINGANG:
        return ViewNameEnum.CTRTURNINREFERENCE;
      case TourenartEnum.LEER_DEPOT_AUSGANG:
        return ViewNameEnum.CTRSHOW;
      default:
        return ViewNameEnum.CTRSHOW;
    }
  };

  const makeCarrierLabel = (carrier: StammdatenReederDTO | null) => {
    if (carrier == null) return t('carrier');
    let carrierInfo = carrier.code;
    carrierInfo += carrier?.kurzname ? ' - ' + carrier.kurzname : '';
    carrierInfo += carrier?.hbCode ? '(' + carrier.hbCode + ')' : '';
    return carrierInfo;
  };

  return <div>
    <SelectionHeaderView />
    <div className="title">{t('carrier')}</div>
    <div className='formbox-wrapper content'>
      <div className='formbox'>
        <div className='formbox-field'>
          <Dropdown
            data-testid="dropdown"
            showClear
            filter
            optionLabel="code"
            placeholder={t('carrier')}
            value={container?.carrier}
            filterBy="code,kurzname"
            options={containeroperators}
            valueTemplate={makeCarrierLabel}
            itemTemplate={makeCarrierLabel}
            resetFilterOnHide
            onChange={(e) => container && setContainer({ ...container, carrier: e.target.value })}
            autoFocus
          />
        </div>
      </div>
    </div>
    <NavigateBackNextView nextView={nextView} nextDisabled={!container?.carrier} />
  </div>;
};