import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useBooking } from "../contexts/booking.context";
import { ContainerDTO } from "../dto/ContainerDTO";
import { ValidationErrorDTO } from "../dto/ValidationErrorDTO";
import { ViewNameEnum } from "../dto/ViewNameEnum";
import { containerValidationService, PROPERTY_FREISTELLREFERENZ } from "../services/container.validation.service";
import { SelectionHeaderView } from "./selectionheader.component";
import { FormView } from "./subComponents/formview.component";
import { NavigateBackNextView } from "./subComponents/navigatebacknext.component";
import { ValidationInfo } from "./subComponents/validationinfo.component";

export const CtrFreistellNrView = () => {
  const validate = (ctr: ContainerDTO) => (containerValidationService.validate(ctr, (a) => a === PROPERTY_FREISTELLREFERENZ));
  const { container, setContainer } = useBooking();
  const [validationErrors, setValidationErrors] = useState<ValidationErrorDTO[]>(container ? validate(container) : []);
  const { t } = useTranslation();

  const nextView = () => ( ViewNameEnum.CTRNR );

  const validateAndUpdate = (newContainer: ContainerDTO) => {
    setValidationErrors(validate(newContainer));
    setContainer(newContainer);
    return true;
  };

  return <>
    <SelectionHeaderView />
    <div className="title">{t('freistellreferenz')}</div>
    <FormView nextView={nextView} nextEnabled={validationErrors.length === 0}>
      <div className='formbox-wrapper content'>
        <div className='formbox'>
          <div className='formbox-field'>
            <label htmlFor="freistellref">{t('freistellreferenz')}</label>
            <InputText
              data-testid="input"
              id="freistellref"
              value={container?.freistellreferenz || ''}
              className={classNames({ 'p-invalid': validationErrors.find(e => e.property === PROPERTY_FREISTELLREFERENZ) })}
              onChange={(e) => container && validateAndUpdate({ ...container, freistellreferenz: e.target.value })}
              maxLength={35}
              autoFocus />
          </div>
          <ValidationInfo property={PROPERTY_FREISTELLREFERENZ} validationErrors={validationErrors} />
        </div>
      </div>
    </FormView>
    <NavigateBackNextView nextView={nextView} nextDisabled={validationErrors.length !== 0} />
  </>;
};