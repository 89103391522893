import moment from "moment";
import { useSystemparameter } from "../../contexts/systemparameter.context";
import { getAppVersion, getBuildTimestamp } from "../../utils/versionUtils";

export const VersionInfo = () => {
    const systemparameter = useSystemparameter();

    const serverVersion = () => {
        if (systemparameter.systemparameter?.serverVersion) {
            return ' | ' + systemparameter.systemparameter.serverVersion;
        }
        return "";
    };

    return (<div className='version-info'>{(getAppVersion() + serverVersion() + ' ' + moment(getBuildTimestamp()).format('YYYY-MM-DD HH:mm'))}</div>);
};

