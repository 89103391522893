import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props<T> = {
  title: string;
  visible: boolean;
  onHide: () => void;
  searchMethod: (event: { query: string, code?: string }) => void;
  itemTtemplate: (item: T) => ReactNode | string;
  onSelect: (item?: T) => void;
  suggestions: T[];
  filter?: string;
}

export const ListSelectorDialog = <T,>(props: Props<T>) => {

  const [filter, setFilter] = useState(props.filter);
  const { visible } = props;
  const searchMethodRef = useRef(props.searchMethod);

  const { t } = useTranslation();

  useEffect(() => { searchMethodRef.current = props.searchMethod }, [props.searchMethod]);

  useEffect(() => {
    visible && searchMethodRef.current({ query: filter?.trim() || ' ' });
  }, [visible, filter]);

  const doSelect = (entry?: T) => {
    props.onSelect(entry);
    doClose();
  }

  const doClose = () => {
    setFilter(undefined);
    props.onHide();
  }

  const rows = props.suggestions?.map(entry => ({ label: props.itemTtemplate(entry), value: entry }));

  const isFiltered = !!filter?.trim();

  return (
    <Dialog header={props.title} visible={props.visible} style={{ width: '20rem', height: '80%' }} onHide={doClose}>

      {/* p-input-icon-right funktioniert nichtm hängt beim Eingeben, löschen */}
      <div className={classNames("p-input-icon-left list-selector-dialog")}>
        <i className="pi pi-search" />
        <InputText id="filter" value={filter || ''} onChange={e => setFilter(e.target.value.trim() || ' ')} placeholder={t('inputTextPlease')} autoFocus />
        {isFiltered && <i className="clear-icon pi pi-times" onClick={() => setFilter(undefined)} />}
      </div>

      <DataTable value={rows} stripedRows size="small" emptyMessage={t('keineEintraegeGefunden')} responsiveLayout="scroll">
        <Column body={(entry) => <div style={{ cursor: 'pointer' }} onClick={() => doSelect(entry.value)}>{entry.label}</div>} />
      </DataTable>

      <Button label={t('action.clear')} onClick={() => doSelect(undefined)} />

    </Dialog>
  )
}
