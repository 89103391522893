import moment from "moment";
import React, { ReactNode, useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SessionDTO } from '../dto/SessionDTO';
import { sessionService } from '../services/session.service';
import { AxiosResponse } from "axios";

type SessionContextType = {
  session?: SessionDTO;
  setSession: (data?: React.SetStateAction<SessionDTO | undefined>) => void;
  locale?: string;
  setLocale: (newLocale?: string) => void;
  token?: string;
  setToken: (token?: string) => void;
  logout: () => Promise<AxiosResponse<void>| undefined>;
};

const SessionContext = React.createContext<SessionContextType>({
  setSession: () => {
    // Dummy bis zur Initialiisierung
  },
  setLocale: () => {
    // Dummy bis zur Initialiisierung
  },
  logout: () => {
    return Promise.resolve() as any;
    // Dummy bis zur Initialiisierung
  },
  setToken: () => {
    // Dummy bis zur Initialiisierung
  }
});

export const SessionContextProvider = (props: { children: ReactNode; initialSession?: SessionDTO; }) => {
  const { i18n } = useTranslation();

  const savedSession = props.initialSession || sessionService.getSession();
  const [session, setSession] = React.useState<SessionDTO | undefined>(savedSession);
  const [locale, setLocaleInternal] = React.useState(savedSession?.locale || i18n.language || undefined);
  const [token, setToken] = React.useState<string>();

  const sessionLocale = session?.locale;

  const setLocale = useCallback((newLocale?: string) => {
    setLocaleInternal(newLocale);
    newLocale && i18n.language !== newLocale && i18n.changeLanguage(newLocale);
    moment.locale(newLocale);
  }, [i18n]);

  useEffect(() => {
    sessionLocale && setLocale(sessionLocale);
  }, [sessionLocale, setLocale]);

  const setSessionAndLocalstorage = (data?: React.SetStateAction<SessionDTO | undefined>) => {
    setSession(data);
    if (!data) {
      sessionService.setRefreshtoken(undefined);
      sessionService.setToken(undefined);
      sessionService.setIdpRefreshtoken(undefined);
      setToken(undefined);
    }
  };

  const logout = () => {
    const refreshToken = sessionService.getRefreshtoken();

    return Promise.resolve()
      .then(() => sessionService.logout(refreshToken || undefined))
      .finally(() => {
        setSessionAndLocalstorage();
      });
  };

  return <SessionContext.Provider value={{ locale, setLocale, session, setSession: setSessionAndLocalstorage, token, setToken, logout }}>{props.children}</SessionContext.Provider>;
};

export const useSession = () => {
  const context = useContext(SessionContext);
  if (context === undefined) {
    throw new Error('useSessionContext must be used within a SessionContextProvider');
  }
  return context;
};

