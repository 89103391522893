import { Dropdown } from "primereact/dropdown";
import { useNavigate } from "react-router-dom";
import { ViewNameEnum } from "../../dto/ViewNameEnum";

type Props = {
  stringList: string[];
  shortcutStringList: string[];
  setSelectedString: (value: string) => void;
  selectedString?: string;
  nextView?: () => ViewNameEnum | undefined;
  onNextView?: (nextView: ViewNameEnum, selectedString: string) => void;
  label: string;
  itemTemplate: (value: string) => void;
};

export const StringShortcutSelectorView = (props: Props) => {
  const navigate = useNavigate();

  const nextView = props.nextView && props.nextView();
  const onSelected = (shortcut: string) => {
    props.setSelectedString(shortcut);
    props.onNextView && nextView && props.onNextView(nextView, shortcut);
    nextView && navigate(nextView);
  };

  const values = props.stringList.map(s => ({ label: props.itemTemplate(s), value: s }));

  return (
    <div className='formbox-wrapper'>
      <div className='formbox'>
        <div className='formbox-field'>
          <Dropdown
            data-testid="dropdown"
            showClear
            filter
            placeholder={props.label}
            value={props.selectedString}
            options={values}
            resetFilterOnHide
            onChange={(e) => props.setSelectedString(e.target.value)} />
        </div>

        <div className='formbox-field'>
          {props.shortcutStringList?.map((shortcut, index) => <button key={index} onClick={() => onSelected(shortcut)} >{shortcut}</button>)}
        </div>

      </div>
    </div>
  );
};