import { ContainerDTO } from "./ContainerDTO";
import { TerminalDTO } from "./TerminalDTO";

export class TourDTO {
  tourenplanOid: string;
  transportvormeldungOid: string;
  lastChangeTime: Date;
  containerList: ContainerDTO[];
  tourenplannummer: string;
  unikatTourenplanReferenz: string;
  termin: Date;
  truckerCardId?: string;
  fuhrunternehmen?: string;
  slotklasse: string;
  terminal: TerminalDTO;
}