import { MessageType } from "../dto/MessageType";
import { TourDTO } from "../dto/TourDTO";
import { ValidationErrorDTO } from "../dto/ValidationErrorDTO";
import { t } from "i18next";

export const PROPERTY_FUHRUNTERNEHMEN = "fuhrunternehmen";

export class TourValidationService {
  validate(
    tour: TourDTO,
    propertyFilter: (property: string) => boolean = () => true,
  ): ValidationErrorDTO[] {
    let errors = [] as ValidationErrorDTO[];

    propertyFilter(PROPERTY_FUHRUNTERNEHMEN) && validateFuhrunternehmen(tour, errors);

    return errors;
  }
}

const validateFuhrunternehmen = (  tour: TourDTO,  errors: ValidationErrorDTO[]) => {
  if (!tour.fuhrunternehmen || tour.fuhrunternehmen.length > 20) {
    errors.push(makeError(PROPERTY_FUHRUNTERNEHMEN, t("error.length", { property: t(PROPERTY_FUHRUNTERNEHMEN), 'min': 1, 'max': 35 })));
  }
};

const makeError = (proerty: string, message: string) => {
  return {
    property: proerty,
    type: MessageType.ERROR,
    message: message,
  } as ValidationErrorDTO;
};

export const tourValidationService = new TourValidationService();