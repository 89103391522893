import { classNames } from "primereact/utils";
import { SlotAuslastungsgradEnum } from "../../dto/SlotAuslastungsgradEnum";
import { Ampel } from "./ampel.component";

type Props = { time: number, ampelState: SlotAuslastungsgradEnum, activeTime: number, disabled: boolean, onClick: () => void; };

export const TerminviewTermin: React.FC<Props> = ({ time, ampelState, activeTime, disabled, onClick }) => {

    return (
        <button className={classNames("terminview-termin-button", { "terminview-termin-active": activeTime === time })} key={time} onClick={onClick} disabled={disabled}>
            <div className="terminview-termin">
                <Ampel state={ampelState} disabled={disabled}/>
                <span>
                    {time.toString().padStart(2, "0")}
                </span>
            </div>
        </button>);
};