import React, { ReactNode, useCallback, useContext, useState } from 'react';
import { ClientMessageDTO } from '../dto/ClientMessageDTO';

class LastClientMessages {
  lastUpdate: Date;
  messages: ClientMessageDTO[];
}

type ClientMessagesContextType = {
  clientMessages?: LastClientMessages;
  setClientMessages: (msg: LastClientMessages) => void;
  resetHiddenStatus: () => void;
};

const ClientMessagesContext = React.createContext<ClientMessagesContextType>({
  setClientMessages: () => {
    // init
  },
  resetHiddenStatus: () => {
    // Init
  }
});

export const ClientMessagesContextProvider = (props: { children: ReactNode; }) => {
  const [clientMessages, setClientMessages] = useState<LastClientMessages>();

  const resetHiddenStatus = useCallback(() => {
    if (clientMessages) {
      const unhiddenMessages = { ...clientMessages, messages: clientMessages.messages?.map(m => ({ ...m, hidden: false })) };
      setClientMessages(unhiddenMessages);
    }
  }, [clientMessages]);

  return <ClientMessagesContext.Provider value={{ clientMessages, setClientMessages, resetHiddenStatus }}>{props.children}</ClientMessagesContext.Provider>;
};

export const useClientMessages = () => {
  const context = useContext(ClientMessagesContext);
  if (context === undefined) {
    throw new Error('useClientMessages must be used within a ClientMessagesContextProvider');
  }
  return context;
};

