import React, { useContext } from 'react';

type LoadingStatusDTO = {
  isLoading: boolean;
};

const initialStatus = {
  isLoading: false,
  loaderCount: 0
};

type LoadingStatusContextType = {
  loadingStatus: LoadingStatusDTO;
  setLoadingStatus: React.Dispatch<React.SetStateAction<LoadingStatusDTO>>;
};

const LoadingStatusContext = React.createContext<LoadingStatusContextType>({
  setLoadingStatus: () => {
    // intermediate
  }, loadingStatus: initialStatus,
});

export const LoadingStatusContextProvider: React.FC = ({ children }) => {

  const [loadingStatus, setLoadingStatus] = React.useState<LoadingStatusDTO>({ isLoading: false });

  return <LoadingStatusContext.Provider value={{ loadingStatus, setLoadingStatus }}>{children}</LoadingStatusContext.Provider>;
};

export const useLoadingStatusContext = () => {
  const context = useContext(LoadingStatusContext);
  if (context === undefined) {
    throw new Error('useLoadingStatus must be used within a LoadingStatusContextProvider');
  }
  return context;
};
